import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import getFullAudienceQuery from "./getFullAudience.graphql";

// TODO: here is where typescript types would be super usefuld
const useGetFullAudience = () => {
  const [input, setInput] = useState();
  const [onCompleted, setOnCompleted] = useState();
  const [onError, setOnError] = useState();

  const [
    getFullAudience,
    {
      called: getFullAudienceCalled,
      loading: getFullAudienceLoading,
      error: getFullAudienceError,
      data: getFullAudienceData,
    },
  ] = useLazyQuery(getFullAudienceQuery, {
    // TODO: use refetch query instead
    fetchPolicy: "cache-and-network",
    variables: {
      input,
    },
    onCompleted,
    onError,
    context: {
      debounceKey: "getFullAudience",
    },
  });

  const getFullAudienceWrapper = async (getFullAudienceInput) => {
    // TODO: handle errorss
    return new Promise(async (resolve, reject) => {
      await setOnCompleted(
        () =>
          ({ getFullAudience }) =>
            resolve(getFullAudience),
      );
      await setOnError(() => (error) => reject(error));
      await setInput(getFullAudienceInput);
    });
  };

  useEffect(() => {
    if (!input) {
      return;
    }
    getFullAudience();
  }, [input, getFullAudience]);

  return {
    getFullAudience: getFullAudienceWrapper,
    getFullAudienceCalled,
    getFullAudienceLoading,
    getFullAudienceError,
    getFullAudienceData,
  };
};

export default useGetFullAudience;
