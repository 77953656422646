import loadable from "@loadable/component";
import pTimeout from "p-timeout";

import Landing from "./Landing";

const timeout = 10000; // 10 seconds

// reference: https://www.smooth-code.com/open-source/loadable-components/docs/prefetching/

const AsyncDiscover = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Discover"), timeout),
);

const AsyncNotification = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Notification"), timeout),
);

const AsyncHome = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Home"), timeout),
);

const AsyncLogin = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Login"), timeout),
);

const AsyncEmailConfirmation = loadable(() =>
  pTimeout(
    import(/* webpackPrefetch: true */ "./Login/EmailConfirmation"),
    timeout,
  ),
);

const AsyncProfile = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Profile"), timeout),
);

const AsyncMessages = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Messages"), timeout),
);

const AsyncEvent = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Event"), timeout),
);

const AsyncGroup = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Group"), timeout),
);

const AsyncNotFound = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./NotFound"), timeout),
);

const AsyncSettings = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Settings"), timeout),
);

const AsyncFeedback = loadable(() =>
  pTimeout(import(/* webpackPrefetch: true */ "./Feedback"), timeout),
);

export {
  Landing,
  AsyncDiscover,
  AsyncNotification,
  AsyncHome,
  AsyncLogin,
  AsyncEmailConfirmation,
  AsyncProfile,
  AsyncMessages,
  AsyncEvent,
  AsyncNotFound,
  AsyncGroup,
  AsyncSettings,
  AsyncFeedback,
};
