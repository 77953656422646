import React, { Component } from "react";
import { Zoom } from "react-reveal";

import Number1 from "images/how-it-works/number-1.svg";
import Number2 from "images/how-it-works/number-2.svg";
import Number3 from "images/how-it-works/number-3.svg";
import Step1 from "images/how-it-works/step-1.svg";
import Step2 from "images/how-it-works/step-2.svg";
import Step3 from "images/how-it-works/step-3.svg";

import {
  Step,
  Container,
  TopRow,
  BottomRow,
  Row,
  Column,
  HeaderText,
  SubHeaderText,
} from "./howItWorks/styles";

class HowItWorks extends Component {
  render() {
    return (
      <Container>
        <TopRow>
          <HeaderText>How it Works</HeaderText>
        </TopRow>
        <BottomRow>
          <Column>
            <Row>
              <Zoom duration={300}>
                <Step src={Number1} alt="step-1" />
              </Zoom>
            </Row>
            <Row>
              <SubHeaderText>
                Browse events from multiple platforms
                <br />
                (or create your own)
              </SubHeaderText>
            </Row>
            <Row grow>
              <img src={Step1} alt="step-1" style={{ width: "320px" }} />
            </Row>
          </Column>
          <Column>
            <Row>
              <Zoom delay={500} duration={300}>
                <Step src={Number2} alt="step-2" />
              </Zoom>
            </Row>
            <Row>
              <SubHeaderText>
                Form "pods" with other interested users
                <br />( pods are 2 or 3 peas )<br />
              </SubHeaderText>
            </Row>
            <Row grow>
              <img src={Step2} alt="step-2" style={{ width: "160px" }} />
            </Row>
          </Column>

          <Column>
            <Row>
              <Zoom delay={1000} duration={300}>
                <Step src={Number3} alt="step-3" />
              </Zoom>
            </Row>
            <Row>
              <SubHeaderText>Get together and have a great time!</SubHeaderText>
            </Row>
            <Row grow>
              <img src={Step3} alt="step-3" style={{ width: "290px" }} />
            </Row>
          </Column>
        </BottomRow>
      </Container>
    );
  }
}

export default HowItWorks;
