import React, { memo } from "react";
import PropTypes from "prop-types";
import { MediaUploader } from "mui-treasury/src/brands/peapods";
import { v4 } from "uuid";
import { useSnackbar } from "notistack";

import { useUploadFile } from "graphql/api";

function EventPhotoUploader({ isOpen, onClose, onFileUploaded, ownerId }) {
  const { uploadFile } = useUploadFile();
  const { enqueueSnackbar } = useSnackbar();

  const generateUploadSignature = async (callback, uploadFileInput) => {
    const { uploadSignature } = await uploadFile(uploadFileInput);
    callback(uploadSignature);
  };

  const getPublicId = () => {
    return `${v4().replace(/-/g, "")}`;
  };

  const getConfig = () => ({
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.REACT_APP_CLOUDINARY_API_KEY,
    facebookAppId: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
    instagramClientId: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
    googleApiKey: process.env.REACT_APP_GOOGLE_SEARCH_API_KEY,
    uploadPreset: `event-cover-photos-${process.env.REACT_APP_SENTRY_ENVIRONMENT}`,
    searchByRights: true,
    cropping: true,
    show_powered_by: false,
    sources: ["local", "facebook", "instagram", "image_search"],
    defaultSource: "image_search",
    publicId: getPublicId(),
    uploadSignature: generateUploadSignature,
    tags: [`ownerId:${ownerId}`],
  });

  const onWidgetEvent = (error, { event, info }) => {
    if (error) {
      const message = `Failed to upload photo: ${error.message}`;
      return enqueueSnackbar(message, {
        variant: "error",
        persist: true,
      });
    }

    if (isOpen && ["close", "success"].includes(event)) {
      onClose();
      if (event === "success") {
        onFileUploaded(info);
      }
    }
  };

  if (!ownerId) {
    return null;
  }

  return (
    <MediaUploader
      isVisible={isOpen}
      onClose={onClose}
      getConfig={getConfig}
      onWidgetEvent={onWidgetEvent}
      onScriptLoadFailed={() => {
        throw new Error("Failed to load image uploader.");
      }}
    />
  );
}

EventPhotoUploader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
  ownerId: PropTypes.string,
};

export default memo(EventPhotoUploader);
