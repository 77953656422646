import LogRocket from "logrocket";
import { init, configureScope, showReportDialog } from "@sentry/browser";
import { Loader } from "google-maps";
import smoothscroll from "smoothscroll-polyfill";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import "reset-css";

import "./index.css";

smoothscroll.polyfill();

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(duration);

const initMatomoAnalytics = () => {
  if (process.env.NODE_ENV !== "test") {
    window.matomoEventTracker = window._paq;
  } else {
    window.ga = {
      event: () => {},
      apply: () => {},
    };
  }
};

const initGoogleMapsApi = () => {
  const options = {
    version: "3.43",
    libraries: ["places"],
  };
  const GoogleMapsLoader = new Loader(
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    options,
  );
  if (window.googleMapsLoaded) {
    return;
  }
  window.googleMapsLoaded = new Promise((resolve, reject) => {
    GoogleMapsLoader.load(() => resolve());
  });
};

const initSentry = () => {
  init({
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    beforeSend(event) {
      if (event.exception) {
        showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
  configureScope(async (scope) => {
    const sessionUrl = await new Promise((resolve) =>
      LogRocket.getSessionURL(resolve),
    );
    scope.setExtra("sessionUrl", sessionUrl);
  });
};

const initLogRocket = async () => {
  await LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT);
};

const handleFacebookRedirect = () => {
  // Facebook redirect: https://stackoverflow.com/questions/7131909/facebook-callback-appends-to-return-url
  if (window.location.hash === "#_=_") {
    window.history.replaceState
      ? window.history.replaceState(
          null,
          null,
          window.location.href.split("#")[0],
        )
      : (window.location.hash = "");
  }
};

const handleHotReload = () => {
  // TODO: https://github.com/facebookincubator/create-react-app/pull/2304
  if (module.hot) {
    module.hot.accept();
  }
};

// TODO: eventually we will have real offline support
const removeServiceWorkers = async () => {
  if (!window.navigator.serviceWorker) {
    return;
  }
  const registrations = await window.navigator.serviceWorker.getRegistrations();

  registrations.forEach((registration) => {
    registration.unregister();
  });
};

const initialize = async () => {
  const isDev = process.env.REACT_APP_SENTRY_ENVIRONMENT === "development";

  window.peapodsInfo = {
    version: process.env.REACT_APP_PEAPODS_VERSION,
  };

  handleFacebookRedirect();
  initMatomoAnalytics();
  initGoogleMapsApi();

  if (!isDev) {
    await initLogRocket();
    initSentry();
  } else {
    handleHotReload();
  }
  removeServiceWorkers();
};

export default initialize;
