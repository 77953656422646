import React, { memo } from "react";
import PropTypes from "prop-types";
import { TimeRangeSelector as PureTimeRangeSelector } from "mui-treasury/src/brands/peapods";

const TimeRangeSelector = ({
  userId,
  onChange,
  onEditModeChange,
  timeRangeOptions,
  users,
}) => {
  const timeRangeSelection = timeRangeOptions
    ? timeRangeOptions.filter((option) => option.userId === userId)
    : [];

  const onTimeRangeOptionsChanged = (timeRanges) => {
    const newTimeRangeOptions = timeRanges.map(({ timeRange, state }) => ({
      start: timeRange.start,
      end: timeRange.end,
      state,
      userId,
    }));

    onChange([
      ...(timeRangeOptions
        ? timeRangeOptions.filter((option) => option.userId !== userId)
        : []),
      ...newTimeRangeOptions,
    ]);
  };

  const mapTimeRangeOptions = (opts) => {
    const options = opts || [];

    return options.map(({ start, end, state, timeRange, userId }) => ({
      timeRange: timeRange
        ? {
            start: new Date(timeRange.start),
            end: new Date(timeRange.end),
          }
        : {
            start: new Date(start),
            end: new Date(end),
          },
      start,
      end,
      state,
      userId,
    }));
  };

  return (
    <PureTimeRangeSelector
      timeRangeOptions={mapTimeRangeOptions(timeRangeOptions)}
      selection={mapTimeRangeOptions(timeRangeSelection)}
      onChange={onTimeRangeOptionsChanged}
      onEditModeChange={onEditModeChange}
      userId={userId}
      users={users}
    />
  );
};

TimeRangeSelector.propTypes = {
  userId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditModeChange: PropTypes.func,
  timeRangeOptions: PropTypes.arrayOf(PropTypes.shape({})),
  allPodUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      profilePhoto: PropTypes.string,
    }),
  ),
};

TimeRangeSelector.defaultProps = {
  timeRangeOptions: [],
  allPodUsers: [],
  onEditModeChange: undefined,
};

export default memo(TimeRangeSelector);
