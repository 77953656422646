import styled, { keyframes } from "styled-components";
import { FaSpinner } from "react-icons/fa";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(FaSpinner)`
  animation: ${rotate360} 1.5s linear infinite;
  font-size: ${(props) => (props.size ? props.size : "10px")};
`;

const colors = {
  mainBlue: "#007dff",
  secondaryBlue: "#6dabff",
  mainGreen: "#66cc66",
  secondaryGreen: "#009966",
  mainRed: "#ff6060",
  mainPurple: "#a75ffd",
};

const DESKTOP_WIDTH = 1200;
const TABLET_WIDTH = 1000;

export { colors, LoadingIcon, DESKTOP_WIDTH, TABLET_WIDTH };
