import { useState } from "react";

const listener = (event) => {
  event.preventDefault();
  event.returnValue = "";
};

const usePreventLeave = (dirty) => {
  const [isDirty, setIsDirty] = useState(dirty);

  const enablePrevent = () => window.addEventListener("beforeunload", listener);

  const disablePrevent = () =>
    window.removeEventListener("beforeunload", listener);

  const setIsDirtyWrapper = (val) => {
    val ? enablePrevent() : disablePrevent();
    setIsDirty(val);
  };

  const promptForConfirmation = (
    message = "You have unsaved changes, are you sure?",
  ) => {
    // eslint-disable-next-line
    return window.confirm(message);
  };

  return { isDirty, setIsDirty: setIsDirtyWrapper, promptForConfirmation };
};

export default usePreventLeave;
