import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import Ink from "react-ink";

import Pea from "images/peapods-logo-circle.svg";
import { FaSpinner } from "react-icons/fa";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(FaSpinner)`
  animation: ${rotate360} 1.5s linear infinite;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 50px;
  padding: 4px;
  box-shadow: ${(props) =>
    props.isFocused
      ? "0px 0px 20px 0px rgba(0, 255, 31, 0.85)"
      : "0px 2px 10px 0px rgba(0, 255, 31, 0.3)"};

  @media (max-width: 600px) {
    width: 330px;

    > label {
      padding-right: 40px;
    }
  }
`;

const LittlePea = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const Input = styled.input`
  width: 250px;
  height: 30px;
  border: 0;
  font-size: 20px;
  opacity: 0.5;
`;

// TODO these buttons are hacks
const Button = styled.button`
  z-index: 1000;
  width: 200px;
  border-radius: 50px;
  position: relative;
  font-size: 16px;
  border: none;
  height: 40px;
  transition: all 0.2s ease;
  background: ${(props) =>
    props.disabled ? "grey" : "linear-gradient(to bottom,#a900ff,#fb00ff)"};
  color: white;
  display: ${(props) => (props.bottom ? "none" : "inline")};

  @media (max-width: 600px) {
    display: ${(props) => (props.bottom ? "inline" : "none")};
    width: 100%;
    margin-top: 14px;
    height: 47px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
    filter: ${(props) =>
      props.disabled ? "brightness(1.0)" : "brightness(1.2)"};
  }
`;

const HackButton = styled.button`
  width: 200px;
  z-index: 1000;
  border-radius: 50px;
  position: relative;
  font-size: 16px;
  border: none;
  height: 40px;
  transition: all 0.2s ease;
  background: ${(props) =>
    props.disabled ? "grey" : "linear-gradient(to bottom,#a900ff,#fb00ff)"};
  color: white;

  &:hover {
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
    filter: ${(props) =>
      props.disabled ? "brightness(1.0)" : "brightness(1.2)"};
  }
`;

class EmailSubmitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      showLoading: false,
      isValid: false,
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    this.props.onSubmit(this.props.email, () => {
      this.setState({ showLoading: false });
    });
  };

  onFocusIn = (e) => {
    this.setState({
      isFocused: e.target === this.emailInput,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  onFocusOut = (e) => {
    if (e.target === this.emailInput) {
      this.setState({ isFocused: false });
    }
  };

  componentDidMount = () => {
    document.addEventListener("focusin", this.onFocusIn);
    document.addEventListener("focusout", this.onFocusOut);
  };

  componentWillUnmount = () => {
    document.removeEventListener("focusin", this.onFocusIn);
    document.removeEventListener("focusout", this.onFocusOut);
  };

  renderForm = (email) => {
    return (
      <form onSubmit={this.onSubmit}>
        <Container isFocused={this.state.isFocused}>
          <LittlePea src={Pea} alt="pea" />
          <label>
            <Input
              type="email"
              autoComplete="email"
              value={email}
              onChange={this.props.onEmailChanged}
              placeholder="your@email.com"
              ref={(comp) => (this.emailInput = comp)}
            />
          </label>
          <Button
            disabled={!this.props.isValidEmail}
            type="submit"
            value="Submit"
          >
            {this.state.showLoading ? <LoadingIcon /> : "Keep me posted!"}
            {this.props.isValidEmail ? <Ink /> : null}
          </Button>
        </Container>
        <Button
          bottom
          disabled={!this.props.isValidEmail}
          type="submit"
          value="Submit"
        >
          {this.state.showLoading ? <LoadingIcon /> : "Keep me posted!"}
          {this.props.isValidEmail ? <Ink /> : null}
        </Button>
      </form>
    );
  };

  render = () => {
    const { email } = this.props;
    return (
      <div>
        {this.props.showEmailInput ? (
          this.renderForm(email)
        ) : (
          <HackButton onClick={this.props.showModal}>See My Code</HackButton>
        )}
      </div>
    );
  };
}

export default EmailSubmitForm;
