import React, { Component } from "react";
import { Fade } from "react-reveal";
import lottie from "lottie-web";

import {
  Container,
  TopRow,
  BottomRow,
  Column,
  Title,
  Subtitle,
  HeroTextContainer,
  HeroAnimationContainer,
  TextWrapper,
} from "./heroHeader/styles";
import animationData from "./heroHeader/lottie-animation.json";

class HeroHeader extends Component {
  componentDidMount = () => {
    lottie.setQuality("low");
    // TODO: why can't we render the animation in jsdom?
    if (process.env.NODE_ENV !== "test") {
      this.animation = lottie.loadAnimation({
        container: window.document.getElementById("animation-container"),
        renderer: window.innerWidth < 1500 ? "canvas" : "svg",
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  };

  componentWillUnmount() {
    if (this.animation) {
      this.animation.destroy();
    }
  }

  render() {
    return (
      <Container>
        <Column>
          <TopRow>
            <HeroTextContainer>
              <TextWrapper>
                <Fade delay={500} duration={1200}>
                  <Title>Real Social Networking</Title>
                </Fade>
                <Fade delay={1500} duration={1200}>
                  <Subtitle>Find Peas. Form Pods. Stick Together.</Subtitle>
                </Fade>
              </TextWrapper>
            </HeroTextContainer>
            <HeroAnimationContainer id="animation-container" />
          </TopRow>
          <BottomRow>{this.props.children}</BottomRow>
        </Column>
      </Container>
    );
  }
}

export default HeroHeader;
