export default () => ({
  MuiGrid: {
    container: {
      // TODO: this seems like a hack
      // eslint-disable-next-line
      // https://github.com/mui-org/material-ui/issues/7466#issuecomment-398233978
      width: '100% !important',
    },
  },
});
