import React, { useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Radio,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "mui-treasury/src/brands/peapods";

const RadioGrid = ({
  options,
  title,
  value: propValue,
  onChange,
  gridItemSize,
}) => {
  const [value, setValue] = useState(propValue ? propValue : options[0].value);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setValue(propValue ? propValue : options[0].value);
  }, [propValue, options]);

  return (
    <FormControl margin={"normal"} component="fieldset" fullWidth>
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup
        aria-label={title}
        name={title}
        value={value}
        onChange={handleChange}
      >
        <Grid container direction="row" alignContent="space-between">
          {options.map((option) => {
            return (
              <Grid item key={option.value} xs={gridItemSize}>
                <FormControlLabel
                  control={<Radio />}
                  label={option.label}
                  value={option.value}
                />
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

RadioGrid.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  gridItemSize: PropTypes.number,
};

RadioGrid.defaultProps = {
  gridItemSize: 12,
  value: null,
};

export default memo(RadioGrid);
