import { useMutation } from "@apollo/client";

import { deleteInviteCode as deleteInviteCodeMutation } from "graphql/api";

const useDeleteInviteCode = () => {
  const [deleteInviteCode, { loading }] = useMutation(
    deleteInviteCodeMutation,
    {
      refetchQueries: ["getInviteCodes"],
    },
  );

  return {
    deleteInviteCode,
    deleteInviteCodeLoading: loading,
  };
};

export default useDeleteInviteCode;
