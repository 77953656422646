import React, {
  useEffect,
  useCallback,
  useState,
  createContext,
  useContext,
} from "react";
import { useQuery } from "@apollo/client";
import getstream from "getstream";
import { uniqBy } from "lodash";

import { getCurrentUser as getCurrentUserQuery } from "graphql/api";
import { queries } from "graphql/clientApi";

import { history } from "Routes";

const GetstreamContext = createContext({});

const useGetstreamContext = () => useContext(GetstreamContext);

const Provider = ({ children }) => {
  const getstreamApiKey = process.env.REACT_APP_GETSTREAM_API_KEY;
  const getstreamAppId = process.env.REACT_APP_GETSTREAM_APP_ID;

  const [subscription, setSubscription] = useState();
  const [feed, setFeed] = useState();
  const [activityGroups, setActivityGroups] = useState([]);
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const { data: isLoggedInData } = useQuery(queries.IS_LOGGED_IN_QUERY);
  const isLoggedIn = isLoggedInData && isLoggedInData.isLoggedIn;

  const { data } = useQuery(getCurrentUserQuery, {
    skip: !isLoggedIn,
  });

  const currentUser = data && data.getCurrentUser.user;

  const startSubscription = useCallback(
    ({ userId, getstreamUserToken }) => {
      if (feed) {
        return;
      }

      const getstreamClient = getstream.connect(
        getstreamApiKey,
        getstreamUserToken,
        getstreamAppId,
      );

      const userNotificationFeed = getstreamClient.feed(
        "notification",
        userId,
        getstreamUserToken,
      );

      setFeed(userNotificationFeed);
    },
    [feed, getstreamApiKey, getstreamAppId],
  );

  const onNewUserNotification = useCallback(
    (data) => {
      data.new.forEach((activity) => {
        if (activity.message) {
          if (currentUser && activity.message.author.id !== currentUser.id) {
            // TODO: we should actually store the unread messages in the
            // apollo client cache
            setNewMessageCount(newMessageCount + 1);
          }
        } else {
          setNewNotificationCount(newNotificationCount + 1);
        }
      });
    },
    [newMessageCount, newNotificationCount, currentUser],
  );

  const getFeedPage = useCallback(
    async ({ limit = 20, offset = 0, markAsRead } = {}) => {
      if (!feed) {
        return;
      }

      const { results, next } = await feed.get({
        limit,
        offset,
        mark_read: markAsRead,
      });

      const newGroups =
        offset === 0 ? results : uniqBy([...activityGroups, ...results], "id");

      setActivityGroups(newGroups);
      setHasNextPage(!!next);
      setNewNotificationCount(0);

      const requestVerbs = ["pod_req", "follow_req", "group_join_req"];

      const readActivityIds = results
        .filter((group) => !group.is_read && !requestVerbs.includes(group.verb))
        .map((g) => g.id);

      feed.get({
        limit,
        offset,
        mark_read: readActivityIds,
      });
    },
    [feed, activityGroups],
  );

  const markAsRead = async (id) => {
    await getFeedPage({ markAsRead: [id] });
    if (window.location.pathname === "/notifications") {
      history.push("/notifications", {
        reloadFeed: true,
      });
    }
  };

  const markMessagesAsRead = () => {
    setNewMessageCount(0);
  };

  useEffect(() => {
    if (data && data.getCurrentUser) {
      startSubscription({
        userId: data.getCurrentUser.user.id,
        getstreamUserToken: data.getCurrentUser.getstreamUserToken,
      });
    }
  }, [data, startSubscription]);

  useEffect(() => {
    if (!feed || subscription) {
      return;
    }

    const sub = feed.subscribe(onNewUserNotification);

    setSubscription(sub);

    getFeedPage();
  }, [feed, subscription, getFeedPage, onNewUserNotification]);

  useEffect(() => {
    return () => subscription && subscription.cancel();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.peapodsFaviconBadge.badge(newNotificationCount + newMessageCount);
  }, [newNotificationCount, newMessageCount]);

  const state = {
    hasNextPage,
    markAsRead,
    markMessagesAsRead,
    getFeedPage,
    activityGroups,
    newMessageCount,
    newNotificationCount,
  };

  return (
    <GetstreamContext.Provider value={state}>
      {children}
    </GetstreamContext.Provider>
  );
};

export { Provider, useGetstreamContext };
