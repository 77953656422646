import styled from "styled-components";

import { colors } from "pages/Landing/landing/SharedStyles";

const Background = styled.div`
  color: ${(props) => (props.isTransparent ? "white" : "black")};
  width: 100vw;
  height: 100px;
  position: fixed;
  z-index: 500;
  transition: background-color 0.5s ease;
  box-shadow: ${(props) =>
    props.isTransparent ? "none" : "3px 8px 20px rgba(0, 0, 0, 0.2)"};
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15%;
  margin-right: 15%;

  @media (max-width: 1400px) {
    margin-left: 10%;
    margin-right: 10%;
  }

  @media (max-width: 780px) {
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const SimpleWrapper = styled.div`
  justify-content: space-between;
  font-size: 20px;

  > a {
    margin-left: 10px;
    margin-right: 10px;

    &:hover {
      color: ${(props) =>
        props.isTransparent ? "#65f38c" : colors.secondayGreen};
      cursor: pointer;
    }

    @media (max-width: 780px) {
      display: none;
    }
  }
`;

const Logo = styled.img`
  height: 75px;
`;

export { Background, Container, SimpleWrapper, Logo };
