import React, { Component } from "react";

import {
  Container,
  TopRow,
  BottomRow,
  Column,
  HeaderText,
  SubHeaderTextTitle,
  SubHeaderText,
  MissionTextContainer,
} from "./ourMission/styles";

class OurMission extends Component {
  render() {
    return (
      <Container>
        <TopRow>
          <Column>
            <HeaderText>Our Mission</HeaderText>
            <MissionTextContainer>
              <SubHeaderTextTitle>
                <b>"Deepen your connections by sharing experiences."</b>
              </SubHeaderTextTitle>

              <SubHeaderText>
                Peapods is a new type of social network that values real human
                interaction. The platform helps you become closer with your
                friends and make new ones. We value kindness, friendship, user
                privacy, and genuine connection. Together we can cure boredom,
                conquer loneliness, and fulfill the original promise of the
                internet - to connect us in ways that matter.{" "}
                <div style={{ paddingTop: "20px" }} />
                We hope to see you soon!
              </SubHeaderText>
            </MissionTextContainer>
          </Column>
        </TopRow>
        <BottomRow>{this.props.children}</BottomRow>
      </Container>
    );
  }
}

export default OurMission;
