import { useMutation } from "@apollo/client";

import { joinGroup as joinGroupMutation } from "graphql/api";

const useJoinGroup = () => {
  const [joinGroup, { loading }] = useMutation(joinGroupMutation, {
    refetchQueries: ["getGroupById", "getCurrentUser", "getUserById"],
  });

  return {
    joinGroup,
    joinGroupLoading: loading,
  };
};

export default useJoinGroup;
