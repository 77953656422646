import styled from "styled-components";
import { Grid } from "mui-treasury/src/brands/peapods";

const NavbarWrapper = styled.div`
  z-index: 100;
  height: 72px;
  min-height: 72px;
  width: 100%;
  background: white;
  box-shadow: 3px 8px 20px rgba(0, 0, 0, 0.2);
  & .MuiBottomNavigation-root {
    position: relative;
  }
`;

const NavbarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  min-height: 72px;
`;

const NavbarContent = styled(Grid)`
  padding-right: 10%;
  padding-left: 10%;
  height: 100%;
  justify-content: space-between;
`;

const LeftContent = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  height: 100%;

  & > a {
    margin-right: 44px;
    text-decoration: none;
  }
`;

const CenterContent = styled.div`
  width: 33%;
  text-align: center;
  pointer-events: none;

  > a > img {
    pointer-events: all;
    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s;
    }
  }
`;

const RightContent = styled.div`
  display: flex;
  width: 33%;
  justify-content: flex-end;
  align-items: center;
  height: 44%;
`;

const Logo = styled.img`
  height: 38px;
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const MobileSearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  background: rgb(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: transform 0.3s;
  transform: translateY(120%) translateX(0);
  &.show {
    transform: translateY(0) translateX(0);
  }
`;

const SpeedDialContainer = styled.div`
  position: relative;
  height: 48px;
  width: 160px;
  margin-right: 20px;
`;

const MobileSpeedDialContainer = styled.div`
  position: absolute;
  bottom: 344px;
  right: 97px;
`;

export {
  NavbarWrapper,
  NavbarContainer,
  NavbarContent,
  LeftContent,
  CenterContent,
  RightContent,
  Logo,
  BottomContainer,
  MobileSearchContainer,
  SpeedDialContainer,
  MobileSpeedDialContainer,
};
