import {
  validateInviteCode as validateInviteCodeQuery,
  updateInviteCode as updateInviteCodeMutation,
} from "graphql/api";

async function validateInviteCode(client, inviteCode) {
  const { data } = await client.query({
    query: validateInviteCodeQuery,
    variables: {
      input: {
        inviteCode,
      },
    },
  });

  return !!data?.validateInviteCode?.isValid;
}

async function updateInviteCode(client, inviteCode) {
  const { data } = await client.mutate({
    mutation: updateInviteCodeMutation,
    variables: {
      input: {
        inviteCode,
      },
    },
  });

  return !!data?.updateInviteCode?.success;
}

export { validateInviteCode, updateInviteCode };
