import React, { useState, useEffect, memo } from "react";
import {
  Box,
  Grid,
  FormControl,
  Button,
  FormLabel,
  Counter,
  LoadingSpinner,
} from "mui-treasury/src/brands/peapods";
import { useMutation } from "@apollo/client";

import RadioGrid from "pure-components/RadioGrid";
import { Modal } from "pure-components/Layout";
import PodTimeSelector from "pure-components/PodTimeSelector";

import AudienceSelector from "smart-components/AudienceSelector";

import { setAddToCalendarEventMutation } from "graphql/clientApi";

import { useUserHooks } from "graphql/api";

import usePreventLeave from "utils/preventLeave";

import usePodFormHooks from "./PodFormHooks";

const PodForm = () => {
  const { isDirty, setIsDirty, promptForConfirmation } = usePreventLeave();

  // TODO: don't hardcode these values
  const DEFAULT_MIN_POD_SIZE = 2;
  const DEFAULT_MAX_POD_SIZE = 20;
  const [title, setTitle] = useState("Create Pod");
  const [actionText, setActionText] = useState("Create");
  const [deleteText, setDeleteText] = useState("Delete");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const [setAddToCalendarEvent] = useMutation(setAddToCalendarEventMutation);

  const { currentUser } = useUserHooks();

  const {
    selectedPods,
    podParams,
    setPodParams,
    podGraphQl,
    event,
    myPod,
    modalOpen,
    onClose,
  } = usePodFormHooks();

  const {
    createPod,
    createPodData,
    createPodLoading,
    updatePod,
    updatePodLoading,
    deletePod,
    deletePodLoading,
    getEventByIdLoading,
  } = podGraphQl;

  useEffect(() => {
    setIsLoading(
      (myPod && !myPod.members) ||
        createPodLoading ||
        updatePodLoading ||
        getEventByIdLoading,
    );
  }, [myPod, createPodLoading, updatePodLoading, getEventByIdLoading]);

  useEffect(() => {
    setTitle(myPod ? "Edit Pod" : "Create Pod");
    setActionText(myPod ? "Save" : "Create");
    // TODO: prompt for confirmation
    setDeleteText(
      myPod && myPod.members && myPod.members.length > 1 ? "Leave" : "Delete",
    );
  }, [myPod]);

  const isEventLoading = !event;

  const statusOptions = ["interested", "attending"].map((option) => ({
    value: option.replace(/\s+/g, "_").toUpperCase(),
    label: option,
  }));

  const datingOptions = ["any", "only dates", "no dates"].map((option) => ({
    value: option.replace(/\s+/g, "_").toUpperCase(),
    label: option,
  }));

  const onSave = () => {
    myPod ? updatePod(podParams) : createPod(podParams);
    setIsDirty(false);
  };

  const onPodSaved = () => {
    if (!createPodData) {
      return;
    }

    setAddToCalendarEvent({
      variables: {
        event,
      },
    });
  };

  useEffect(onPodSaved, [createPodData, event, setAddToCalendarEvent]);

  const onFormClosed = (...args) => {
    if (isDirty && !promptForConfirmation()) {
      return;
    }
    setIsDirty(false);

    onClose(...args);
  };

  const onEditModeChange = (isEditMode) => {
    setIsSaveDisabled(isEditMode);
  };

  const onDelete = () => {
    deletePod();
    setIsDirty(false);
  };

  const onPodParamsChanged = (...args) => {
    setIsDirty(true);
    setPodParams(...args);
  };

  const onStateChanged = (state) => {
    onPodParamsChanged({
      ...podParams,
      state,
    });
  };

  const onTimeOptionChanged = ({
    startTime,
    endTime,
    timeRangeOptions,
    timeOption,
  }) => {
    onPodParamsChanged({
      ...podParams,
      startTime,
      endTime,
      timeRangeOptions,
      timeOption,
    });
  };

  const onDatingChanged = (datingOption) => {
    onPodParamsChanged({
      ...podParams,
      datingOption,
    });
  };

  const onAudienceChanged = (audience) => {
    onPodParamsChanged({
      ...podParams,
      audience,
    });
  };

  const onMaxSizeChanged = (e) => {
    onPodParamsChanged({
      ...podParams,
      maxSize: e.target.value,
    });
  };

  const {
    state,
    datingOption,
    maxSize,
    audience,
    startTime,
    endTime,
    timeRangeOptions,
    timeOption,
  } = podParams;

  const podMembers = selectedPods?.length ? selectedPods[0].members : [];

  // TODO: render members
  return (
    <Modal
      open={modalOpen}
      title={title}
      render={(isMobile) => {
        return (
          <Grid
            container
            direction="column"
            style={{
              position: "relative",
            }}
          >
            {myPod && !myPod.members && (
              <Grid
                style={{
                  position: "absolute",
                  height: "100%",
                  background: "white",
                  zIndex: 1000,
                }}
                container
                alignItems="center"
                justify="center"
              >
                <LoadingSpinner />
              </Grid>
            )}

            <Grid item>
              <RadioGrid
                options={statusOptions}
                title="Status"
                defaultValue={statusOptions[0].value}
                value={state}
                onChange={onStateChanged}
              />
            </Grid>

            <Grid item>
              <PodTimeSelector
                userId={currentUser?.id}
                allPodUsers={podMembers}
                value={timeOption}
                onChange={onTimeOptionChanged}
                onEditModeChange={onEditModeChange}
                startTime={startTime}
                endTime={endTime}
                timeRangeOptions={timeRangeOptions}
              />
            </Grid>

            <Grid item>
              <AudienceSelector
                podId={myPod && myPod.id}
                value={audience}
                onChange={onAudienceChanged}
              />
            </Grid>

            <Grid item>
              <RadioGrid
                options={datingOptions}
                title="Dating Option"
                defaultValue={datingOptions[0].value}
                value={datingOption}
                onChange={onDatingChanged}
              />
            </Grid>

            <Grid item>
              <FormControl margin={"normal"} component="fieldset">
                <FormLabel component="legend">Size Limit</FormLabel>
                <Counter
                  min={DEFAULT_MIN_POD_SIZE}
                  max={DEFAULT_MAX_POD_SIZE}
                  value={maxSize || DEFAULT_MIN_POD_SIZE}
                  onChange={onMaxSizeChanged}
                />
              </FormControl>
            </Grid>

            <Box position={"sticky"} bottom={0} left={0} mt={4}>
              <Grid
                container
                alignItems={"center"}
                justify="center"
                spacing={4}
              >
                <Grid item>
                  <Button onClick={onFormClosed}>Cancel</Button>
                </Grid>

                {myPod && (
                  <Grid item>
                    <Button
                      disabled={deletePodLoading || isEventLoading}
                      loading={deletePodLoading || isEventLoading}
                      color={"danger"}
                      variant={"contained"}
                      onClick={onDelete}
                    >
                      {deleteText}
                    </Button>
                  </Grid>
                )}

                <Grid item>
                  <Button
                    id="save-pod"
                    disabled={isLoading || !isDirty || isSaveDisabled}
                    loading={isLoading}
                    color={"primary"}
                    variant={"contained"}
                    onClick={onSave}
                  >
                    {actionText}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      }}
    />
  );
};

export default memo(PodForm);
