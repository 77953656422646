import gql from "graphql-tag";
import { differenceBy, compact } from "lodash";
import { theme } from "mui-treasury/src/brands/peapods";

import { getEventById, getUserById } from "graphql/api";
import { queries } from "graphql/clientApi";

import avatarPlaceholder from "images/avatar-placeholder.svg";
import hashtagPlaceholder from "images/hashtag-placeholder.svg";
import closeFriendsPlaceholder from "images/category-cards/community.svg";
import coworkersPlaceholder from "images/category-cards/networking.svg";
import acquaintancesPlaceholder from "images/category-cards/outdoors.svg";

import { history } from "../Routes";

const TOKEN_NAME = "token";

// reference: https://www.apollographql.com/docs/tutorial/local-state/

const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    getShowPodForm: Boolean!
    getEvents: [Event!]!
    getSelectedUsers: [User!]!
    getSelectedEvents: [Event!]!
    getSelectedThread: Thread!
    getShowPodTimeRangesForm: Boolean!
    getShowEventForm: Boolean!
    getShowEventEditForm: Boolean!
    getShowGroupForm: Boolean!
    getShowGroupEditForm: Boolean!
    getAddToCalendarEvent: Event
  }

  extend type Mutation {
    login(token: String!): Boolean!
    logout: Boolean!
    setUsers(events: [User!]!): [User!]!
    setEvents(events: [Event!]!): [Event!]!
    selectUsers(events: [User!]!): [User!]!
    selectEvents(events: [Event!]!): [Event!]!
    selectGroups(events: [Group!]!): [Group!]!
    deselectEvents(events: [Event!]!): [Event!]!
    clearSelectedEvents: Boolean!
    setCurrentUser: User!
    setShowPodForm(showPodForm: Boolean!): Boolean!
    setShowPodTimeRangesForm(showPodTimeRangesForm: Boolean!): Boolean!
    selectThread(thread: Thread!): Thread!
    setShowEventForm(showEventForm: Boolean!): Boolean!
    setShowEventEditForm(showEventEditForm: Boolean!): Boolean!
    setShowGroupForm(showGroupForm: Boolean!): Boolean!
    setShowGroupEditForm(showGroupEditForm: Boolean!): Boolean!
    setShowInviteForm(showInviteForm: Boolean!): Boolean!
    setAddToCalendarEvent(event: Event): Boolean!
  }

  extend type Event {
    isSelected: Boolean!
    myPod: Pod
    profilePhoto: String!
  }

  extend type Group {
    isSelected: Boolean!
  }

  extend type Thread {
    isSelected: Boolean!
    title: String!
    avatars: [String]
  }

  extend type User {
    profilePhoto: String!
    isCurrentUser: Boolean!
  }

  extend type PublicUserProps {
    profilePhoto: String!
    isCurrentUser: Boolean!
  }

  extend type Hashtag {
    profilePhoto: String!
  }
`;

const profilePhoto = (user) => {
  return user && user.profilePhotos && user.profilePhotos[0]
    ? user.profilePhotos[0]
    : avatarPlaceholder;
};

const isCurrentUser = (user, _, { cache }) => {
  // Note: this is not always reliable, it assumes we have the current user
  // by the time the resolver is called
  const { currentUser } = cache.readQuery({
    query: queries.GET_CURRENT_USER_QUERY,
  });
  return currentUser && user && user.id === currentUser.id;
};

const resolvers = {
  Mutation: {
    login: async (_, { token }, { client }) => {
      window.localStorage.setItem(TOKEN_NAME, token);

      await client.resetStore();

      client.cache.writeQuery({
        query: queries.IS_LOGGED_IN_QUERY,
        data: { isLoggedIn: true },
      });

      return true;
    },

    logout: async (_, args, { client }) => {
      window.localStorage.removeItem(TOKEN_NAME);

      client.cache.writeQuery({
        query: queries.IS_LOGGED_IN_QUERY,
        data: { isLoggedIn: false },
      });

      await client.clearStore();

      window.location.reload();

      return true;
    },

    setUsers: (_, { users }, { cache }) => {
      const data = {
        users,
      };

      cache.writeQuery({ query: queries.GET_USERS_QUERY, data });

      return data.users;
    },

    setEvents: (_, { events }, { cache }) => {
      const data = {
        events,
      };

      cache.writeQuery({ query: queries.GET_EVENTS_QUERY, data });

      return data.events;
    },

    selectEvents: (_, { events }, { cache, client }) => {
      const { selectedEvents } = cache.readQuery({
        query: queries.GET_SELECTED_EVENTS_QUERY,
      });

      const data = {
        selectedEvents: events || [],
      };

      cache.writeQuery({ query: queries.GET_SELECTED_EVENTS_QUERY, data });

      if (
        events.length === 1 &&
        (selectedEvents.length === 0 || selectedEvents[0].id !== events[0].id)
      ) {
        const eventId = events[0].id;

        history.push({
          pathname: `/event/${eventId}`,
          state: { modal: !theme.isMobile },
        });

        client.query({
          query: getEventById,
          variables: {
            input: {
              id: eventId,
            },
          },
          skip: !eventId,
        });
      }

      return data.selectedEvents;
    },

    selectPods: (_, { pods }, { cache, client }) => {
      const { selectedPods } = cache.readQuery({
        query: queries.GET_SELECTED_PODS_QUERY,
      });

      const data = {
        selectedPods: pods || [],
      };

      cache.writeQuery({ query: queries.GET_SELECTED_PODS_QUERY, data });

      if (
        pods.length === 1 &&
        (selectedPods.length === 0 || selectedPods[0].id !== pods[0].id)
      ) {
        // TODO: add pod subroute
        // const podId = pods[0].id;
        // history.push({
        //   pathname: `${history.location.pathname}/pod/${podId}`,
        //   state: { modal: !theme.isMobile },
        // });
      }

      return pods;
    },

    selectUsers: (_, { users }, { cache, client }) => {
      const { selectedUsers } = cache.readQuery({
        query: queries.GET_SELECTED_USERS_QUERY,
      });

      const data = {
        selectedUsers: users || [],
      };

      cache.writeQuery({ query: queries.GET_SELECTED_USERS_QUERY, data });

      if (
        users.length === 1 &&
        (selectedUsers.length === 0 || selectedUsers[0].id !== users[0].id)
      ) {
        const username = users[0].username;

        history.push({
          pathname: `/@${username}`,
          state: { modal: !theme.isMobile },
        });

        client.query({
          query: getUserById,
          variables: {
            input: {
              username,
            },
          },
        });
      }

      return data.selectedUsers;
    },

    selectGroups: (_, { groups }, { cache }) => {
      const { selectedGroups } = cache.readQuery({
        query: queries.GET_SELECTED_GROUPS_QUERY,
      });

      const data = {
        selectedGroups: groups,
      };

      cache.writeQuery({ query: queries.GET_SELECTED_GROUPS_QUERY, data });

      if (
        groups.length === 1 &&
        (selectedGroups.length === 0 || selectedGroups[0].id !== groups[0].id)
      ) {
        history.push({
          pathname: `/group/${groups[0].id}`,
          state: { modal: !theme.isMobile },
        });
      }

      return data.selectedGroups;
    },

    deselectEvents: (_, { events }, { cache }) => {
      const { selectedEvents } = cache.readQuery({
        query: queries.GET_SELECTED_EVENTS_QUERY,
      });
      const newSelectedEvents = differenceBy(selectedEvents, events, "id");
      const data = {
        selectedEvents: newSelectedEvents,
      };
      cache.writeQuery({ query: queries.GET_SELECTED_EVENTS_QUERY, data });
      return data.selectedEvents;
    },

    clearSelectedEvents: (_, args, { cache }) => {
      const data = {
        selectedEvents: [],
      };
      cache.writeQuery({ query: queries.GET_SELECTED_EVENTS_QUERY, data });
      return data.selectedEvents;
    },

    setShowPodForm: (_, { showPodForm }, { cache }) => {
      const data = {
        showPodForm,
      };
      cache.writeQuery({ query: queries.GET_SHOW_POD_FORM, data });
      return showPodForm;
    },

    setShowPodTimeRangesForm: (_, { showPodTimeRangesForm }, { cache }) => {
      const data = {
        showPodTimeRangesForm,
      };
      cache.writeQuery({ query: queries.GET_SHOW_POD_TIME_RANGES_FORM, data });
      return showPodTimeRangesForm;
    },

    selectThread: (_, { thread }, { cache }) => {
      const data = {
        selectedThread: thread,
      };

      cache.writeQuery({ query: queries.GET_SELECTED_THREAD_QUERY, data });

      return data.selectedThread;
    },

    setShowEventForm: (_, { showEventForm }, { cache }) => {
      const data = {
        showEventForm,
      };
      cache.writeQuery({ query: queries.GET_SHOW_EVENT_FORM, data });
      return showEventForm;
    },

    setShowEventEditForm: (_, { showEventEditForm }, { cache }) => {
      const data = {
        showEventEditForm,
      };
      cache.writeQuery({ query: queries.GET_SHOW_EVENT_EDIT_FORM, data });
      return showEventEditForm;
    },

    setShowGroupForm: (_, { showGroupForm }, { cache }) => {
      const data = {
        showGroupForm,
      };
      cache.writeQuery({ query: queries.GET_SHOW_GROUP_FORM, data });
      return showGroupForm;
    },

    setShowInviteForm: (_, { showInviteForm }, { cache }) => {
      const data = {
        showInviteForm,
      };

      cache.writeQuery({ query: queries.GET_SHOW_INVITE_FORM, data });
      return showInviteForm;
    },

    setShowGroupEditForm: (_, { showGroupEditForm }, { cache }) => {
      const data = {
        showGroupEditForm,
      };
      cache.writeQuery({ query: queries.GET_SHOW_GROUP_EDIT_FORM, data });
      return showGroupEditForm;
    },

    setAddToCalendarEvent: (_, { event }, { cache }) => {
      const data = {
        addToCalendarEvent: event,
      };

      cache.writeQuery({ query: queries.GET_ADD_TO_CALENDAR_EVENT, data });

      return !!data.addToCalendarEvent;
    },
  },

  Event: {
    isSelected: (event, _, { cache }) => {
      const { selectedEvents } = cache.readQuery({
        query: queries.GET_SELECTED_EVENTS_QUERY,
      });
      return selectedEvents.map((e) => e.id).includes(event.id);
    },

    myPod: (event, _, { cache }) => {
      const { currentUser } = cache.readQuery({
        query: queries.GET_CURRENT_USER_QUERY,
      });

      if (currentUser?.podsConnection?.edges) {
        for (let i = 0; i < currentUser.podsConnection.edges.length; i++) {
          const pod = currentUser.podsConnection.edges[i].node;
          if (pod.event.id === event.id) {
            return pod;
          }
        }
      }

      return null;
    },

    profilePhoto: (event) => {
      return event.coverPicture || hashtagPlaceholder;
    },
  },

  Group: {
    isSelected: (group, _, { cache }) => {
      const { selectedGroups } = cache.readQuery({
        query: queries.GET_SELECTED_GROUPS_QUERY,
      });
      return selectedGroups.map((g) => g.id).includes(group.id);
    },

    profilePhoto: (group) => {
      if (!group) {
        return avatarPlaceholder;
      }
      if (group.profilePhoto) {
        return group.profilePhoto;
      }
      const name = group.name.toLowerCase();
      if (name === "close friends") {
        group.profilePhoto = closeFriendsPlaceholder;
      }
      if (name === "coworkers") {
        group.profilePhoto = coworkersPlaceholder;
      }
      if (name === "acquaintances") {
        group.profilePhoto = acquaintancesPlaceholder;
      }
      return avatarPlaceholder;
    },
  },

  Thread: {
    isSelected: (thread, _, { cache }) => {
      const { selectedThreads } = cache.readQuery({
        query: queries.GET_SELECTED_THREADS_QUERY,
      });
      return selectedThreads.map((t) => t.id).includes(thread.id);
    },

    title: (thread) => {
      let title;
      if (thread.members && thread.members[0]) {
        title = thread.members[0].name;
      }
      if (thread.parentPod) {
        title = thread.parentPod.event.title;
      }
      if (thread.parentGroup) {
        title = thread.parentGroup.name;
      }
      if (thread.name) {
        title = thread.name;
      }
      return title;
    },

    avatars: (thread, _, { cache }) => {
      const { currentUser } = cache.readQuery({
        query: queries.GET_CURRENT_USER_QUERY,
      });

      const avatars = [];
      if (thread.parentPod) {
        avatars.push(thread.parentPod.event.coverPicture);
      } else if (thread.parentGroup) {
        avatars.push(thread.parentGroup.profilePhoto);
      } else if (thread.members) {
        thread.members
          .filter((member) => {
            if (currentUser && currentUser.id === member.id) {
              return false;
            }
            return true;
          })
          .forEach(
            (member) =>
              member.profilePhotos && avatars.push(member.profilePhotos[0]),
          );
      }
      return compact(avatars);
    },
  },

  PublicUserProps: {
    profilePhoto,
    isCurrentUser,
  },

  User: {
    profilePhoto,
    isCurrentUser,
  },

  Hashtag: {
    profilePhoto: () => {
      return hashtagPlaceholder;
    },
  },
};

export { typeDefs, resolvers, TOKEN_NAME };
