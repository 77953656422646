import { useQuery, useMutation } from "@apollo/client";

import {
  getShowGroupFormQuery,
  setShowGroupFormMutation,
  selectedGroupsQuery,
  selectGroupsMutation,
  getShowInviteFormQuery,
  setShowInviteFormMutation,
} from "graphql/clientApi";

const useClientApiHooks = () => {
  const { data: data1 } = useQuery(selectedGroupsQuery);
  const selectedGroups = data1.selectedGroups;

  const { data: data2 } = useQuery(getShowGroupFormQuery);
  const isGroupFormOpen = data2.showGroupForm;

  const { data: data3 } = useQuery(getShowInviteFormQuery);
  const isInviteFormOpen = data3.showInviteForm;

  const [openGroupForm] = useMutation(setShowGroupFormMutation, {
    variables: { showGroupForm: true },
  });

  const [hideGroupForm] = useMutation(setShowGroupFormMutation, {
    variables: { showGroupForm: false },
  });

  const [openInviteForm] = useMutation(setShowInviteFormMutation, {
    variables: { showInviteForm: true },
  });

  const [closeInviteForm] = useMutation(setShowInviteFormMutation, {
    variables: { showInviteForm: false },
  });

  const [selectGroups] = useMutation(selectGroupsMutation);

  const selectGroupsWrapper = (groups) => {
    selectGroups({
      variables: {
        groups,
      },
    });
  };

  const closeGroupForm = () => {
    hideGroupForm();
    selectGroupsWrapper([]);
  };

  return {
    isGroupFormOpen,
    openGroupForm,
    selectedGroups,
    selectGroups: selectGroupsWrapper,
    closeGroupForm,
    isInviteFormOpen,
    openInviteForm,
    closeInviteForm,
  };
};

export default useClientApiHooks;
