import React, { useRef } from "react";
import { SnackbarProvider as Provider } from "notistack";
import { Button } from "mui-treasury/src/brands/peapods";
import { makeStyles } from "@material-ui/core/styles";

// TODO: show a count down timer
// https://github.com/iamhosseindhv/notistack/pull/50

const SnackBarProvider = ({ children }) => {
  const useStyles = makeStyles((theme) => ({
    success: { backgroundColor: theme.palette.primary.main },
    error: { backgroundColor: theme.palette.error.main },
    warning: { backgroundColor: "orange" },
    info: { backgroundColor: theme.palette.secondary.main },
  }));

  const classes = useStyles();
  const snackBarRef = useRef();

  return (
    <Provider
      ref={snackBarRef}
      action={(key) => (
        <Button
          onClick={() => {
            snackBarRef.current.closeSnackbar(key);
          }}
        >
          dismiss
        </Button>
      )}
      hideIconVariant
      maxSnack={3}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {children}
    </Provider>
  );
};

export default SnackBarProvider;
