import React, { Component } from "react";

import styled from "styled-components";

const Container1 = styled.div`
  min-height: 500px;
  position: relative;
  background: ${(props) => props.background};
  padding-top: ${(props) => props.topPadding || "0px"};
  padding-bottom: 100px;
`;

const Container = styled.div`
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

class SVGWaves extends Component {
  render() {
    return (
      <Container1
        background={this.props.background}
        topPadding={this.props.topPadding}
      >
        {this.props.children}
        <Container>
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 100 20"
            preserveAspectRatio="none"
          >
            <path
              fill={this.props.waveFillTop}
              opacity="0.5"
              d="M0 30 V15 Q30 3 61 15 V30z"
            />
            <path
              fill={this.props.waveFillBottom}
              d="M0 30 V12 Q30 17 56 12 T100 11 V30z"
            />
          </svg>
        </Container>
      </Container1>
    );
  }
}

export default SVGWaves;
