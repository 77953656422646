import React, { Component } from "react";

import CatCardActive from "images/category-cards/active.svg";
import CatCardGaming from "images/category-cards/gaming.svg";
import CatCardOutdoors from "images/category-cards/outdoors.svg";
import CatCardHobbies from "images/category-cards/hobbies.svg";
import CatCardMovies from "images/category-cards/movies.svg";
import CatCardCommunity from "images/category-cards/community.svg";
import CatCardNetworking from "images/category-cards/networking.svg";
import CatCardActivism from "images/category-cards/activism.svg";
import CatCardVolunteering from "images/category-cards/volunteering.svg";
import CatCardLearning from "images/category-cards/learning.svg";
import CatCardArtAndCulture from "images/category-cards/art-and-culture.svg";
import CatCardMusic from "images/category-cards/music.svg";
import CatCardSpirituality from "images/category-cards/spirituality.svg";
import CatCardFood from "images/category-cards/food.svg";
import CatCardDating from "images/category-cards/dating.svg";

import { colors } from "pages/Landing/landing/SharedStyles";

import {
  Container,
  TopRow,
  BottomRow,
  Column,
  HeaderText,
  CardGrid,
  Card,
  CardTitle,
} from "./eventCategories/styles";

const categories = [
  {
    title: "Active",
    image: CatCardActive,
    color: colors.mainRed,
  },
  {
    title: "Gaming",
    image: CatCardGaming,
    color: colors.mainRed,
  },
  {
    title: "Outdoors",
    image: CatCardOutdoors,
    color: colors.mainRed,
  },
  {
    title: "Hobbies",
    image: CatCardHobbies,
    color: colors.mainRed,
  },
  {
    title: "Movies",
    image: CatCardMovies,
    color: colors.mainRed,
  },
  {
    title: "Community",
    image: CatCardCommunity,
    color: colors.secondaryBlue,
  },
  {
    title: "Networking",
    image: CatCardNetworking,
    color: colors.secondaryBlue,
  },
  {
    title: "Activism",
    image: CatCardActivism,
    color: colors.secondaryBlue,
  },
  {
    title: "Volunteering",
    image: CatCardVolunteering,
    color: colors.secondaryBlue,
  },
  {
    title: "Learning",
    image: CatCardLearning,
    color: colors.secondaryBlue,
  },
  {
    title: "Art and Culture",
    image: CatCardArtAndCulture,
    color: colors.mainPurple,
  },
  {
    title: "Music",
    image: CatCardMusic,
    color: colors.mainPurple,
  },
  {
    title: "Spirituality",
    image: CatCardSpirituality,
    color: colors.mainPurple,
  },
  {
    title: "Food",
    image: CatCardFood,
    color: colors.mainPurple,
  },
  {
    title: "Dating",
    image: CatCardDating,
    color: colors.mainPurple,
  },
];

class EventCategories extends Component {
  renderCategoryCard = (cat, idx) => {
    return (
      <Card key={idx} color={cat.color}>
        <CardTitle>{cat.title}</CardTitle>
        <img src={cat.image} alt={cat.title} />
      </Card>
    );
  };

  render() {
    return (
      <Container>
        <TopRow>
          <Column>
            <HeaderText>Event Categories</HeaderText>
          </Column>
        </TopRow>
        <BottomRow>
          <CardGrid>{categories.map(this.renderCategoryCard)}</CardGrid>
        </BottomRow>
      </Container>
    );
  }
}

export default EventCategories;
