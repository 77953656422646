import useGetInviteCodes from "./getInviteCodes";
import useApproveInviteCode from "./approveInviteCode";
import useDenyInviteCode from "./denyInviteCode";
import useDeleteInviteCode from "./deleteInviteCode";
import useGenerateInviteCode from "./generateInviteCode";
import useUpdateInviteCode from "./updateInviteCode";

const useInviteHooks = () => {
  const { getInviteCodesData, getInviteCodesLoading } = useGetInviteCodes();
  const { approveInviteCode, approveInviteCodeLoading } =
    useApproveInviteCode();
  const { denyInviteCode, denyInviteCodeLoading } = useDenyInviteCode();
  const { deleteInviteCode, deleteInviteCodeLoading } = useDeleteInviteCode();
  const { generateInviteCode, generateInviteCodeLoading } =
    useGenerateInviteCode();
  const { updateInviteCode, updateInviteCodeLoading } = useUpdateInviteCode();

  return {
    getInviteCodesData,
    getInviteCodesLoading,
    approveInviteCode,
    approveInviteCodeLoading,
    denyInviteCode,
    denyInviteCodeLoading,
    deleteInviteCode,
    deleteInviteCodeLoading,
    generateInviteCode,
    generateInviteCodeLoading,
    updateInviteCode,
    updateInviteCodeLoading,
  };
};

export default useInviteHooks;
