import React, { useState, useEffect, useCallback } from "react";
import { Backdrop } from "mui-treasury/src/brands/peapods";
import { useSnackbar } from "notistack";

import { useCurrentUser } from "graphql/api";

import CookieSnackbar from "./CookieSnackbar";

const COOKIE_WAIT_TIME = 5000;

const CookiePopup = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const currentUser = useCurrentUser();

  const [isOpen, setIsOpen] = useState(false);

  const onAccept = useCallback(
    (key) => {
      closeSnackbar(key);
      setIsOpen(false);
    },
    [closeSnackbar, setIsOpen],
  );

  useEffect(() => {
    setTimeout(() => {
      if (currentUser && !window.localStorage.getItem("cookieAccepted")) {
        setIsOpen(true);
        enqueueSnackbar("", {
          persist: true,
          content: () => <CookieSnackbar closeSnackbar={onAccept} />,
        });
      }
    }, COOKIE_WAIT_TIME);
  }, [enqueueSnackbar, onAccept, currentUser]);

  return (
    <Backdrop
      style={{
        zIndex: 1000,
      }}
      open={isOpen}
    />
  );
};

export default CookiePopup;
