/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { SearchInput } from "mui-treasury/src/brands/peapods";

function SearchInputControl(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes },
  } = props;

  return (
    <SearchInput
      fullWidth
      inputComponent="div"
      inputProps={{
        className: classes.input,
        ref: innerRef,
        children,
        ...innerProps,
      }}
    />
  );
}

SearchInputControl.propTypes = {
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
};

export default SearchInputControl;
