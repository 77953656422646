import React, { memo } from "react";
import { theme, useMediaQuery } from "mui-treasury/src/brands/peapods";
import { ThemeProvider } from "@material-ui/styles";
import Bowser from "bowser";

import ErrorBoundary from "pure-components/ErrorBoundary";
import SnackbarProvider from "pure-components/SnackbarProvider";
import { Provider as GetstreamProvider } from "smart-components/Navbar/getstreamHooks";

import ApolloProvider from "graphql/ApolloProvider";

const Providers = ({ children }) => {
  const { md, lg } = theme.breakpoints.values;

  theme.isMobile = useMediaQuery(`(max-width:${md}px)`);
  theme.isTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));
  theme.isDesktop = useMediaQuery(`(min-width:${lg}px)`);

  theme.browserInfo = Bowser.parse(window.navigator.userAgent);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <ErrorBoundary>
          <ApolloProvider>
            <GetstreamProvider>{children}</GetstreamProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default memo(Providers);
