import React, { useState, useEffect, memo, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";
import TimeUtils from "@date-io/dayjs";
import tzLookup from "tz-lookup";

import {
  getAddToCalendarEventQuery,
  setAddToCalendarEventMutation,
} from "graphql/clientApi";

import CalendarModal from "./CalendarModal";
import CalendarButton from "./CalendarButton";

const AddToCalendarModal = AddToCalendarHOC(CalendarButton, CalendarModal);

const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

const timeUtils = new TimeUtils();
const { dayjs } = timeUtils;

const AddToCalendarDialog = () => {
  const {
    data: { addToCalendarEvent },
  } = useQuery(getAddToCalendarEventQuery);

  const [setAddToCalendarEvent] = useMutation(setAddToCalendarEventMutation);

  const [event, setEvent] = useState();

  const onDataChanged = () => {
    if (!addToCalendarEvent || !addToCalendarEvent.startTime) {
      setEvent(undefined);
      return;
    }

    const { id, startTime, endTime, title, location } = addToCalendarEvent;

    const startDatetime = dayjs(startTime).utc();
    const endDatetime = endTime
      ? dayjs(endTime).utc()
      : startDatetime.clone().add(2, "hours");

    const duration = dayjs
      .duration(endDatetime.diff(startDatetime))
      .asHours()
      .toString();

    const timezone = tzLookup(location.lat, location.lng);

    setEvent({
      title,
      description: `${window.location.origin}/event/${id}`,
      location: location?.formattedAddress,
      endDatetime: endDatetime.format("YYYYMMDDTHHmmssZ"),
      startDatetime: startDatetime.format("YYYYMMDDTHHmmssZ"),
      duration,
      timezone,
    });
  };

  const clearEvent = useCallback(() => {
    setAddToCalendarEvent({
      variables: {
        event: null,
      },
    });
  }, [setAddToCalendarEvent]);

  useEffect(onDataChanged, [addToCalendarEvent]);

  if (!event) {
    return null;
  }

  return (
    <AddToCalendarModal
      buttonProps={{
        isOpen: !!event,
      }}
      dropdownProps={{
        isOpen: !!event,
        clearEvent,
      }}
      items={
        isiOS
          ? [SHARE_SITES.GOOGLE]
          : [SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.OUTLOOK]
      }
      event={event}
    />
  );
};

export default memo(AddToCalendarDialog);
