import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
import { useApolloClient } from "@apollo/client";

import { getGroupById as getGroupByIdQuery } from "graphql/api";
import useClientApiHooks from "graphql/clientApi/hooks";

import useCreateGroup from "./createGroup";
import useUpdateGroup from "./updateGroup";
import useDeleteGroup from "./deleteGroup";
import useJoinGroup from "./joinGroup";
import useLeaveGroup from "./leaveGroup";
import useGetGroupById from "./getGroupById";
import useInviteUsersToGroup from "./inviteUsersToGroup";

// TODO: we need to implement group admin features

const useGroupHooks = () => {
  const client = useApolloClient();
  const history = useHistory();
  const lastLocation = useLastLocation();
  const { selectGroups } = useClientApiHooks();

  const { createGroup, createGroupLoading } = useCreateGroup();
  const { updateGroup, updateGroupLoading } = useUpdateGroup();
  const { deleteGroup, deleteGroupData, deleteGroupLoading } = useDeleteGroup();
  const { joinGroup, joinGroupLoading } = useJoinGroup();
  const { leaveGroup, leaveGroupData, leaveGroupLoading } = useLeaveGroup();
  const { inviteUsersToGroup, inviteUsersToGroupLoading } =
    useInviteUsersToGroup();

  const [shouldHandleDelete, setShouldHandleDelete] = useState(true);

  const refetchQueries = ["getCurrentUser", "getUserById", "getGroupById"];

  const onDeleteGroup = (id) => {
    client.cache.evict(`Group:${id}`);
    client.cache.gc();
    selectGroups({ variables: { groups: [] } });
  };

  const createGroupWrapper = async (input) => {
    const result = await createGroup({
      variables: {
        input,
      },
      refetchQueries,
    });
    return result.data && result.data.createGroup;
  };

  const joinGroupWrapper = (id) => {
    joinGroup({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries,
    });
  };

  const leaveGroupWrapper = async (id) => {
    const result = await leaveGroup({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: ["getCurrentUser", "getUserById"],
      update: (cache, { data }) => {
        if (data && data.leaveGroup.success) {
          if (data.leaveGroup.deleted) {
            onDeleteGroup(id);
          } else {
            client.query({
              fetchPolicy: "cache-first",
              query: getGroupByIdQuery,
              variables: {
                input: {
                  id,
                },
              },
            });
          }
        }
      },
    });
    return result.data && result.data.leaveGroup;
  };

  const deleteGroupWrapper = (id) => {
    deleteGroup({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries,
      update: (cache, data) => {
        onDeleteGroup(id);
      },
    });
  };

  const updateGroupWrapper = async (input) => {
    const result = await updateGroup({
      variables: {
        input,
      },
      refetchQueries,
    });
    return result.data && result.data.updateGroup;
  };

  const inviteUsersToGroupWrapper = (input) => {
    inviteUsersToGroup({
      variables: {
        input,
      },
      refetchQueries,
    });
  };

  useEffect(() => {
    setShouldHandleDelete(true);
  }, [deleteGroupData, leaveGroupData]);

  useEffect(() => {
    if (
      shouldHandleDelete &&
      history.location &&
      lastLocation &&
      !deleteGroupLoading &&
      !leaveGroupLoading &&
      (deleteGroupData ||
        (leaveGroupData && leaveGroupData.leaveGroup.deleted)) &&
      history.location.pathame?.split("/")[1] === "group"
    ) {
      if (lastLocation.pathname !== history.location.pathname) {
        history.goBack();
      } else {
        history.replace("/");
      }
      setShouldHandleDelete(false);
    }
  }, [
    deleteGroupData,
    deleteGroupLoading,
    leaveGroupData,
    leaveGroupLoading,
    lastLocation,
    history,
    shouldHandleDelete,
  ]);

  return {
    useGetGroupById,
    createGroup: createGroupWrapper,
    createGroupLoading,
    updateGroup: updateGroupWrapper,
    updateGroupLoading,
    deleteGroup: deleteGroupWrapper,
    deleteGroupLoading,
    joinGroup: joinGroupWrapper,
    joinGroupLoading,
    leaveGroup: leaveGroupWrapper,
    leaveGroupLoading,
    inviteUsersToGroup: inviteUsersToGroupWrapper,
    inviteUsersToGroupLoading,
  };
};

export default useGroupHooks;
