import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import DebounceLink from "apollo-link-debounce";

import { resolvers, typeDefs, TOKEN_NAME } from "./resolvers";

const createClient = ({ handleError }) => {
  const DEFAULT_DEBOUNCE_TIMEOUT = 300;

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND_API_URL}/graphql`,
  });

  const authLink = setContext((_, { headers, token }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const errorLink = onError(handleError);

  const asyncTokenLink = setContext(
    async () => await { token: window.localStorage.getItem(TOKEN_NAME) },
  );

  const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

  // TODO: add request timeout option
  // https://github.com/apollographql/apollo-link/issues/954
  const link = createPersistedQueryLink({
    generateHash: ({ documentId }) => documentId,
  })
    .concat(errorLink)
    .concat(asyncTokenLink)
    .concat(debounceLink)
    .concat(authLink)
    .concat(httpLink);

  const cache = new InMemoryCache({
    addTypename: true,
    freezeResults: true,
  });

  // TODO: see examples on how to use
  // https://codesandbox.io/s/5v1ol0nkjx
  // https://codesandbox.io/s/apollo-graphql-reacthooks-example-gbuus
  const client = new ApolloClient({
    name: process.env.REACT_APP_NAME,
    version: process.env.REACT_APP_VERSION,
    link,
    cache,
    typeDefs,
    resolvers,
    defaultOptions: {
      query: {
        errorPolicy: "ignore",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });

  // Use this object for 'redux-like' global app state
  // https://www.apollographql.com/docs/tutorial/local-state/
  const data = {
    isLoggedIn: !!window.localStorage.getItem(TOKEN_NAME),
    selectedUsers: [],
    selectedEvents: [],
    selectedPods: [],
    selectedGroups: [],
    users: [],
    events: [],
    currentUser: null,
    showPodForm: false,
    showPodTimeRangesForm: false,
    showEventForm: false,
    showEditEventForm: false,
    showGroupForm: false,
    showEditGroupForm: false,
    showInviteForm: false,
    addToCalendarEvent: null,
  };

  cache.writeData({ data });

  client.onResetStore(() => cache.writeData({ data }));

  return client;
};

export default createClient;
