import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  Paper,
  Popover,
  MenuItem,
  Avatar,
  LoadingSpinner,
  logo,
  makeStyles,
} from "mui-treasury/src/brands/peapods";
import { FaMapSigns as RoadmapIcon } from "react-icons/fa";
import { FiMessageSquare as FeedbackIcon } from "react-icons/fi";
import { MdFormatListBulleted as ChangelogIcon } from "react-icons/md";

import { mutations } from "graphql/clientApi";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import useClientApiHooks from "graphql/clientApi/hooks";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: 20,
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
}));

const ProfileMenu = ({
  isMobile,
  username,
  userImage,
  showMobileSearch,
  onMenuClosed,
  ...props
}) => {
  const classes = useStyles();

  const { openInviteForm } = useClientApiHooks();
  const [anchorEl, setAnchorEl] = useState(null);
  const [logout] = useMutation(mutations.LOGOUT);

  function onClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function onClose(event) {
    setAnchorEl(null);

    if (onMenuClosed) {
      onMenuClosed(event);
    }
  }

  function openInvites(event) {
    openInviteForm();
    onClose(event);
  }

  const onShowSearch = () => {
    setAnchorEl(null);
    showMobileSearch();
  };

  return (
    <>
      {userImage ? (
        <Avatar
          id="user-menu-avatar"
          aria-controls="simple-menu"
          aria-haspopup="true"
          src={userImage}
          size={isMobile ? "small" : "big"}
          isClickable
          onClick={onClick}
          {...props}
        />
      ) : (
        <LoadingSpinner />
      )}
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isMobile ? "center" : "right",
        }}
        style={{
          top: isMobile ? -72 : 0,
        }}
      >
        <Paper>
          <Link to={`/@${username}`}>
            <MenuItem
              id="user-profile"
              classes={{
                root: classes.menuItem,
              }}
              icon={<Avatar src={logo} size="tiny" />}
              onClick={onClose}
              label="My Profile"
              value="My Profile"
            />
          </Link>

          <MenuItem
            classes={{
              root: classes.menuItem,
            }}
            icon={<GroupAddIcon />}
            onClick={openInvites}
            label="Invites"
            value="Invites"
          />
          <Link to={`/feedback/comments`}>
            <MenuItem
              classes={{
                root: classes.menuItem,
              }}
              icon={<FeedbackIcon />}
              IconProps={{
                shape: "square",
              }}
              onClick={onClose}
              label="Feedback"
              value="Feedback"
            />
          </Link>

          <Link to={`/feedback/roadmap`}>
            <MenuItem
              classes={{
                root: classes.menuItem,
              }}
              icon={<RoadmapIcon />}
              IconProps={{
                shape: "square",
              }}
              onClick={onClose}
              label="Roadmap"
              value="Roadmap"
            />
          </Link>

          <Link to={`/feedback/changelog`}>
            <MenuItem
              classes={{
                root: classes.menuItem,
              }}
              icon={<ChangelogIcon />}
              IconProps={{
                shape: "square",
              }}
              onClick={onClose}
              label="Changelog"
              value="Changelog"
            />
          </Link>

          <Link to="/settings">
            <MenuItem
              classes={{
                root: classes.menuItem,
              }}
              icon={"settings"}
              onClick={onClose}
              label="Settings"
              value="Settings"
            />
          </Link>

          {isMobile && (
            <MenuItem
              classes={{
                root: classes.menuItem,
              }}
              icon={"search"}
              label="Search"
              value="search"
              onClick={onShowSearch}
            />
          )}

          <MenuItem
            id="logout-button"
            classes={{
              root: classes.menuItem,
            }}
            icon={"logout"}
            label="Logout"
            value="Logout"
            onClick={logout}
          />
        </Paper>
      </Popover>
    </>
  );
};

export default ProfileMenu;
