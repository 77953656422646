import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import getGroupByIdQuery from "../../queries/group/getGroupById.graphql";

const useGetGroupById = (id) => {
  const [group, setGroup] = useState();
  const [currentUserMemberState, setCurrentUserMemberState] = useState();
  const [getstreamToken, setGetstreamToken] = useState();

  const { data: getGroupByIdData, loading: getGroupByIdLoading } = useQuery(
    getGroupByIdQuery,
    {
      variables: {
        input: {
          id,
        },
      },
      skip: !id,
    },
  );

  useEffect(() => {
    if (getGroupByIdData) {
      const { group, currentUserMemberState, getstreamToken } =
        getGroupByIdData.getGroupById;
      setGroup(group);
      setCurrentUserMemberState(currentUserMemberState);
      setGetstreamToken(getstreamToken);
    }
  }, [getGroupByIdData]);

  return {
    group,
    getGroupByIdLoading,
    currentUserMemberState,
    getstreamToken,
  };
};

export default useGetGroupById;
