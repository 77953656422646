import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import getThreadByIdQuery from "../../queries/thread/getThreadById.graphql";

const useGetThreadById = (id) => {
  const [thread, setThread] = useState(null);

  const { data: getThreadByIdData, loading: getThreadByIdLoading } = useQuery(
    getThreadByIdQuery,
    {
      variables: {
        input: {
          id,
        },
      },
      skip: !id,
    },
  );

  useEffect(() => {
    if (getThreadByIdData) {
      const { thread } = getThreadByIdData.getThreadById;
      setThread(thread);
    }
  }, [getThreadByIdData]);

  return {
    thread,
    getThreadByIdLoading,
  };
};

export default useGetThreadById;
