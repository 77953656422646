import { useQuery } from "@apollo/client";

import { getInviteCodes as getInviteCodesQuery } from "graphql/api";

const useGetInviteCodes = () => {
  const { data, loading } = useQuery(getInviteCodesQuery, {
    variables: {
      input: {},
    },
  });

  return {
    getInviteCodesData: data,
    getInviteCodesLoading: loading,
  };
};

export default useGetInviteCodes;
