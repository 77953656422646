import { useMutation } from "@apollo/client";

import { updateInviteCode as updateInviteCodeMutation } from "graphql/api";

const useUpdateInviteCode = () => {
  const [updateInviteCode, { loading }] = useMutation(updateInviteCodeMutation);

  return {
    updateInviteCode,
    updateInviteCodeLoading: loading,
  };
};

export default useUpdateInviteCode;
