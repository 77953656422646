import { useMutation } from "@apollo/client";

import { inviteUsersToGroup as inviteUsersToGroupMutation } from "graphql/api";

const useInviteUsersToGroup = () => {
  const [inviteUsersToGroup, { loading: inviteUsersToGroupLoading }] =
    useMutation(inviteUsersToGroupMutation, {
      refetchQueries: ["getGroupById", "getCurrentUser"],
    });

  return {
    inviteUsersToGroup,
    inviteUsersToGroupLoading,
  };
};

export default useInviteUsersToGroup;
