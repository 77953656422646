import { useMutation } from "@apollo/client";

import { deleteGroup as deleteGroupMutation } from "graphql/api";

const useDeleteGroup = () => {
  const [deleteGroup, { data: deleteGroupData, loading: deleteGroupLoading }] =
    useMutation(deleteGroupMutation, {
      refetchQueries: ["getCurrentUser", "getUserById"],
    });

  return {
    deleteGroup,
    deleteGroupData,
    deleteGroupLoading,
  };
};

export default useDeleteGroup;
