import styled from "styled-components";

const CalendarOptions = styled.div`
  width: 200px;
  text-align: center;

  a {
    display: block;
    padding: 10px;
    color: rgba(0, 0, 0, 0.87);
    &:visited {
      color: rgba(0, 0, 0, 0.87);
    }
    &:hover {
      background: #eee;
    }
  }
`;

export { CalendarOptions };
