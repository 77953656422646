import React, { useState, useEffect, useRef, memo } from "react";
import { withRouter, Link, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useWindowSize } from "react-use";
import {
  MenuItem,
  Button,
  theme,
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  logo as peapodsLogo,
} from "mui-treasury/src/brands/peapods";

import { mutations } from "graphql/clientApi";
import { useCurrentUser } from "graphql/api";
import { getCurrentUser as getCurrentUserQuery } from "graphql/api";

import ProfileMenu from "./ProfileMenu";
import Search from "./Search";
import SpeedDial from "./SpeedDial";
import OnboardingGuide from "./OnboardingGuide/index.jsx";

import {
  NavbarWrapper,
  NavbarContainer,
  NavbarContent,
  LeftContent,
  CenterContent,
  RightContent,
  Logo,
  MobileSearchContainer,
  SpeedDialContainer,
  MobileSpeedDialContainer,
  BottomContainer,
} from "./styles";

import { useGetstreamContext } from "./getstreamHooks";
import InviteForm from "../InviteForm";

const PROFILE_MENU_ITEM = 5;

const Navbar = ({ history, location, isLoggedIn }) => {
  const { pathname } = useLocation();
  const defaultValue = "/" + pathname?.split("/")[1] || "/";

  const { width } = useWindowSize();

  const shouldAnimateMenu =
    location.pathname?.indexOf("/discover") === 0 || location.pathname === "/";

  const { isMobile } = theme;

  const navRef = useRef();

  const [prevValue, setPrevValue] = useState();
  const [value, setValue] = useState(defaultValue);
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
  const [isSpeedDialOpen, setSpeedDialOpen] = useState(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState(true);
  const [showSearchButton, setShowSearchButton] = useState(true);
  const [hideMenuLabels, setHideMenuLabels] = useState(width < 1600);

  const toggleShowSearchButton = () => setShowSearchButton(!showSearchButton);

  const currentUser = useCurrentUser();

  const { data } = useQuery(getCurrentUserQuery, {
    skip: !isLoggedIn,
  });
  const getCurrentUser = data ? data.getCurrentUser : null;

  const [openEventForm] = useMutation(mutations.SET_SHOW_EVENT_FORM, {
    variables: {
      showEventForm: true,
    },
  });

  const {
    newNotificationCount,
    newMessageCount,
    getFeedPage,
    markMessagesAsRead,
  } = useGetstreamContext({
    userId: currentUser ? currentUser.id : null,
    getstreamUserToken: getCurrentUser ? getCurrentUser.getstreamToken : null,
  });

  const [openGroupForm] = useMutation(mutations.SET_SHOW_GROUP_FORM, {
    variables: {
      showGroupForm: true,
    },
  });

  const onSpeedDialClose = (e, reason) => {
    if (reason === "mouseLeave" || reason === "blur") {
      return;
    }
    setSpeedDialOpen(false);
  };

  const onSpeedDialOpen = (e, reason) => {
    if (reason === "toggle") {
      setSpeedDialOpen(true);
    }
  };

  const onOnboardingOpen = () => {
    setIsOnboardingOpen(true);
  };

  const onOnboardingClosed = () => {
    setIsOnboardingOpen(false);
  };

  const onSpeedDialAction = (actionName) => {
    if (actionName === "Create Event") {
      openEventForm();
    }
    if (actionName === "Create Group") {
      openGroupForm();
    }
    if (actionName === "Create Message") {
      history.push("/messages/new");
    }
    setSpeedDialOpen(false);
  };

  const onNotificationsClicked = () => {
    getFeedPage();
    setTimeout(() => {
      history.push("/notifications", {
        reloadFeed: true,
      });
    }, 0);
  };

  const onMessagesClicked = () => {
    markMessagesAsRead();
  };

  const userImage = currentUser?.profilePhoto;
  const username = currentUser?.username;

  function handleChange(event, newValue) {
    if (newValue === PROFILE_MENU_ITEM) {
      setPrevValue(value);
    }
    setValue(newValue);
    history.push(newValue);
  }

  const toggleMobileSearch = () => {
    setIsMobileSearchVisible(!isMobileSearchVisible);
  };

  const onCurrentUser = () => {
    if (!currentUser) {
      return;
    }

    setIsOnboarded(currentUser.settings && currentUser.settings.isOnboarded);
  };

  const onPathnameChanged = () => {
    const newValue = "/" + pathname?.split("/")[1] || "/";

    if (newValue !== value) {
      setValue(newValue);
    }
  };

  // const hasSnapchatToken = !!window.localStorage.getItem("snapchatToken");

  useEffect(onCurrentUser, [currentUser]);
  useEffect(() => setHideMenuLabels(width < 1600), [width]);
  useEffect(onPathnameChanged, [pathname, value]);

  const onProfileMenuClosed = (e) => {
    e.stopPropagation();
    setValue(prevValue);
  };

  return (
    <NavbarWrapper
      ref={navRef}
      isMobile={isMobile}
      shouldAnimate={shouldAnimateMenu}
    >
      {!isMobile ? (
        <NavbarContainer>
          {/* TODO: remove this when we have OAuth2 snapchat client secret */}
          {/* {!hasSnapchatToken && (
            <button id="snapchat-auth-button" style={{ position: "absolute" }}>
              link snapchat
            </button>
          )} */}

          <NavbarContent container direction="row" alignItems="center">
            <LeftContent item lg={6} direction="row">
              <Link to="/">
                <MenuItem
                  icon={"home"}
                  isSelected={pathname === "/"}
                  label={hideMenuLabels ? undefined : "Home"}
                />
              </Link>

              <Link to="/discover">
                <MenuItem
                  icon={"search"}
                  isSelected={pathname?.startsWith("/discover")}
                  label={hideMenuLabels ? undefined : "Discover"}
                />
              </Link>

              <Link to="/notifications">
                <MenuItem
                  isSelected={pathname?.startsWith("/notifications")}
                  icon={"notifications"}
                  label={hideMenuLabels ? undefined : "Notifications"}
                  badgeContent={newNotificationCount}
                  badgeShowed
                  onClick={onNotificationsClicked}
                />
              </Link>

              <Link to="/messages">
                <MenuItem
                  icon={"mail"}
                  isSelected={pathname?.startsWith("/messages")}
                  label={hideMenuLabels ? undefined : "Messages"}
                  badgeContent={newMessageCount}
                  badgeShowed
                  onClick={onMessagesClicked}
                />
              </Link>
            </LeftContent>

            <CenterContent item lg={2} direction="row" alignItems="center">
              <Link to="/">
                <Logo src={peapodsLogo} alt="peapods-logo" />
              </Link>
            </CenterContent>

            <RightContent item lg={4} direction="row" alignItems="center">
              <Search
                isMobile={isMobile}
                isVisible
                showButton={showSearchButton}
                toggleButton={toggleShowSearchButton}
              />

              {showSearchButton && (
                <>
                  <SpeedDialContainer>
                    {!isOnboarded && (
                      <OnboardingGuide
                        isOpen={isOnboardingOpen}
                        onOpen={onOnboardingOpen}
                        onClose={onOnboardingClosed}
                      />
                    )}

                    <SpeedDial
                      isOpen={isSpeedDialOpen}
                      onOpen={onSpeedDialOpen}
                      onClose={onSpeedDialClose}
                      onAction={onSpeedDialAction}
                    />
                  </SpeedDialContainer>

                  <ProfileMenu
                    isMobile={isMobile}
                    userImage={userImage}
                    username={username}
                  />
                </>
              )}
            </RightContent>
          </NavbarContent>
        </NavbarContainer>
      ) : (
        <BottomContainer>
          {!isOnboarded && (
            <OnboardingGuide
              isOpen={isOnboardingOpen}
              onOpen={onOnboardingOpen}
              onClose={onOnboardingClosed}
            />
          )}

          <MobileSpeedDialContainer>
            <SpeedDial
              isOpen={isSpeedDialOpen}
              onOpen={onSpeedDialOpen}
              onClose={onSpeedDialClose}
              onAction={onSpeedDialAction}
              isMobile
            />
          </MobileSpeedDialContainer>

          <BottomNavigation
            value={value}
            onChange={handleChange}
            style={{
              height: 72,
              overflowY: isMobileSearchVisible ? "unset" : "hidden",
            }}
          >
            <MobileSearchContainer
              className={isMobileSearchVisible ? "show" : ""}
            >
              <Grid
                container
                spacing={2}
                justify="center"
                style={{ margin: 0 }}
              >
                <Grid item style={{ flexBasis: "80%" }}>
                  <Search
                    isMobile
                    isVisible={isMobileSearchVisible}
                    toggleButton={toggleMobileSearch}
                  />
                </Grid>

                <Grid item>
                  <Button
                    shape={"circular"}
                    icon={"close"}
                    size={"small"}
                    color="primary"
                    onClick={toggleMobileSearch}
                    variant={"contained"}
                  />
                </Grid>
              </Grid>
            </MobileSearchContainer>

            <BottomNavigationAction
              label="Messages"
              value="/messages"
              icon={
                <MenuItem
                  isSelected={pathname?.startsWith("/messages")}
                  badgeShowed
                  badgeContent={newMessageCount}
                  isVertical
                  icon={"mail"}
                  IconProps={{
                    style: {
                      color: "inherit",
                    },
                  }}
                />
              }
            />

            <BottomNavigationAction
              label="Discover"
              value="/discover"
              icon={
                <MenuItem
                  isSelected={pathname?.startsWith("/discover")}
                  isVertical
                  icon={"search"}
                  IconProps={{
                    style: {
                      color: "inherit",
                    },
                  }}
                />
              }
            />

            <BottomNavigationAction
              label="Home"
              value="/"
              icon={
                <MenuItem
                  isSelected={pathname === "/"}
                  isVertical
                  icon={"home"}
                  IconProps={{
                    style: {
                      color: "inherit",
                    },
                  }}
                />
              }
            />

            <BottomNavigationAction
              label="Notifications"
              value="/notifications"
              icon={
                <MenuItem
                  isSelected={pathname?.startsWith("/notifications")}
                  badgeShowed
                  badgeContent={newNotificationCount}
                  isVertical
                  icon={"notifications"}
                  IconProps={{
                    style: {
                      color: "inherit",
                    },
                  }}
                />
              }
            />

            <BottomNavigationAction
              label="Profile"
              icon={
                <ProfileMenu
                  isMobile={isMobile}
                  userImage={userImage}
                  username={username}
                  showMobileSearch={toggleMobileSearch}
                  onMenuClosed={onProfileMenuClosed}
                />
              }
            />
          </BottomNavigation>
        </BottomContainer>
      )}
      <InviteForm />
    </NavbarWrapper>
  );
};

export default memo(withRouter(Navbar));
