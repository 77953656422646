import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  FileCopyOutlined as CopyIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { orderBy } from "lodash";

import {
  Avatar,
  Button,
  LoadingSpinner,
} from "mui-treasury/src/brands/peapods";
import { useInviteHooks } from "graphql/api";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    minWidth: 400,
    height: 380,
    overflow: "auto",
    marginTop: 5,
    [breakpoints.down("xs")]: {
      minWidth: 300,
      height: 500,
    },
  },
  inviteTable: {
    tableLayout: "fixed",
  },
  headerLabel: {
    position: "sticky",
    top: 0,
    backgroundColor: palette.background.paper,
    color: palette.primary.contrastText,
    zIndex: 5,
  },
  inviteTableRow: {
    height: 100,
  },
  inviteCodeCell: {
    [breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  inviteCode: {
    display: "flex",
    minWidth: 100,
    alignItems: "center",
    justifyContent: "space-between",
  },
  copyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.grey[400],
    borderRadius: "50%",
    width: 25,
    height: 25,
  },
  copyIcon: {
    width: 12,
    height: 12,
    cursor: "pointer",
    color: palette.common.white,
  },
}));

function InvitesTable() {
  const classes = useStyles();

  const { getInviteCodesData, getInviteCodesLoading } = useInviteHooks();

  const getInviteList = () => {
    const { getInviteCodes } = getInviteCodesData;
    const { edges } = getInviteCodes;

    const sortedEdges = orderBy(edges, ["inviteCode"], ["asc"]);

    return sortedEdges.map(({ node: { inviteCode, status, user } }, index) => (
      <InvitesTableRow
        key={index}
        inviteCode={inviteCode}
        status={status}
        user={user}
      />
    ));
  };

  const getEmptyInvitePlaceholder = () => {
    const { getInviteCodes } = getInviteCodesData;
    const { edges } = getInviteCodes;

    if (!edges.length) {
      return (
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
          height={300}
        >
          <Typography variant="body2">No Invites yet</Typography>
        </Box>
      );
    }
  };

  return (
    <>
      {getInviteCodesLoading ? (
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
          height={300}
        >
          <LoadingSpinner />
        </Box>
      ) : (
        <div className={classes.container}>
          <Table className={classes.inviteTable}>
            <InvitesTableHeader />
            <TableBody>{getInviteList()}</TableBody>
          </Table>
          {getEmptyInvitePlaceholder()}
        </div>
      )}
    </>
  );
}

function InvitesTableHeader() {
  const classes = useStyles();

  const Item = (label) => (
    <Typography variant="body1" color="textPrimary">
      {label}
    </Typography>
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.headerLabel}>{Item("Code")}</TableCell>
        <TableCell className={classes.headerLabel}>{Item("Status")}</TableCell>
        <TableCell className={classes.headerLabel} align="center">
          {Item("Pea")}
        </TableCell>
        <TableCell className={classes.headerLabel} align="center">
          {Item("Action")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function InvitesTableRow({ inviteCode, status, user }) {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const {
    approveInviteCode,
    approveInviteCodeLoading,
    denyInviteCode,
    denyInviteCodeLoading,
    deleteInviteCode,
    deleteInviteCodeLoading,
  } = useInviteHooks();

  const userId = user.id;

  const onApprove = () => {
    const variables = {
      input: {
        userId,
        inviteCode,
      },
    };

    approveInviteCode({ variables });
  };

  const onDeny = () => {
    const variables = {
      input: {
        userId,
        inviteCode,
      },
    };

    denyInviteCode({ variables });
  };

  const onDelete = () => {
    const variables = {
      input: {
        inviteCode,
      },
    };

    deleteInviteCode({ variables });
  };
  const onCopy = () => {
    setCopied(true);
  };

  let inviteStatus = status;

  const isUsed = status === "APPROVED" || status === "DENIED";

  if (status === "PENDING_APPROVAL") {
    inviteStatus = "PENDING";
  }

  let actionButtons = null;

  const buttonStyle = { fontSize: 12, minHeight: 30 };

  if (inviteStatus === "PENDING") {
    actionButtons = (
      <>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          loading={approveInviteCodeLoading}
          onClick={onApprove}
          style={buttonStyle}
        >
          Approve
        </Button>
        <Button
          variant={"contained"}
          color={"danger"}
          size={"small"}
          loading={denyInviteCodeLoading}
          onClick={onDeny}
          style={{ ...buttonStyle, marginTop: 5 }}
        >
          Deny
        </Button>
      </>
    );
  } else if (inviteStatus === "UNUSED") {
    actionButtons = (
      <Button
        variant={"contained"}
        color={"danger"}
        size={"small"}
        loading={deleteInviteCodeLoading}
        onClick={onDelete}
        style={buttonStyle}
      >
        Delete
      </Button>
    );
  }

  let invitedUser = null;

  if ((isUsed || inviteStatus === "PENDING") && user) {
    const { username, profilePhotos } = user;
    const avatar = profilePhotos && profilePhotos[0];

    invitedUser = (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Avatar src={avatar} size={"small"} />
        <Typography variant="body2" style={{ marginTop: 5 }}>
          @{username}
        </Typography>
      </Box>
    );
  }

  return (
    <TableRow className={classes.inviteTableRow}>
      <TableCell className={classes.inviteCodeCell}>
        <Box className={classes.inviteCode}>
          <Typography variant="body2">{inviteCode}</Typography>
          <CopyToClipboard text={inviteCode} onCopy={onCopy}>
            <Grid className={classes.copyContainer}>
              {copied ? (
                <CheckIcon className={classes.copyIcon} />
              ) : (
                <CopyIcon className={classes.copyIcon} />
              )}
            </Grid>
          </CopyToClipboard>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{inviteStatus.toLowerCase()}</Typography>
      </TableCell>
      <TableCell>{invitedUser}</TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column">
          {actionButtons}
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default InvitesTable;
