import React, { Component } from "react";
import styled from "styled-components";

import EmailSubmitForm from "./landing/EmailSubmitForm";
import Navbar from "./landing/Navbar";
import Section from "./landing/Section";
import WaveyBackground from "./landing/WaveyBackground";
import HeroHeader from "./landing/HeroHeader";
import HowItWorks from "./landing/HowItWorks";
import OurMission from "./landing/OurMission";
import EventCategories from "./landing/EventCategories";
import Footer from "./landing/Footer";
import ShareModal from "./landing/ShareModal";

import { colors } from "pages/Landing/landing/SharedStyles";

const PageWrapper = styled.div`
  overflow-x: hidden;
`;

class LandingPage extends Component {
  constructor() {
    super();
    this.state = {
      shareUrl: "",
      newReferralToken: "",
      showModal: false,
      navOpacity: 0,
    };
    this.rootRef = React.createRef();
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  onSubmitEmail = async (email, cb) => {
    try {
      const res = await fetch("https://www.getwaitlist.com/waitlist", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          api_key: process.env.REACT_APP_GETWAITLIST_KEY,
          referral_link: window.location.href,
        }),
      });

      if (res?.status !== 200) {
        throw new Error(`Network error.`);
      }

      const { referral_link } = await res.json();

      // eslint-disable-next-line
      window.matomoEventTracker?.push(["trackEvent", "user", "signup", "beta"]);

      this.setState({ shareUrl: referral_link, showModal: true });
    } catch (err) {
      // eslint-disable-next-line
      console.error(`Failed to signup for beta: ${err}`);
    } finally {
      cb();
    }
  };

  handleScroll = (event) => {
    if (this.state.showModal) {
      return;
    }
    this.setState({
      navOpacity: Math.min(this.rootRef.current.scrollTop / 1, 1),
    });
  };

  onEmailChanged = (e) => {
    const email = e.target.value;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = regex.test(email);
    this.setState({
      email,
      isValidEmail,
    });
  };

  renderEmailSubmitForm = () => (
    <EmailSubmitForm
      onSubmit={this.onSubmitEmail}
      email={this.state.email}
      showEmailInput={!this.state.newReferralToken}
      showModal={this.showModal}
      onEmailChanged={this.onEmailChanged}
      isValidEmail={this.state.isValidEmail}
    />
  );

  render() {
    return (
      <PageWrapper onScroll={this.handleScroll} ref={this.rootRef}>
        <Navbar
          navOpacity={this.state.navOpacity}
          links={[
            { label: "How It Works", anchor: "howitworks" },
            { label: "Event Categories", anchor: "eventcategories" },
            { label: "Our Mission", anchor: "ourmission" },
          ]}
        />
        <div
          style={{
            width: "100%",
          }}
        >
          <div className="section">
            <WaveyBackground
              background={`linear-gradient(to bottom, ${
                colors.mainBlue
              }, ${"#7fec7f"})`}
              topPadding="100px"
              waveFillTop="lightblue"
              waveFillBottom="white"
            >
              <Section>
                <HeroHeader>{this.renderEmailSubmitForm()}</HeroHeader>
              </Section>
            </WaveyBackground>
          </div>

          <div className="section" name="howitworks">
            <WaveyBackground
              waveFillTop="lightblue"
              waveFillBottom={colors.mainBlue}
            >
              <Section>
                <HowItWorks />
              </Section>
            </WaveyBackground>
          </div>

          <div className="section" id="eventcategories">
            <WaveyBackground
              background={colors.mainBlue}
              waveFillTop={colors.mainBlue}
              waveFillBottom={colors.mainBlue}
            >
              <Section backgroundColor={colors.mainBlue}>
                <EventCategories />
              </Section>
            </WaveyBackground>
          </div>

          <div className="section" id="our-mission" name="ourmission">
            <WaveyBackground
              waveFillTop="lightblue"
              waveFillBottom={colors.mainBlue}
            >
              <Section>
                <OurMission>{this.renderEmailSubmitForm()}</OurMission>
              </Section>
            </WaveyBackground>
          </div>

          <Footer />

          <ShareModal
            open={this.state.showModal}
            onClose={this.onCloseModal}
            shareUrl={this.state.shareUrl}
          />
        </div>
      </PageWrapper>
    );
  }
}

export default LandingPage;
