import React, { memo } from "react";
import PropTypes from "prop-types";
import { Button } from "mui-treasury/src/brands/peapods";

import SearchInput from "smart-components/SearchInput";

import SearchInputControl from "./SearchInputControl";

const Search = ({ isMobile, isVisible, showButton, toggleButton }) => {
  if (isVisible && showButton) {
    return (
      <Button
        onClick={toggleButton}
        size="big"
        color={"secondary"}
        icon="search"
        variant="contained"
        shape={"circular"}
        shadowless={false}
      />
    );
  }

  return !isVisible ? null : (
    <div
      style={{
        width: "100%",
        position: showButton ? "absolute" : "inherit",
        height: showButton ? 50 : "auto",
      }}
    >
      <SearchInput
        InputControlComponent={SearchInputControl}
        menuPlacement={isMobile ? "top" : "auto"}
        value={[]}
        includeEvents
        includeHashtags
        clearInputAfterEnter
        isRouting
        autoFocus
        onChange={toggleButton}
        onBlur={!isMobile && toggleButton}
      />
    </div>
  );
};

Search.propTypes = {
  toggleButton: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isVisible: PropTypes.bool,
  showButton: PropTypes.bool,
};

Search.defaultProps = {
  isMobile: false,
  isVisible: false,
  showButton: false,
};

export default memo(Search);
