import { images } from "mui-treasury/src/brands/peapods";

const {
  activism,
  artAndCulture,
  community,
  food,
  gaming,
  hobbies,
  learning,
  movies,
  music,
  networking,
  outdoor,
  spirituality,
  sport,
  volunteering,
} = images;

const defaultEventParams = {
  title: "",
  categories: [
    { image: activism, label: "Activism", tag: "activism", checked: false },
    {
      image: artAndCulture,
      label: "Art & Culture",
      tag: "artculture",
      checked: false,
    },
    {
      image: community,
      label: "Community",
      tag: "community",
      checked: false,
    },
    { image: food, label: "Food", tag: "food", checked: false },
    { image: gaming, label: "Gaming", tag: "gaming", checked: false },
    { image: hobbies, label: "Hobbies", tag: "hobbie", checked: false },
    { image: learning, label: "Learning", tag: "learning", checked: false },
    { image: movies, label: "Movies", tag: "movie", checked: false },
    { image: music, label: "Music", tag: "music", checked: false },
    {
      image: networking,
      label: "Networking",
      tag: "networking",
      checked: false,
    },
    { image: outdoor, label: "Outdoor", tag: "outdoor", checked: false },
    {
      image: spirituality,
      label: "Spirituality",
      tag: "spirituality",
      checked: false,
    },
    { image: sport, label: "Sport", tag: "sport", checked: false },
    {
      image: volunteering,
      label: "Volunteering",
      tag: "volunteering",
      checked: false,
    },
  ],
  description: "",
  hashtags: [],
  startTime: "",
  endTime: "",
  isPrivate: false,
  isVirtual: false,
  invitingGuests: "",
  price: 0,
  coverPicture: null,
  location: undefined,
  fee: {
    price: 0,
  },
  audience: undefined,
};

export default defaultEventParams;
