import React, { useState } from "react";
import { Modal } from "pure-components/Layout";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Box, Typography } from "@material-ui/core";
import { Button, LoadingSpinner } from "mui-treasury/src/brands/peapods";

import { useInviteHooks, useUserHooks } from "graphql/api";
import useClientApiHooks from "graphql/clientApi/hooks";

import InvitesTable from "./InvitesTable";
import RequestInvite from "./RequestInvite";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    minWidth: 560,
    height: 500,
    margin: "10px 0px",
    [breakpoints.down("xs")]: {
      minWidth: 300,
    },
  },
  cancelButton: {
    position: "absolute",
    bottom: 15,
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

function InviteForm() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(1);

  const { currentUser, getCurrentUserLoading } = useUserHooks();
  const { isInviteFormOpen, closeInviteForm } = useClientApiHooks();
  const { generateInviteCode, generateInviteCodeLoading } = useInviteHooks();

  const handleTabChange = (event, currentTab) => {
    setActiveTab(currentTab);
  };

  const onGenerateCode = () => {
    generateInviteCode();
  };

  const renderRequestInvite =
    currentUser?.invitedBy?.status === "DENIED" ?? true;

  return (
    <Modal
      open={isInviteFormOpen}
      render={(isMobile) => {
        return (
          <>
            <div className={classes.container}>
              <Tabs
                className={classes.tabs}
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Find Friends" />
                <Tab label="Invite Friends" />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                <Box
                  display="flex"
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                  height={300}
                >
                  <Typography variant="body2">Coming soon</Typography>
                </Box>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                {getCurrentUserLoading ? (
                  <Box
                    display="flex"
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                    height={400}
                  >
                    <LoadingSpinner />
                  </Box>
                ) : (
                  <>
                    {renderRequestInvite && <RequestInvite />}
                    <Box pt={1}>
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        size={"small"}
                        loading={generateInviteCodeLoading}
                        onClick={onGenerateCode}
                      >
                        Create code
                      </Button>
                    </Box>
                    <InvitesTable />
                  </>
                )}
              </TabPanel>
            </div>
            <Button className={classes.cancelButton} onClick={closeInviteForm}>
              Cancel
            </Button>
          </>
        );
      }}
    />
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

export default InviteForm;
