import { useMutation } from "@apollo/client";

import deleteFileMutation from "./deleteFile.graphql";

const useDeleteFiles = (folderName) => {
  const [deleteFile] = useMutation(deleteFileMutation);

  const deleteFilesWrapper = async (urls) => {
    const publicIds = urls.map((url) => {
      const path = `${process.env.REACT_APP_SENTRY_ENVIRONMENT}/${folderName}/`;
      const fileName = url.split("/" + path)[1].split(".jpg")[0];
      return `${path}${fileName}`;
    });

    const promises = publicIds.map(async (publicId) => {
      await deleteFile({
        variables: {
          input: {
            publicId,
          },
        },
      });
    });

    return Promise.all(promises);
  };

  return deleteFilesWrapper;
};

export default useDeleteFiles;
