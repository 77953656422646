import React, { memo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Avatar,
  Typography,
  theme,
  makeStyles,
} from "mui-treasury/src/brands/peapods";

const useStyles = makeStyles(({ isFocused }) => ({
  avatar: {
    width: 56,
    height: 56,
  },
  grid: {
    width: "100%",
    backgroundColor: (props) =>
      props.isFocused ? theme.palette.grey[100] : "white",
    color: (props) => (props.isFocused ? theme.palette.secondary.main : "auto"),
    margin: 0,
    borderRadius: 4,
    transition: "background-color 0.1s linear",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[100],
      "& .MuiTypography-body2": {
        transition: "color 0.1s linear",
        color: theme.palette.secondary.main,
      },
    },
  },
}));

const SearchInputOption = ({
  innerRef,
  innerProps,
  isFocused,
  data,
  getValue,
  setValue,
  history,
  location: { pathname },
}) => {
  const classes = useStyles({ isFocused });
  if (data.__isNew__) {
    return null;
  }
  const { id, name, title, profilePhoto, profilePhotos } = data.node;
  const avatarSrc = profilePhoto || (profilePhotos && profilePhotos[0]);

  const onItemClick = () => {
    setValue([...getValue(), data]);

    const { node, isRouting } = data;

    if (isRouting) {
      const { __typename } = node;
      let newRoute = "";
      if (__typename === "User") {
        newRoute = `/@${node.username}`;
      } else if (__typename === "Event") {
        newRoute = `/event/${node.id}`;
      } else if (__typename === "Group") {
        newRoute = `/group/${node.id}`;
      } else if (__typename === "Hashtag") {
        newRoute = `/discover/pods?eventHashtag=${node.name}`;
      }

      if (pathname !== newRoute) {
        history.push(newRoute);
      }
    }
  };

  const label = title || name;

  return (
    <Grid
      data-test-id={"search-input-option"}
      key={id}
      ref={innerRef}
      container
      selected={isFocused}
      {...innerProps}
    >
      <Grid
        container
        alignItems="center"
        className={classes.grid}
        spacing={4}
        onClick={onItemClick}
      >
        <Grid item xs={3}>
          <Avatar src={avatarSrc}>{name && name[0].toUpperCase()}</Avatar>
        </Grid>

        <Grid item xs={9}>
          <div>
            <Typography variant="body2">{label}</Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

SearchInputOption.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

SearchInputOption.defaultProps = {
  children: null,
  innerProps: null,
  innerRef: () => {},
  isFocused: false,
  isSelected: false,
};

export default memo(withRouter(SearchInputOption));
