import React, { useState, useCallback, memo } from "react";
import PropTypes from "prop-types";
import {
  FormLabel,
  MyLocationIcon,
  AutocompleteList,
  Button,
  Grid,
  Box,
  theme,
} from "mui-treasury/src/brands/peapods";
import { useSnackbar } from "notistack";

import LocationSnackbar from "./LocationSnackbar";

const deniedMessages = [
  "User denied Geolocation",
  "User denied geolocation prompt",
];

const LocationInput = ({
  isRequired,
  value,
  onChange,
  onClick,
  removeSpacing,
  buttonText,
  isLoading,
  showLabel,
  cityOnly,
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let geocoder;
  let autocomplete;
  let OK;
  if (window.google) {
    // TODO: refactor this to not rely on dangerous global vars
    geocoder = new window.google.maps.Geocoder();
    autocomplete = new window.google.maps.places.AutocompleteService();
    OK = window.google.maps.GeocoderStatus.OK;
  }

  const unblockUrlMap = {
    Chrome: "chrome://settings/content/location",
    Firefox: "about:preferences#privacy",
    Safari:
      "https://support.apple.com/guide/safari/customize-settings-per-website-ibrw7f78f7fe",
  };

  const unblockUrl = unblockUrlMap[theme.browserInfo.browser.name];

  const handleError = useCallback(
    (error) => {
      setLoading(false);

      if (deniedMessages.includes(error.message)) {
        const message = `Please unblock us in your browser settings.`;
        return enqueueSnackbar("", {
          persist: true,
          content: (key) => (
            <LocationSnackbar
              key={key}
              message={message}
              url={unblockUrl}
              closeSnackbar={closeSnackbar}
            />
          ),
        });
      }

      // eslint-disable-next-line
      console.error(error);
      enqueueSnackbar(error.message, {
        variant: "error",
        persist: true,
      });
    },
    [enqueueSnackbar, unblockUrl, closeSnackbar],
  );

  const geoCode = async (params) => {
    return new Promise((resolve) => {
      geocoder.geocode(params, async (results, status) => {
        if (status === OK) {
          if (cityOnly) {
            const [city] = results.filter((res) =>
              res.types.includes("locality"),
            );
            return resolve([city]);
          } else {
            return resolve(results);
          }
        }
        resolve(
          handleError(
            new Error(`Failed to geocode: ${JSON.stringify(params)}`),
          ),
        );
      });
    });
  };

  const getSuggestions = useCallback(
    async (input) => {
      if (value === input) {
        return Promise.resolve([]);
      }

      setLoading(true);

      const request = new Promise((resolve) => {
        autocomplete.getPlacePredictions(
          {
            input,
          },
          (responses, status) => {
            setLoading(false);

            if (status === OK) {
              return resolve(
                responses.map((r) => ({ ...r, label: r.description })),
              );
            }

            resolve(
              handleError(
                new Error(`Failed to query google places api: ${input}`),
              ),
            );
          },
        );
      });

      // TODO: we should cancel stale requests
      return request;
    },
    [value, handleError, OK, autocomplete],
  );

  const onCurrentPositionSuccess = async ({ coords }) => {
    const location = {
      lat: coords.latitude,
      lng: coords.longitude,
    };
    const [res] = await geoCode({ location });
    setLoading(false);
    onChange(res);
  };

  const currentPositionOptions = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 0,
  };

  const setMyLocation = () => {
    setLoading(true);
    window.navigator.geolocation.getCurrentPosition(
      onCurrentPositionSuccess,
      handleError,
      currentPositionOptions,
    );
    if (onClick) {
      onClick();
    }
  };

  const geoCodeResult = async (location) => {
    if (!location) {
      return onChange(location);
    }

    const [res] = await geoCode({ address: location.description });
    onChange(res);
  };

  return (
    <Grid container alignItems="center" direction="column">
      {showLabel && (
        <Grid item style={{ width: "100%" }}>
          <FormLabel required={isRequired}>Location</FormLabel>
        </Grid>
      )}

      <Grid container spacing={2} style={{ margin: 0 }}>
        {window.navigator.geolocation && (
          <Grid item style={{ paddingBottom: 0 }}>
            <Button
              loading={loading || isLoading}
              size="small"
              variant={"contained"}
              color="primary"
              shape={"circular"}
              icon={<MyLocationIcon />}
              onClick={setMyLocation}
              title={buttonText}
            />
          </Grid>
        )}

        <Box flexGrow={1} ml={1} alignSelf="flex-end">
          <AutocompleteList
            fullWidth
            autoFocus
            isClearable
            isLoading={loading}
            placeholder={value || "Please enter address here"}
            value={[]}
            getSuggestions={getSuggestions}
            onChange={geoCodeResult}
            canCreate={false}
            removeSpacing={removeSpacing}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

LocationInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.string,
  removeSpacing: PropTypes.bool,
  buttonText: PropTypes.string,
  isLoading: PropTypes.bool,
  showLabel: PropTypes.bool,
  // be careful with this prop, respect user privacy
  cityOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
};

LocationInput.defaultProps = {
  onClick: undefined,
  value: undefined,
  removeSpacing: false,
  buttonText: "Use my location",
  isLoading: false,
  showLabel: true,
  cityOnly: true,
  isRequired: false,
};

export default memo(LocationInput);
