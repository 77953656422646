import styled from "styled-components";

const Container = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: ${(props) => (props.grow ? "1" : "0")};

  @media (max-width: 1200px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const TopRow = styled(Row)`
  justify-self: flex-start;
`;

const BottomRow = styled(Row)`
  margin: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
  width: 350px;
  height: 350px;
`;

const HeaderText = styled.h1`
  font-size: 30px;
  margin: auto;
  margin-bottom: 60px;
`;

const SubHeaderText = styled.h2`
  font-size: 18px;
  color: #5b618e;
  margin: 0;
`;

const LogoContainer = styled.div`
  display: flex;

  > img {
    height: 30px;
  }
`;

const Step = styled.img`
  width: 60px;
  padding: 1px;
`;

const StepImage = styled.img`
  width: 100px;
`;

export {
  Step,
  StepImage,
  Container,
  Row,
  TopRow,
  BottomRow,
  Column,
  HeaderText,
  SubHeaderText,
  LogoContainer,
};
