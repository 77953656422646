import styled from "styled-components";

const Container = styled.div`
  min-height: 500px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1200px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const TopRow = styled(Row)`
  justify-self: flex-start;
`;

const BottomRow = styled(Row)`
  margin: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const HeaderText = styled.h1`
  font-size: 30px;
  color: white;
  margin-bottom: 60px;
`;

const SubHeaderText = styled.h2`
  font-size: 18px;
  color: #5b618e;
  margin: 0;
`;

const LogoContainer = styled.div`
  display: flex;

  > img {
    height: 30px;
  }
`;

const CardGrid = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

const Card = styled.div`
  width: 180px;
  margin: 16px;
  padding: 8px;
  border-radius: 8px;
  background: ${(props) => (props.color ? props.color : "yellow")};

  @media (max-width: 1160px) {
    width: 200px;
  }

  @media (max-width: 945px) {
    width: 250px;
  }

  @media (max-width: 754px) {
    width: 200px;
    margin: 8px;
  }

  @media (max-width: 600px) {
    width: 260px;
    margin: 8px;
  }
`;

const CardTitle = styled.h2`
  color: white;
  padding-bottom: 4px;
`;

export {
  Container,
  Row,
  TopRow,
  BottomRow,
  Column,
  HeaderText,
  SubHeaderText,
  LogoContainer,
  CardGrid,
  Card,
  CardTitle,
};
