import React from "react";
import { ApolloProvider } from "@apollo/client";
import { useSnackbar } from "notistack";

import createClient from "graphql/apolloClient";

const Provider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const renderSnackbar = (message) => {
    // eslint-disable-next-line
    console.error(message);
    enqueueSnackbar(message, {
      variant: "error",
      persist: true,
    });
  };

  const handleError = ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        renderSnackbar(message);
      });
    }

    if (networkError) {
      renderSnackbar(networkError.message);
    }
  };

  const client = createClient({ handleError });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
