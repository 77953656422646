import { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { uniqBy, cloneDeep } from "lodash";
import { useHistory } from "react-router-dom";

// TODO: export raw named queries
import { queries, mutations } from "graphql/clientApi";
import {
  createEvent as createEventMutation,
  updateEvent as updateEventMutation,
  deleteEvent as deleteEventMutation,
  getCurrentUser,
  getUserById,
  searchEventsQuery,
  getEventById,
} from "graphql/api";

import usePodGraphql from "../PodForm/PodFormHooks";

import defaultEventParams from "./defaultEventParams";

const useEventGraphql = () => {
  const history = useHistory();

  const { data } = useQuery(queries.GET_SHOW_EVENT_FORM);
  const modalOpen = data && data.showEventForm;

  const { data: data2 } = useQuery(queries.GET_SHOW_EVENT_EDIT_FORM);
  const editModalOpen = data2 && data2.showEventEditForm;

  const { data: data3 } = useQuery(queries.GET_SELECTED_EVENTS_QUERY);
  const selectedEvents = data3 && data3.selectedEvents;

  const [closeEventForm] = useMutation(mutations.SET_SHOW_EVENT_FORM, {
    variables: { showEventForm: false },
  });

  const [openEventForm] = useMutation(mutations.SET_SHOW_EVENT_FORM, {
    variables: { showEventForm: true },
  });

  const [closeEventEditForm] = useMutation(mutations.SET_SHOW_EVENT_EDIT_FORM, {
    variables: { showEventEditForm: false },
  });

  const [selectEvents] = useMutation(mutations.SELECT_EVENTS);

  const {
    podGraphQl: { createPod },
  } = usePodGraphql();

  const [eventId, setEventId] = useState();
  const [eventParams, setEventParams] = useState(defaultEventParams);
  const [myEventParams, setMyEventParams] = useState(defaultEventParams);

  const {
    title,
    location,
    hashtags,
    coverPicture,
    description,
    audience,
    categories,
    startTime,
    endTime,
    isPrivate,
    isVirtual,
    fee,
  } = eventParams;

  const categoryTags = categories.filter((x) => x.checked).map((x) => x.tag);

  const onClose = useCallback(() => {
    closeEventForm();
    closeEventEditForm();
  }, [closeEventForm, closeEventEditForm]);

  const onOpen = useCallback(() => {
    openEventForm();
  }, [openEventForm]);

  useEffect(() => {
    if (modalOpen) {
      setEventParams(cloneDeep(defaultEventParams));
    }
  }, [modalOpen]);

  useEffect(() => {
    const event = selectedEvents && selectedEvents[0];
    if (event) {
      setEventId(event.id);
      const params = cloneDeep(defaultEventParams);

      if (event.taggedConnection) {
        // check categories and set extra as hashtags
        const tags = event.taggedConnection.edges.map((edge) => edge.node.name);
        params.categories.forEach((category) => {
          const tagIndex = tags.findIndex((t) => t === category.tag);
          if (tagIndex !== -1) {
            category.checked = true;
            tags.splice(tagIndex, 1);
          }
        });
        params.hashtags = tags;
      }
      params.id = event.id;
      params.title = event.title;
      params.description = event.description;
      params.startTime = event.startTime;
      params.endTime = event.endTime;
      params.isPrivate = event.isPrivate;
      params.isVirtual = event.isVirtual;
      if (event.location) {
        const { __typename, ...location } = event.location;
        params.location = location;
      }
      params.coverPicture = event.coverPicture;
      if (event.fee) {
        const { __typename, ...fee } = event.fee;
        params.fee = fee;
      }
      if (event.audience) {
        const { __typename, ...audience } = event.audience;
        params.audience = audience;
      }
      setMyEventParams(params);
    }
  }, [selectedEvents]);

  useEffect(() => {
    if (editModalOpen) {
      setEventParams(myEventParams);
    }
  }, [editModalOpen, myEventParams]);

  const input = {
    title,
    description,
    hashtags: uniqBy([...categoryTags, ...hashtags]),
    location,
    coverPicture,
    isPrivate,
    isVirtual,
    startTime: startTime || undefined,
    endTime: endTime || undefined,
    audience:
      audience &&
      eventId &&
      Object.values(audience).every((el) => el === undefined)
        ? { reset: true }
        : audience,
  };
  input.fee = Object.assign({}, fee);
  input.fee.price = parseFloat(input.fee.price);

  const variables = { input };

  const refetchQueries = [
    searchEventsQuery.definitions[0].name.value,
    getCurrentUser.definitions[0].name.value,
    {
      query: getEventById,
      variables: {
        input: {
          id: eventId,
        },
        skip: !eventId,
      },
    },
  ];

  const [createEvent, { data: createEventData, loading: createEventLoading }] =
    useMutation(createEventMutation);

  const [updateEvent, { data: updateEventData, loading: updateEventLoading }] =
    useMutation(updateEventMutation, {
      variables: {
        input: {
          ...input,
          id: eventId,
        },
      },
      refetchQueries,
      skip: !eventId,
    });

  const [deleteEvent, { data: deleteEventData, loading: deleteEventLoading }] =
    useMutation(deleteEventMutation, {
      variables: {
        input: {
          id: eventId,
        },
      },
      skip: !eventId,
      refetchQueries: [
        searchEventsQuery.definitions[0].name.value,
        getCurrentUser.definitions[0].name.value,
      ],
      update: (cache, data) => {
        cache.evict(`Event:${eventId}`);
        cache.gc();
        selectEvents({ variables: { events: [] } });

        if (window.location.pathname?.includes(eventId)) {
          history.push("/");
        }
      },
    });

  useEffect(() => {
    if (createEventData || updateEventData || deleteEventData) {
      onClose();
    }
  }, [onClose, createEventData, updateEventData, deleteEventData]);

  const createEventWrapper = async (podParams) => {
    const options = {
      variables,
      refetchQueries: [
        getCurrentUser.definitions[0].name.value,
        getUserById.definitions[0].name.value,
      ],
    };

    if (podParams) {
      options.update = (cache, { data }) => {
        if (data && data.createEvent.event) {
          createPod({
            eventId: data.createEvent.event.id,
            ...podParams,
          });
        }
      };
    }

    await createEvent(options);
  };

  return {
    eventParams,
    setEventParams,
    modalOpen,
    editModalOpen,
    onClose,
    onOpen,
    eventGraphQl: {
      createEvent: createEventWrapper,
      createEventData,
      createEventLoading,
      updateEvent,
      updateEventData,
      updateEventLoading,
      deleteEvent,
      deleteEventData,
      deleteEventLoading,
    },
  };
};

export default useEventGraphql;
