import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import getPodByIdQuery from "../../queries/pod/getPodById.graphql";

const useGetPodById = (id) => {
  const [pod, setPod] = useState();

  const { data: getPodByIdData, loading: getPodByIdLoading } = useQuery(
    getPodByIdQuery,
    {
      variables: {
        input: {
          id,
        },
      },
      skip: !id,
    },
  );

  useEffect(() => {
    if (getPodByIdData) {
      const { pod } = getPodByIdData.getPodById;

      setPod(pod);
    }
  }, [getPodByIdData]);

  return {
    pod,
    getPodByIdLoading,
  };
};

export default useGetPodById;
