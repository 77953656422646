import { useMutation } from "@apollo/client";

import { generateInviteCode as generateInviteCodeMutation } from "graphql/api";

const useGenerateInviteCode = () => {
  const [generateInviteCode, { loading }] = useMutation(
    generateInviteCodeMutation,
    {
      refetchQueries: ["getInviteCodes"],
    },
  );

  return {
    generateInviteCode,
    generateInviteCodeLoading: loading,
  };
};

export default useGenerateInviteCode;
