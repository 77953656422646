import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";

import { useCurrentUser } from "graphql/api";

import EventPhotoUploader from "./EventPhotoUploader";
import EventDialog from "./EventDialog";
import useEventFormHooks from "./EventFormHooks";

const EventForm = ({ history }) => {
  const currentUser = useCurrentUser();
  const lastLocation = useLastLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [showPhotoUploader, setShowPhotoUploader] = useState(false);
  const [shouldHandleDelete, setShouldHandleDelete] = useState(true);

  const {
    eventParams,
    setEventParams,
    modalOpen,
    editModalOpen,
    onClose,
    eventGraphQl,
  } = useEventFormHooks();

  const {
    createEvent,
    createEventLoading,
    updateEvent,
    updateEventLoading,
    deleteEvent,
    deleteEventLoading,
    deleteEventData,
  } = eventGraphQl;

  useEffect(() => {
    setIsLoading(createEventLoading || updateEventLoading);
  }, [createEventLoading, updateEventLoading]);

  useEffect(() => {
    setShouldHandleDelete(true);
  }, [deleteEventData]);

  useEffect(() => {
    if (
      !deleteEventLoading &&
      deleteEventData &&
      shouldHandleDelete &&
      history.location &&
      lastLocation &&
      history.location.pathame?.split("/")[1] === "event"
    ) {
      if (lastLocation.pathname !== history.location.pathname) {
        history.goBack();
      } else {
        history.replace("/");
      }
      setShouldHandleDelete(false);
    }
  }, [
    deleteEventData,
    deleteEventLoading,
    lastLocation,
    history,
    shouldHandleDelete,
  ]);

  function onSubmit(isDelete, podParams) {
    if (isDelete) {
      deleteEvent();
    } else if (editModalOpen) {
      updateEvent();
    } else {
      createEvent(podParams);
    }
  }

  const onEventParamsChanged = (...args) => {
    setEventParams(...args);
  };

  function onPhotoUploaded({ secure_url: coverPicture }) {
    onEventParamsChanged({
      ...eventParams,
      coverPicture,
    });
    setShowPhotoUploader(false);
  }

  function onPhotoUploaderClosed() {
    setShowPhotoUploader(false);
  }

  return (
    <>
      <EventDialog
        open={modalOpen || editModalOpen}
        onClose={onClose}
        title={editModalOpen ? "Edit Event" : "Create Event"}
        type={editModalOpen ? "save" : "create"}
        eventParams={eventParams}
        setEventParams={onEventParamsChanged}
        setShowPhotoUploader={setShowPhotoUploader}
        isLoading={isLoading}
        isDeleting={deleteEventLoading}
        onSubmit={onSubmit}
      />

      <EventPhotoUploader
        isOpen={showPhotoUploader}
        onClose={onPhotoUploaderClosed}
        onFileUploaded={onPhotoUploaded}
        ownerId={currentUser && currentUser.id}
      />
    </>
  );
};

export default withRouter(EventForm);
