import React, { Component } from "react";
import Scroll from "react-scroll";

import "./navbar/Navbar.css";
import logo from "images/peapods-logo.svg";
import { Background, Container, Logo, SimpleWrapper } from "./navbar/styles";

const createLink = (link, idx) => (
  <Scroll.Link
    key={idx}
    activeClass="navbar-active-link"
    to={link.anchor}
    smooth={true}
    offset={-100}
    duration={500}
  >
    {link.label}
  </Scroll.Link>
);

class Navbar extends Component {
  render() {
    return (
      <Background
        isTransparent={this.props.navOpacity < 1}
        style={{
          backgroundColor: `rgba(255, 255, 255, ${this.props.navOpacity})`,
        }}
      >
        <Container>
          <SimpleWrapper>
            <Logo src={logo} alt="peapods-logo" />
          </SimpleWrapper>
          <SimpleWrapper isTransparent={this.props.isTransparent}>
            {this.props.links.map(createLink)}
          </SimpleWrapper>
        </Container>
      </Background>
    );
  }
}

export default Navbar;
