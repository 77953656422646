import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField } from "@material-ui/core";
import { useApolloClient } from "@apollo/client";
import { Button } from "mui-treasury/src/brands/peapods";

import { useUserHooks } from "graphql/api";

import { validateInviteCode, updateInviteCode } from "../../utils/invite";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    paddingRight: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  inviteCode: {
    width: "80%",
  },
}));

function RequestInvite() {
  const classes = useStyles();

  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [errors, setErrors] = useState({});

  const { refetchCurrentUser } = useUserHooks();

  const onRequest = async () => {
    const validInviteCode = !getErrorStringInviteCode(inviteCode);

    if (!validInviteCode) {
      enqueueSnackbar("Invite code is required", {
        variant: "error",
        persist: true,
      });
      return;
    }

    setLoading(true);
    const isValid = await validateInviteCode(client, inviteCode);

    if (!isValid) {
      enqueueSnackbar("Invite code provided is not valid", {
        variant: "error",
        persist: true,
      });
      setLoading(false);
      return;
    }

    const success = await updateInviteCode(client, inviteCode);
    setLoading(false);

    if (success) {
      enqueueSnackbar("Invite request sent successfully", {
        variant: "success",
        persist: true,
      });
      setInviteCode("");
      refetchCurrentUser();
    }
  };

  const validate = (fieldname) => {
    const newErrors = { ...errors };

    if (fieldname === "inviteCode") {
      newErrors.inviteCode = getErrorStringInviteCode(inviteCode);
    }

    setErrors(newErrors);
  };

  function getErrorStringInviteCode(inviteCode) {
    if (!inviteCode || inviteCode === "") {
      return "Required";
    }
  }

  return (
    <Box className={classes.container} my={1}>
      <TextField
        required
        className={classes.inviteCode}
        value={inviteCode}
        error={!!errors.inviteCode}
        label={"Invite Code"}
        name="inviteCode"
        type="text"
        onBlur={() => validate("inviteCode")}
        fullWidth
        helperText={errors.inviteCode}
        FormHelperTextProps={{ error: true }}
        onChange={({ target }) => setInviteCode(target.value)}
      />
      <Button
        variant={"contained"}
        color={"primary"}
        size={"small"}
        loading={loading}
        onClick={onRequest}
      >
        Request
      </Button>
    </Box>
  );
}

export default RequestInvite;
