import styled from "styled-components";

const Container = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1200px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const TopRow = styled(Row)`
  justify-self: flex-start;
`;

const BottomRow = styled(Row)`
  margin: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const HeaderText = styled.h1`
  font-size: 30px;
  margin-bottom: 60px;
`;

const SubHeaderText = styled.h2`
  font-size: 20px;
  color: #5b618e;
  margin: 0;
  margin-bottom: 40px;
  line-height: 1.5;
`;

const SubHeaderTextTitle = styled(SubHeaderText)`
  font-weight: bold;
  color: #18a918;
`;

const MissionTextContainer = styled.div`
  max-width: 600px;

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

export {
  MissionTextContainer,
  Container,
  Row,
  TopRow,
  BottomRow,
  Column,
  HeaderText,
  SubHeaderTextTitle,
  SubHeaderText,
};
