import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import searchAllQuery from "./searchAll.graphql";

// TODO: here is where typescript types would be super usefuld
const useSearchAll = () => {
  const [input, setInput] = useState({});
  const [onCompleted, setOnCompleted] = useState();
  const [onError, setOnError] = useState();

  const [
    executeSearchAll,
    { loading: searchAllLoading, error: searchAllError, data: searchAllData },
  ] = useLazyQuery(searchAllQuery, {
    variables: {
      input,
    },
    onCompleted,
    onError,
    context: {
      debounceKey: "searchAll",
    },
  });

  const searchAllWrapper = async (searchAllInput) => {
    await setInput({});

    // TODO: handle errors
    return new Promise(async (resolve, reject) => {
      await setOnCompleted(
        () =>
          ({ searchAll }) =>
            resolve(searchAll),
      );
      await setOnError(() => (error) => reject(error));
      await setInput(searchAllInput);
    });
  };

  useEffect(() => {
    if (!input.searchString) {
      return;
    }
    executeSearchAll();
  }, [input, executeSearchAll]);

  return {
    searchAll: searchAllWrapper,
    searchAllLoading,
    searchAllError,
    searchAllData,
  };
};

export default useSearchAll;
