import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  height: 100%;

  @media (max-width: 680px) {
    padding-top: 10px;
  }
`;

const TopRow = styled(Row)`
  justify-self: flex-start;
  height: 500px;
  width: 1200px;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    height: 575px;
    width: 100%;
  }

  @media (max-width: 450px) {
    height: 500px;
  }
`;

const BottomRow = styled(Row)`
  padding-bottom: 50px;
  z-index: 100;
`;

const Title = styled.h1`
  font-size: 48px;
  display: block;
`;
const Subtitle = styled.h2`
  font-size: 30px;
  display: block;
`;

const HeroTextContainer = styled.div`
  max-width: 100vw;
  color: white;
  text-align: center;
  width: 540px;
  height: 230px;
  margin-bottom: 15px;

  > div {
    width: 100%;
  }

  @media (max-width: 1200px) {
    height: 180px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  @media (max-width: 680px) {
    > div {
      * h1 {
        font-size: 40px;
      }

      * h2 {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 570px) {
    width: 380px;

    > div {
      * h1 {
        font-size: 34px;
      }

      * h2 {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 400px) {
    > div {
      * h1 {
        font-size: 28px;
      }

      * h2 {
        font-size: 17px;
      }
    }
  }
`;

const TextWrapper = styled.div`
  @media (max-width: 1200px) {
    width: 570px;
  }
`;

// TODO: https://github.com/airbnb/lottie-web/issues/1016
const HeroAnimationContainer = styled.div`
  position: relative;
  width: 500px;
  height: 500px;

  > canvas {
    width: 180% !important;
    height: auto !important;
    left: -163px;
    transform: translateY(-19%) !important;
    position: absolute;
  }

  > svg {
    transform: scale(1.8) translateY(15px) translateX(20px) !important;
  }

  @media (max-width: 1200px) {
    > canvas {
      width: 147% !important;
      left: -106px;
      transform: translateY(-23%) !important;
    }

    > svg {
      transform: scale(1.4) translateY(-30px) translateX(5px) !important;
    }
  }

  @media (max-width: 450px) {
    width: 400px;
    height: 400px;

    > canvas {
      width: 147% !important;
      left: -93px;
      transform: translateY(-23%) !important;
    }
  }
`;

const HeroIllustration = styled.img`
  height: 250px;
`;

const Container = styled.div`
  max-width: 100vw;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export {
  Container,
  Row,
  Column,
  TopRow,
  BottomRow,
  Title,
  Subtitle,
  HeroTextContainer,
  HeroIllustration,
  HeroAnimationContainer,
  TextWrapper,
};
