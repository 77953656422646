import React from "react";
import Favico from "favico.js";

import PodForm from "smart-components/PodForm";
import PodTimeRangesForm from "smart-components/PodTimeRangesForm";
import EventForm from "smart-components/EventForm";
import GroupForm from "smart-components/GroupForm";
import CookiePopup from "smart-components/CookiePopup";
import AddToCalendar from "smart-components/AddToCalendar";

import Routes from "./Routes.jsx";
import Providers from "./Providers";

const faviconBadge = new Favico({
  animation: "pop",
});

window.peapodsFaviconBadge = faviconBadge;

// TODO: reset store on logout
// https://www.apollographql.com/docs/react/recipes/authentication/
const App = () => {
  return (
    <React.StrictMode>
      <Providers>
        <Routes>
          <PodForm />
          <PodTimeRangesForm />
          <EventForm />
          <GroupForm />
          <AddToCalendar />
        </Routes>

        <CookiePopup />
      </Providers>
    </React.StrictMode>
  );
};

export default App;
