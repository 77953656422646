import styled from "styled-components";

const Section = styled.div`
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 15%;
  margin-right: 15%;

  @media (max-width: 1400px) {
    margin-left: 10%;
    margin-right: 10%;
  }

  @media (max-width: 500px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

export default Section;
