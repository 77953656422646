import React, { Component } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import Modal from "react-responsive-modal";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";

import MegaPhonePea from "images/megaphone-pea.svg";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  > div {
    margin: 8px;
    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }
`;

const CopyToClipboardBtn = styled.button`
  width: 190px;
  color: ${(props) => (props.isCopied ? "white" : "white")};
  padding: 8px;
  border-radius: 50px;
  transition: all 0.2s;
  font-size: 14px;
  outline: none;
  border: none;
  justify-content: center;
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.isCopied ? "grey" : "linear-gradient(to bottom,#a900ff,#fb00ff)"};

  &:hover {
    cursor: pointer;
    filter: ${(props) =>
      props.isCopied ? "brightness(1)" : "brightness(1.2)"};
  }

  > * {
    padding: 4px;
  }
`;

const GoldText = styled.p`
  display: inline;
  color: darkgoldenrod;
`;

const MegaphonePea = styled.img`
  padding: 25px;
`;

class ShareModal extends Component {
  state = {
    value: "",
    copied: false,
  };

  onShare(media) {
    return () => {
      // eslint-disable-next-line
      window.matomoEventTracker?.push([
        "trackEvent",
        "user",
        "share-social",
        media,
      ]);
    };
  }

  onClose = () => {
    this.setState({ copied: false });
    this.props.onClose();
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.onClose}
        center
        styles={{
          overlay: {
            background: "rgba(0, 0, 0, 0.75)",
          },
          modal: {
            textAlign: "center",
            padding: "10px",
            borderRadius: "8px",
            zIndex: "10000",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "400px",
          },
        }}
      >
        <h1 style={{ color: "green", fontSize: "30px" }}>Thanks!</h1>
        <h2>Can you help spread the word?</h2>
        <MegaphonePea src={MegaPhonePea} alt="spread-the-word" width="125px" />
        <h3>
          Recruit <GoldText>2</GoldText> friends and get <GoldText>2</GoldText>{" "}
          free months of <GoldText>premium</GoldText> features when we launch!
        </h3>

        <Column>
          <a href={this.props.shareUrl} _target="blank">
            {this.props.shareUrl}
          </a>

          <CopyToClipboard
            text={this.props.shareUrl}
            onCopy={() => this.setState({ copied: true })}
          >
            <CopyToClipboardBtn isCopied={this.state.copied}>
              {this.state.copied ? null : <FaCopy />}
              {this.state.copied ? "Copied!" : "Copy to Clipboard"}
            </CopyToClipboardBtn>
          </CopyToClipboard>

          <Row>
            <FacebookShareButton
              quote="A new social network based on events, check it out!"
              url={this.props.shareUrl}
              onClick={this.onShare("facebook")}
            >
              <FacebookIcon size={45} round={true} />
            </FacebookShareButton>

            <TwitterShareButton
              url={this.props.shareUrl}
              title="A new social network based on events, check it out!"
              onClick={this.onShare("twitter")}
            >
              <TwitterIcon size={45} round={true} />
            </TwitterShareButton>

            {/* broken? */}
            {/* <EmailShareButton
              subject="Check out this cool new site I found!"
              body={`It's a new social network based on events: ${this.props.shareUrl}`}
              url={this.props.shareUrl}
              onClick={this.onShare('email')}
            >
              <EmailIcon size={45} round={true} />
            </EmailShareButton> */}
          </Row>
        </Column>
      </Modal>
    );
  }
}

export default ShareModal;
