import React, { useState, useEffect, useCallback, memo } from "react";
import { GroupDialog, AutocompleteList } from "mui-treasury/src/brands/peapods";
import { uniqBy, cloneDeep, difference } from "lodash";

import RadioGrid from "pure-components/RadioGrid";

import {
  useSearchAll,
  useGroupHooks,
  useCurrentUser,
  useDeleteFiles,
} from "graphql/api";
import useClientApiHooks from "graphql/clientApi/hooks";

import usePreventLeave from "utils/preventLeave";

import CoverPhotoUploader from "./CoverPhotoUploader";

const defaultGroupParams = {
  id: undefined,
  name: "",
  description: undefined,
  type: "PERSONAL",
  profilePhoto: undefined,
  hashtags: undefined,
};

// TODO: refactor this to be more like EventForm and consolidate logic

const GroupForm = () => {
  const currentUser = useCurrentUser();

  const { isDirty, setIsDirty, promptForConfirmation } = usePreventLeave();

  const [isValid, setIsValid] = useState(false);

  const deleteFiles = useDeleteFiles("group-cover-photos");

  const { selectedGroups, isGroupFormOpen, closeGroupForm } =
    useClientApiHooks();

  const {
    useGetGroupById,
    createGroup,
    createGroupLoading,
    updateGroup,
    updateGroupLoading,
  } = useGroupHooks();

  const { group, getGroupByIdLoading } = useGetGroupById(
    selectedGroups[0] && selectedGroups[0].id,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [showCoverPhotoUploader, setShowCoverPhotoUploader] = useState(false);
  const [groupParams, setGroupParams] = useState({ ...defaultGroupParams });
  const [keywords, setKeywords] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);

  const isEditMode = !!groupParams.id;

  const onKeywordsChanged = (keywords) => {
    onGroupParamsChanged({
      ...groupParams,
      hashtags: uniqBy(keywords, "value").map((tag) => tag.value),
    });
  };

  useEffect(() => {
    if (groupParams.hashtags) {
      setKeywords(
        groupParams.hashtags.map((hashtag) => ({
          label: hashtag,
          value: hashtag,
        })),
      );
    }
  }, [groupParams.hashtags]);

  useEffect(() => {
    setIsLoading(
      createGroupLoading || updateGroupLoading || getGroupByIdLoading,
    );
  }, [createGroupLoading, updateGroupLoading, getGroupByIdLoading]);

  useEffect(() => {
    if (!group) {
      return;
    }

    const params = cloneDeep(defaultGroupParams);

    params.id = group.id;
    params.name = group.name;
    params.type = group.type;
    params.description = group.description;
    // don't use client placeholders
    params.profilePhoto =
      group.profilePhoto.indexOf("/static/media/") === 0
        ? undefined
        : group.profilePhoto;

    if (group.taggedConnection) {
      const tags = group.taggedConnection.edges.map((edge) => edge.node.name);
      params.hashtags = tags;
    }

    if (group.invitedConnection) {
      const values = group.invitedConnection.edges.map(({ node }) => ({
        value: node.id,
        label: node.name || node.username,
        node,
      }));
      setInvitedUsers(values);
    }

    setGroupParams(params);
  }, [selectedGroups, group]);

  useEffect(() => {
    if (
      groupParams.profilePhoto &&
      !filesToDelete.includes(groupParams.profilePhoto) &&
      // backend will cleanup old photos
      (!isEditMode ||
        (isEditMode && group.profilePhoto !== groupParams.profilePhoto))
    ) {
      setFilesToDelete([...filesToDelete, groupParams.profilePhoto]);
    }
  }, [filesToDelete, groupParams.profilePhoto, isEditMode, group]);

  const cleanupFiles = async (fileToKeep) => {
    if (filesToDelete.length) {
      const files = filesToDelete.filter((file) => file !== fileToKeep);
      await deleteFiles(files);
    }
  };

  const resetForm = useCallback(() => {
    setGroupParams({ ...defaultGroupParams });
    setFilesToDelete([]);
    setInvitedUsers([]);
    setKeywords([]);
    setIsValid(false);
  }, []);

  useEffect(() => {
    if (!isGroupFormOpen) {
      resetForm();
    }
  }, [isGroupFormOpen, resetForm]);

  const onClose = async (shouldKeep, forceSkipConfirmation) => {
    if (!forceSkipConfirmation && isDirty && !promptForConfirmation()) {
      return;
    }

    setIsDirty(false);

    const fileToKeep = shouldKeep && groupParams.profilePhoto;

    cleanupFiles(fileToKeep);

    closeGroupForm();
  };

  const onSave = async () => {
    const input = {
      ...groupParams,
    };

    isEditMode ? await updateGroup(input) : await createGroup(input);

    setIsDirty(false);

    onClose(true, true);
  };

  const onFormClosed = () => {
    setIsDirty(false);
    onClose(false);
  };

  const onGroupParamsChanged = (...args) => {
    setIsDirty(true);
    setIsValid(!!args[0]?.name);
    setGroupParams(...args);
  };

  const onChange = (field) => (e) => {
    onGroupParamsChanged({
      ...groupParams,
      [field]: e.target.value,
    });
  };

  const onTypeChanged = (type) => {
    onGroupParamsChanged({
      ...groupParams,
      type,
    });
  };

  const onChangeCoverPhotoClicked = () => {
    setShowCoverPhotoUploader(true);
  };

  const onCoverPhotoClosed = () => {
    setShowCoverPhotoUploader(false);
  };

  const onCoverPhotoUploaded = async ({ secure_url: profilePhoto }) => {
    onGroupParamsChanged({
      ...groupParams,
      profilePhoto,
    });
  };

  const { searchAll } = useSearchAll();

  const formatResponse = (response) => {
    const { users } = response;
    const selectedIds = invitedUsers.map((item) => item.value);

    const createOptions = (page) => {
      return page.edges
        .map(({ node }) => ({
          label: node.name,
          value: node.id,
          node,
        }))
        .filter((item) => !selectedIds.includes(item.node.id));
    };

    return [
      {
        label: "users",
        options: createOptions(users),
      },
    ];
  };

  const getSuggestions = async (searchString) => {
    const input = {
      searchString,
      includeUsers: true,
      userFilters: {
        followers: true,
      },
    };
    const res = await searchAll(input);
    return formatResponse(res);
  };

  const handleInviteListChange = (users) => {
    let membersToAdd = [];

    if (users.length) {
      if (!isEditMode) {
        groupParams.memberIds = invitedUsers.map((item) => item.value);
      } else {
        const userIds = users.map((item) => item.value);
        const existingUserIds = isEditMode
          ? group.invitedConnection.edges.map(({ node }) => node.id)
          : [];

        membersToAdd = difference(userIds, existingUserIds);
      }
    }

    onGroupParamsChanged({
      ...groupParams,
      membersToAdd,
    });
  };

  const typeOptions = (
    isEditMode
      ? group.type === "PERSONAL"
        ? ["PERSONAL"]
        : ["exclusive", "public"]
      : ["personal", "exclusive", "public"]
  ).map((option) => ({
    value: option.replace(/\s+/g, "_").toUpperCase(),
    label: option,
  }));

  const { name, description, type, profilePhoto } = groupParams;

  return (
    <>
      <GroupDialog
        open={isGroupFormOpen}
        dialogTitle={isEditMode ? "Edit Group" : "Create Group"}
        actionText={isEditMode ? "Save" : "Create"}
        name={name}
        description={description}
        type={type}
        profilePhoto={profilePhoto}
        inviteInput={
          <AutocompleteList
            label={type !== "PERSONAL" ? "Invited Users" : "Members"}
            value={invitedUsers}
            isMulti
            canCreate={false}
            getSuggestions={getSuggestions}
            onChange={handleInviteListChange}
          />
        }
        typeInput={
          <RadioGrid
            options={typeOptions}
            title="Type"
            defaultValue={typeOptions[0].value}
            value={type}
            onChange={onTypeChanged}
          />
        }
        tagsInput={
          <AutocompleteList
            label={"Tags"}
            value={keywords}
            canCreate
            fullWidth
            onChange={onKeywordsChanged}
            isMulti
            hideSuggestions
            removeSpacing
          />
        }
        onChangeCoverPhotoClicked={onChangeCoverPhotoClicked}
        onChange={onChange}
        onSave={onSave}
        onClose={onFormClosed}
        loading={isLoading}
        isValid={isValid}
      />

      <CoverPhotoUploader
        isOpen={showCoverPhotoUploader}
        onClose={onCoverPhotoClosed}
        onFileUploaded={onCoverPhotoUploaded}
        ownerId={currentUser && currentUser.id}
      />
    </>
  );
};

export default memo(GroupForm);
