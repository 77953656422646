import React from "react";
import ReactDOM from "react-dom";

import { ErrorPage } from "pure-components/ErrorBoundary";

import initGlobals from "./initGlobals";
import App from "./App";

initGlobals()
  .then(() => {
    ReactDOM.render(<App />, document.getElementById("root"));
  })
  .catch((err) => {
    // eslint-disable-next-line
    console.error(err);
    ReactDOM.render(<ErrorPage />, document.getElementById("root"));
  });
