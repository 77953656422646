import React, { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import {
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  LoadingSpinner,
} from "mui-treasury/src/brands/peapods";

import RadioGrid from "pure-components/RadioGrid";

import SearchInput from "smart-components/SearchInput";

import { useGetFullAudience } from "graphql/api/index";

const AudienceSelector = ({
  podId,
  eventId,
  value,
  showGroups,
  showUsers,
  onChange,
}) => {
  const privacyOptions = ["public", "followers", "custom"].map((option) => ({
    value: option.replace(/\s+/g, "_").toUpperCase(),
    label: option,
  }));

  const defaultPrivacyOption = privacyOptions[0].value;

  const isValueCustomAudience = useCallback(() => {
    return (
      value &&
      ((value.whitelistUsers && value.whitelistUsers.length) ||
        (value.blacklistUsers && value.blacklistUsers.length) ||
        (value.whitelistGroups && value.whitelistGroups.length) ||
        (value.blacklistGroups && value.blacklistGroups.length))
    );
  }, [value]);

  const getInitialPrivacyValue = () => {
    let val = defaultPrivacyOption;

    if (value) {
      val = isValueCustomAudience() ? "CUSTOM" : "FOLLOWERS";
    }

    return val;
  };

  const initialPrivacyValue = getInitialPrivacyValue();
  const [savedPrivacyValue] = useState(initialPrivacyValue);

  const [state, setState] = useState({
    privacy: initialPrivacyValue,
    whiteList: [],
    blackList: [],
  });

  const {
    getFullAudience,
    getFullAudienceCalled,
    getFullAudienceLoading,
    getFullAudienceData,
  } = useGetFullAudience();

  useEffect(() => {
    const getListValue = (list, type) => {
      const idList = list
        .filter((item) => item.node.__typename === type)
        .map((item) => item.node.id);

      return idList.length ? idList : undefined;
    };

    // TODO: don't allo blacklist self
    // dont' allow empty object for audience
    // what if delete pod that users belong to?
    // what if change audience that affects pod members?

    const { privacy, whiteList, blackList } = state;

    const newAudience = {
      followersOnly: privacy === "FOLLOWERS" ? true : undefined,
      whitelistGroups: getListValue(whiteList, "Group"),
      blacklistGroups: getListValue(blackList, "Group"),
      whitelistUsers: getListValue(whiteList, "User"),
      blacklistUsers: getListValue(blackList, "User"),
    };

    const isEmptyAudience = Object.values(newAudience).every(
      (el) => el === undefined,
    );

    if (isEqual(newAudience, value) || (isEmptyAudience && !value)) {
      return;
    } else {
      onChange(newAudience);
    }
  }, [state, onChange, value]);

  useEffect(() => {
    const { whiteList, blackList } = state;
    if (
      savedPrivacyValue === "CUSTOM" &&
      !getFullAudienceCalled &&
      (podId || eventId) &&
      whiteList &&
      !whiteList.length &&
      blackList &&
      !blackList.length &&
      isValueCustomAudience()
    ) {
      getFullAudience({
        eventId,
        podId,
      });
    }
  }, [
    savedPrivacyValue,
    state,
    isValueCustomAudience,
    getFullAudience,
    getFullAudienceCalled,
    podId,
    eventId,
  ]);

  useEffect(() => {
    if (!getFullAudienceData || !getFullAudienceData.getFullAudience) {
      return;
    }

    const {
      getFullAudience: { fullAudience },
    } = getFullAudienceData;

    const whitelistGroups = fullAudience.whitelistGroups || [];
    const blacklistGroups = fullAudience.blacklistGroups || [];
    const whitelistUsers = fullAudience.whitelistUsers || [];
    const blacklistUsers = fullAudience.blacklistUsers || [];

    const newWhiteList = [
      ...whitelistGroups.map((group) => ({
        label: group.name,
        value: group.id,
        node: group,
      })),
      ...whitelistUsers.map((user) => ({
        label: user.name,
        value: user.id,
        node: user,
      })),
    ];

    const newBlackList = [
      ...blacklistGroups.map((group) => ({
        label: group.name,
        value: group.id,
        node: group,
      })),
      ...blacklistUsers.map((user) => ({
        label: user.name,
        value: user.id,
        node: user,
      })),
    ];

    const newState = {
      privacy: "CUSTOM",
      whiteList: newWhiteList,
      blackList: newBlackList,
    };

    setState(newState);
  }, [getFullAudienceData]);

  const handleWhiteListChange = (whiteList) => {
    setState({
      ...state,
      whiteList,
    });
  };

  const handleBlackListChange = (blackList) => {
    setState({
      ...state,
      blackList,
    });
  };

  const onPrivacyChanged = (privacy) => {
    setState({
      ...state,
      privacy,
    });
  };

  const onReset = () => {
    setState({
      privacy: defaultPrivacyOption,
      whiteList: [],
      blackList: [],
    });
  };

  const { privacy, whiteList, blackList } = state;

  return (
    <Grid container>
      <FormControl margin={"none"} fullWidth>
        {privacy === "CUSTOM" && <FormLabel>Privacy</FormLabel>}

        {getFullAudienceLoading && (
          <Grid item>
            <LoadingSpinner />
          </Grid>
        )}

        {!getFullAudienceLoading && privacy !== "CUSTOM" ? (
          <Grid item>
            <RadioGrid
              options={privacyOptions}
              title="Privacy"
              defaultValue={defaultPrivacyOption}
              value={privacy}
              onChange={onPrivacyChanged}
            />
          </Grid>
        ) : (
          <>
            {!getFullAudienceLoading && (
              <Grid item>
                <FormControlLabel
                  control={<Radio onClick={onReset} />}
                  label={"reset"}
                  value={"reset"}
                />

                <Grid container>
                  <FormControl margin={"none"} fullWidth>
                    <Grid item>
                      <SearchInput
                        label="include"
                        value={whiteList}
                        includeUsers
                        includeGroups
                        onChange={handleWhiteListChange}
                      />
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid container>
                  <FormControl margin={"none"} fullWidth>
                    <Grid item>
                      <SearchInput
                        label="exclude"
                        value={blackList}
                        includeUsers
                        includeGroups
                        onChange={handleBlackListChange}
                      />
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </FormControl>
    </Grid>
  );
};

AudienceSelector.propTypes = {
  podId: PropTypes.string,
  eventId: PropTypes.string,
  showGroups: PropTypes.bool,
  showUsers: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  audience: PropTypes.shape({
    followersOnly: PropTypes.bool,
    whitelistGroups: PropTypes.arrayOf(PropTypes.string.isRequired),
    blacklistGroups: PropTypes.arrayOf(PropTypes.string.isRequired),
    whitelistUsers: PropTypes.arrayOf(PropTypes.string.isRequired),
    blacklistUsers: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
};

AudienceSelector.defaultProps = {
  podId: undefined,
  eventId: undefined,
  showGroups: true,
  showUsers: true,
  audience: undefined,
};

export default memo(AudienceSelector);
