import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Grid, Box } from "mui-treasury/src/brands/peapods";
import Lottie from "lottie-react-web";

import notFoundAnimation from "images/pea-not-found-animation.json";

const ErrorPage = ({ error }) => {
  // TODO: is this the right way to handle this error?
  // it seems to be what twitter.com does, what if the user has unsaved state?
  const reloadErrors = [`SyntaxError: Unexpected token '<'`, "Loading chunk"];
  if (error && reloadErrors.includes(error.message)) {
    window.location.reload();
  }

  return (
    <Grid
      container
      direction={"column"}
      alignItems="center"
      justify="center"
      padding={2}
      style={{
        height: "100%",
      }}
    >
      <Grid item>
        <Box fontSize={24} align="center" m={2}>
          Oops!
        </Box>
        <Box fontSize={24} align="center" m={2}>
          We hit a snag and are investigating.
        </Box>
        <Box fontSize={24} align="center" m={2}>
          Thanks for your patience!
        </Box>
      </Grid>

      <Grid item>
        <div style={{ maxWidth: 500, maxHeight: 500 }}>
          <Lottie
            options={{
              renderer: "svg",
              loop: false,
              autoplay: true,
              animationData: notFoundAnimation,
            }}
          />
        </div>
      </Grid>

      <Grid item>
        <Box fontSize={20} align="center">
          {error && `Error: ${error.message}`}
        </Box>
      </Grid>
    </Grid>
  );
};

const ErrorBoundaryWrapper = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
  );
};

export { ErrorPage };
export default ErrorBoundaryWrapper;
