import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import { reportContent as reportContentMutation } from "graphql/api";

const useReportContent = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [reportContent, { loading: reportContentLoading }] = useMutation(
    reportContentMutation,
  );

  const reportContentWrapper = async (input) => {
    const { data } = await reportContent({
      variables: {
        input,
      },
    });

    if (data && data.reportContent.success) {
      enqueueSnackbar("Reported Successfully", {
        variant: "success",
      });
    }
  };

  return {
    reportContent: reportContentWrapper,
    reportContentLoading,
  };
};

export default useReportContent;
