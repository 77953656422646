import { useMutation } from "@apollo/client";

import { createMessage as createMessageMutation } from "graphql/api";

const useCreateMessage = (threadId) => {
  const [createMessage, { loading }] = useMutation(createMessageMutation);

  return {
    createMessage,
    createMessageLoading: loading,
  };
};

export default useCreateMessage;
