import React, { useEffect, useRef } from "react";

const CalendarButton = ({ children, isOpen, onClick, clearEvent }) => {
  const buttonRef = useRef();

  const onOpenChanged = () => {
    if (isOpen && buttonRef.current) {
      buttonRef.current.click();
    }
  };

  useEffect(onOpenChanged, [isOpen]);

  return (
    <div
      ref={buttonRef}
      onClick={onClick}
      style={{
        visibility: "hidden",
        position: "absolute",
      }}
    >
      {children}
    </div>
  );
};

export default CalendarButton;
