import { useMutation } from "@apollo/client";

import { approveInviteCode as approveInviteCodeMutation } from "graphql/api";

const useApproveInviteCode = () => {
  const [approveInviteCode, { loading }] = useMutation(
    approveInviteCodeMutation,
    {
      refetchQueries: ["getInviteCodes"],
    },
  );

  return {
    approveInviteCode,
    approveInviteCodeLoading: loading,
  };
};

export default useApproveInviteCode;
