import { useMutation } from "@apollo/client";

import { denyInviteCode as denyInviteCodeMutation } from "graphql/api";

const useDenyInviteCode = () => {
  const [denyInviteCode, { loading }] = useMutation(denyInviteCodeMutation, {
    refetchQueries: ["getInviteCodes"],
  });

  return {
    denyInviteCode,
    denyInviteCodeLoading: loading,
  };
};

export default useDenyInviteCode;
