import useGetCurrentUser from "./getCurrentUser";
import useReportContent from "./reportContent";
import useFollowUser from "./followUser";
import useGetUserById from "./getUserById";
import useUpdateUser from "./updateUser";

const useUserHooks = ({ userId, username } = {}) => {
  const {
    refetchCurrentUser,
    currentUser,
    isLoading: getCurrentUserLoading,
  } = useGetCurrentUser();
  const { reportContent, reportContentLoading } = useReportContent();
  const { followUser, followUserLoading } = useFollowUser();
  const { updateUser, updateUserLoading } = useUpdateUser();
  const { getUserByIdData, getUserByIdLoading } = useGetUserById({
    id: userId,
    username,
  });

  return {
    refetchCurrentUser,
    currentUser,
    getCurrentUserLoading,
    reportContent,
    reportContentLoading,
    followUser,
    followUserLoading,
    getUserByIdData,
    getUserByIdLoading,
    updateUser,
    updateUserLoading,
  };
};

export default useUserHooks;
