import { useMutation } from "@apollo/client";

import { updateUser as updateUserMutation } from "graphql/api";

const useUpdateUser = () => {
  const [updateUser, { loading: updateUserLoading }] = useMutation(
    updateUserMutation,
    {
      refetchQueries: ["getCurrentUser", "getUserById"],
    },
  );

  const updateUserWrapper = (input) => {
    return updateUser({
      variables: {
        input,
      },
    });
  };

  return {
    updateUser: updateUserWrapper,
    updateUserLoading,
  };
};

export default useUpdateUser;
