import styled from "styled-components";

const CategorySlider = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

const MobileSliderItem = styled.div`
  > div {
    width: 160px;
  }
`;

export { CategorySlider, MobileSliderItem };
