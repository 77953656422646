import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  FormControl,
  DateTimePicker,
  FormLabel,
  TextField,
  LoadingSpinner,
} from "mui-treasury/src/brands/peapods";
import dayjs from "dayjs";

import RadioGrid from "pure-components/RadioGrid";
import TimeRangeSelector from "pure-components/TimeRangeSelector";

const PodTimeSelector = ({
  loading,
  userId,
  value: timeOption,
  onChange,
  onEditModeChange,
  startTime,
  endTime,
  timeRangeOptions,
  allPodUsers,
}) => {
  const timeOptions = ["use event time", "enable planning", "custom"].map(
    (option) => ({
      value: option.replace(/\s+/g, "_").toUpperCase(),
      label: option,
    }),
  );

  const defaultTimeOption = timeOptions[0].value;

  const [showStartTimePicker, setShowStartTimePicker] = useState(false);
  const [showEndTimePicker, setShowEndTimePicker] = useState(false);
  const minStartDate = new Date(dayjs().add(30, "minutes"));
  const maxStartDate = new Date(dayjs().add(4, "months"));
  const minEndDate = new Date(dayjs(minStartDate).add(30, "minutes"));
  const maxEndDate = new Date(
    dayjs(startTime || minStartDate).add(4, "months"),
  );

  const formatTime = (time) =>
    time ? dayjs(time).format("MM/DD/YYYY HH:mm") : "";

  const onRadioChanged = (val) => {
    onChange({
      startTime,
      endTime,
      timeRangeOptions,
      timeOption: val,
    });
  };

  const onAddStartTime = () => {
    setShowStartTimePicker(true);
  };

  const onSaveStartTime = (startTime) => {
    onChange({
      startTime,
      endTime,
      timeRangeOptions,
      timeOption,
    });
    onCloseStartTimePicker();
    onAddEndTime();
  };

  const onCloseStartTimePicker = () => {
    setShowStartTimePicker(false);
  };

  const onAddEndTime = () => {
    setShowEndTimePicker(true);
  };

  const onSaveEndTime = (endTime) => {
    onChange({
      startTime,
      endTime,
      timeRangeOptions,
      timeOption,
    });
    onCloseEndTimePicker();
  };

  const onCloseEndTimePicker = () => {
    setShowEndTimePicker(false);
  };

  const onTimeRangeOptionsChanged = (timeRangeOptions) => {
    onChange({
      timeRangeOptions,
      startTime,
      endTime,
      timeOption,
    });
  };

  return (
    <Grid container>
      <FormControl margin={"none"} fullWidth>
        {loading && (
          <Grid item>
            <LoadingSpinner />
          </Grid>
        )}

        {!loading && (
          <>
            <Grid item>
              <RadioGrid
                options={timeOptions}
                title="Time Option"
                defaultValue={defaultTimeOption}
                value={timeOption}
                onChange={onRadioChanged}
              />
            </Grid>

            {timeOption === "ENABLE_PLANNING" && (
              <TimeRangeSelector
                timeRangeOptions={timeRangeOptions}
                onChange={onTimeRangeOptionsChanged}
                onEditModeChange={onEditModeChange}
                userId={userId}
                users={allPodUsers}
              />
            )}

            {timeOption === "CUSTOM" && (
              <Grid container direction="row" justify="space-around">
                <Grid item xs={4}>
                  <FormControl margin={"normal"} fullWidth>
                    <FormLabel>Start time</FormLabel>
                    <DateTimePicker
                      strictCompareDates
                      minDate={minStartDate}
                      maxDate={maxStartDate}
                      open={showStartTimePicker}
                      variant="dialog"
                      format="MM/DD/YY HH:mm"
                      id="date-picker-inline"
                      value={startTime || minStartDate}
                      onChange={onSaveStartTime}
                      onClose={onCloseStartTimePicker}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      okLabel="Select start time"
                      disablePast
                      TextFieldComponent={() => (
                        <TextField
                          fullWidth
                          value={formatTime(startTime)}
                          onClick={onAddStartTime}
                          readOnly
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl margin={"normal"} fullWidth>
                    <FormLabel>End time</FormLabel>
                    <DateTimePicker
                      strictCompareDates
                      minDate={minEndDate}
                      maxDate={maxEndDate}
                      open={showEndTimePicker}
                      variant="dialog"
                      format="MM/DD/YY HH:mm"
                      id="date-picker-inline"
                      value={endTime || minEndDate}
                      onChange={onSaveEndTime}
                      onClose={onCloseEndTimePicker}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      okLabel="Select end time"
                      disablePast
                      TextFieldComponent={() => (
                        <TextField
                          fullWidth
                          value={formatTime(endTime)}
                          onClick={onAddEndTime}
                          readOnly
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </FormControl>
    </Grid>
  );
};

PodTimeSelector.propTypes = {
  userId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditModeChange: PropTypes.func,
  loading: PropTypes.bool,
  value: PropTypes.string,
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  timeRangeOptions: PropTypes.arrayOf(PropTypes.shape({})),
  allPodUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      profilePhoto: PropTypes.string,
    }),
  ),
};

PodTimeSelector.defaultProps = {
  loading: false,
  value: undefined,
  startTime: undefined,
  endTime: undefined,
  timeRangeOptions: [],
  allPodUsers: [],
  onEditModeChange: undefined,
};

export default memo(PodTimeSelector);
