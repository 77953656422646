import { useState, useEffect } from "react";

import useCreateMessage from "./createMessage";
import useGetMessageByParent from "./getMessagesByParent";

const useMessageHooks = (threadIdProp) => {
  const [threadId, setThreadId] = useState();

  const { createMessage, createMessageLoading } = useCreateMessage(threadId);

  const { messages, messagesLoading, pageInfo, loadNextPage } =
    useGetMessageByParent(threadId);

  useEffect(() => {
    if (threadIdProp !== threadId) {
      setThreadId(threadIdProp);
    }
  }, [threadIdProp, threadId]);

  return {
    useGetMessageByParent,
    messages,
    messagesLoading,
    pageInfo,
    loadNextPage,
    createMessage,
    createMessageLoading,
  };
};

export default useMessageHooks;
