import { useMutation } from "@apollo/client";

import { leaveGroup as leaveGroupMutation } from "graphql/api";

const useLeaveGroup = () => {
  const [leaveGroup, { data: leaveGroupData, loading: leaveGroupLoading }] =
    useMutation(leaveGroupMutation, {
      refetchQueries: ["getGroupById", "getCurrentUser"],
    });

  return {
    leaveGroup,
    leaveGroupData,
    leaveGroupLoading,
  };
};

export default useLeaveGroup;
