import React from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import {
  Box,
  Grid,
  Dialog,
  Typography,
  theme as muiTheme,
  useTheme,
} from "mui-treasury/src/brands/peapods";

const { md } = muiTheme.breakpoints.values;

const ModalTitleContainer = styled.div`
  border-radius: 16px;
  height: 60px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? "100%" : "calc(100% - 72px)")};
  max-height: ${({ isMobile }) => (isMobile ? "100%" : "calc(100% - 72px)")};

  > * {
    height: 100%;
  }
`;

const ModalContentWrapper = styled.div`
  max-height: 900px;
  padding: 30px;
  padding-top: 0;
`;

const Column = styled(Grid)`
  display: flex;
  margin: 0 !important;
  max-height: 100%;

  @media (max-width: ${md}px) {
    padding: 0 !important;
  }
`;

const Container = styled(Column)`
  flex-direction: "row";

  @media (max-width: ${md}px) {
    flex-direction: "row-reverse";
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 40px;
  height: 160px;
  width: 84px;
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`;

const ModalWrapper = styled(Dialog)`
  & .MuiDialog-paper {
    ${({ isMobile }) =>
      isMobile &&
      css`
        width: 100%;
        border-radius: 0;
        max-height: unset;
        height: 100%;
        margin: 0;
        animation: ${slideUp} 0.5s;
      `}
  }
`;

const LayoutModal = ({ render, open, onClose, title }) => {
  const theme = useTheme();
  const { isMobile } = theme;

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      disableRestoreFocus
      isMobile={isMobile}
    >
      <Box
        position={"sticky"}
        top={0}
        left={0}
        style={{
          background: "white",
          zIndex: 4000,
        }}
      >
        {title && (
          <ModalTitleContainer>
            <Typography
              align={"center"}
              variant={"h5"}
              gutterBottom
              color={"secondary"}
            >
              {title}
            </Typography>
          </ModalTitleContainer>
        )}
      </Box>

      <ModalContentWrapper>{render({ isMobile })}</ModalContentWrapper>
    </ModalWrapper>
  );
};

const Layout = ({
  primaryOnly,
  secondaryAlignRight,
  renderSecondary,
  renderPrimary,
  renderPrimaryDetails,
  renderMobileButton,
  children,
}) => {
  const theme = useTheme();
  const { isDesktop, isTablet, isMobile } = theme;

  return (
    <ContentWrapper isMobile={isMobile}>
      <Container container spacing={3} justify="center" wrap="nowrap">
        {!primaryOnly && isDesktop && !secondaryAlignRight && (
          <Column item xs={12} sm={12} md={6} lg={4}>
            {renderSecondary && renderSecondary()}
          </Column>
        )}

        <Column
          item
          xs={12}
          sm={12}
          md={primaryOnly ? 12 : 6}
          lg={primaryOnly ? 7 : 4}
        >
          {renderPrimary && renderPrimary()}
        </Column>

        {(isTablet || isDesktop) && !primaryOnly && (
          <Column item xs={12} sm={12} md={6} lg={4}>
            {renderPrimaryDetails && renderPrimaryDetails()}
          </Column>
        )}

        {!primaryOnly && isDesktop && secondaryAlignRight && (
          <Column item xs={12} sm={12} md={6} lg={4}>
            {renderSecondary && renderSecondary()}
          </Column>
        )}
      </Container>

      {children}

      {isMobile && renderMobileButton && (
        <ButtonContainer>{renderMobileButton()}</ButtonContainer>
      )}
    </ContentWrapper>
  );
};

Layout.Modal = LayoutModal;

Layout.propTypes = {
  primaryOnly: PropTypes.bool,
  secondaryAlignRight: PropTypes.bool,
  renderPrimary: PropTypes.func,
  renderPrimaryDetails: PropTypes.func,
  renderSecondary: PropTypes.func,
  renderMobileButton: PropTypes.func,
};

Layout.defaultProps = {
  primaryOnly: false,
  secondaryAlignRight: false,
  renderPrimary: () => {},
  renderPrimaryDetails: () => {},
  renderSecondary: () => {},
  renderMobileButton: () => {},
};

export { LayoutModal as Modal };
export default Layout;
