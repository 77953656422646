import React, { forwardRef, useEffect, memo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  SnackbarContent,
  theme,
  Grid,
  Typography,
} from "mui-treasury/src/brands/peapods";
import lottie from "lottie-web";
import { makeStyles } from "@material-ui/core/styles";

import animationData from "./lottie-data.json";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    minHeight: 180,
  },
  lottieContainer: {
    minWidth: 160,
    maxWidth: 160,
    minHeight: 160,
    maxHeight: 160,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    marginRight: 40,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 16,
  },
  text: {
    color: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
    marginRight: 8,
  },
}));

const CookieSnackbar = forwardRef(({ key, closeSnackbar }, ref) => {
  const classes = useStyles();

  const onAccept = () => {
    window.localStorage.setItem("cookieAccepted", true);
    closeSnackbar(key);
  };

  const onLearnMore = () => {
    window.open("https://www.cookiesandyou.com/", "_blank");
  };

  useEffect(() => {
    lottie.loadAnimation({
      container: window.document.getElementById("cookie-lottie-container"),
      renderer: window.innerWidth < 1500 ? "canvas" : "svg",
      loop: true,
      autoplay: true,
      animationData,
    });
  }, []);

  return (
    <SnackbarContent
      ref={ref}
      id={key}
      style={{
        padding: 0,
        backgroundColor: theme.palette.secondary.main,
      }}
      message={
        <Grid className={classes.root}>
          <div
            id="cookie-lottie-container"
            className={classes.lottieContainer}
          />

          <Grid className={classes.body}>
            <Typography variant={"body1"} className={classes.text}>
              This website uses cookies to enhance the user experience.
            </Typography>

            <Grid className={classes.btnContainer}>
              <Button
                onClick={onLearnMore}
                variant="outlined"
                size="small"
                className={classes.button}
              >
                Learn more
              </Button>

              <Button
                onClick={onAccept}
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
});

CookieSnackbar.propTypes = {
  closeSnackbar: PropTypes.func.isRequired,
};

export default memo(CookieSnackbar);
