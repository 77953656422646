import { useMutation } from "@apollo/client";

import { updateGroup as updateGroupMutation } from "graphql/api";

const useUpdateGroup = () => {
  const [updateGroup, { loading }] = useMutation(updateGroupMutation, {
    refetchQueries: ["getCurrentUser", "getUserById", "getGroupById"],
  });

  return {
    updateGroup,
    updateGroupLoading: loading,
  };
};

export default useUpdateGroup;
