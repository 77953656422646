/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useRef, memo } from "react";
import PropTypes from "prop-types";
import { uniqBy } from "lodash";
import dayjs from "dayjs";
import {
  AutocompleteList,
  DateTimePicker,
  FormLabel,
  FormControl,
  ButtonBase,
  CardMedia,
  TextField,
  Grid,
  Box,
  Hidden,
  Switch,
  Icon,
  Typography,
  Button,
  CategoryToggle,
  EventDialog as PureEventDialog,
  Counter,
} from "mui-treasury/src/brands/peapods";

import usePreventLeave from "utils/preventLeave";

import { useDeleteFiles } from "graphql/api";

import RadioGrid from "pure-components/RadioGrid";
import AudienceSelector from "smart-components/AudienceSelector";
import LocationInput from "smart-components/LocationInput";

import { CategorySlider, MobileSliderItem } from "./styles";

const EventDialog = ({
  onClose,
  onSubmit,
  type,
  eventParams,
  setEventParams,
  isLoading,
  isDeleting,
  setShowPhotoUploader,
  open,
  title,
  ...props
}) => {
  // TODO: don't hardcode these values
  const DEFAULT_MIN_POD_SIZE = 2;
  const DEFAULT_MAX_POD_SIZE = 20;
  // TODO: pull from profile
  const DEFAULT_POD_SIZE = 3;
  const typeLabel = type ? `${type[0].toUpperCase()}${type.slice(1)}` : "";
  const isEditing = type === "save";

  const onChange = (...args) => {
    setIsDirty(true);
    setEventParams(...args);
  };

  const changeText = (event, name) => {
    onChange({
      ...eventParams,
      [name]: event.target.value,
    });
  };

  const { isDirty, setIsDirty, promptForConfirmation } = usePreventLeave();

  const [originalCoverPicture, setOriginalCoverPicture] = useState();
  const [invalidFee, setInvalidFee] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [podParams, setPodParams] = useState({
    state: undefined,
    datingOption: undefined,
    maxSize: undefined,
    audience: undefined,
  });

  const deleteFiles = useDeleteFiles("event-cover-photos");

  useEffect(() => {
    if (eventParams.hashtags) {
      setKeywords(
        eventParams.hashtags.map((hashtag) => ({
          label: hashtag,
          value: hashtag,
        })),
      );
    }
  }, [eventParams.hashtags]);

  useEffect(() => {
    if (
      eventParams.coverPicture &&
      !filesToDelete.includes(eventParams.coverPicture)
    ) {
      if (isEditing && !filesToDelete.length) {
        setOriginalCoverPicture(eventParams.coverPicture);
      }

      setFilesToDelete([...filesToDelete, eventParams.coverPicture]);
      setIsDirty(true);
    }
  }, [
    filesToDelete,
    eventParams.coverPicture,
    setIsDirty,
    isEditing,
    setOriginalCoverPicture,
  ]);

  const changeFee = (event) => {
    setInvalidFee(!event.target.value || Number.isNaN(event.target.value));
    const fee = { price: event.target.value };
    onChange({ ...eventParams, fee });
  };

  const changeToggle = (event, index) => {
    const newCategories = eventParams.categories;
    newCategories[index].checked = event.target.checked;
    onChange({
      ...eventParams,
      categories: newCategories,
    });
  };

  const onChangePhotoClicked = () => {
    setShowPhotoUploader(true);
  };

  const onKeywordsChanged = (keywords) => {
    onChange({
      ...eventParams,
      hashtags: uniqBy(keywords, "value").map((tag) => tag.value),
    });
  };

  const onPrivacyChanged = (e) => {
    onChange({
      ...eventParams,
      isPrivate: e.target.checked,
    });
  };

  const onVirtualChanged = (e) => {
    onChange({
      ...eventParams,
      isVirtual: e.target.checked,
    });
  };

  const onAudienceChanged = (audience) => {
    onChange({ ...eventParams, audience });
    setPodParams({
      ...podParams,
      audience,
    });
  };

  const onLocationChanged = (res) => {
    if (!res) {
      return;
    }

    const lat = res.geometry.location.lat();
    const lng = res.geometry.location.lng();
    const formattedAddress = res.formatted_address;

    const location = { lat, lng, formattedAddress };
    onChange({ ...eventParams, location });
  };

  const onStateChanged = (state) => {
    setPodParams({
      ...podParams,
      state,
    });
  };

  const onDatingChanged = (datingOption) => {
    setPodParams({
      ...podParams,
      datingOption,
    });
  };

  const onMaxSizeChanged = (e) => {
    setPodParams({
      ...podParams,
      maxSize: e.target.value,
    });
  };

  const [showStartTimePicker, setShowStartTimePicker] = useState(false);
  const [showEndTimePicker, setShowEndTimePicker] = useState(false);
  const minStartDate = new Date(dayjs().add(30, "minutes"));
  const maxStartDate = new Date(dayjs().add(4, "months"));
  const minEndDate = new Date(dayjs(minStartDate).add(30, "minutes"));
  const maxEndDate = new Date(
    dayjs(eventParams.startTime || minStartDate).add(4, "months"),
  );

  const statusOptions = ["interested", "attending"].map((option) => ({
    value: option.replace(/\s+/g, "_").toUpperCase(),
    label: option,
  }));

  const datingOptions = ["any", "only dates", "no dates"].map((option) => ({
    value: option.replace(/\s+/g, "_").toUpperCase(),
    label: option,
  }));

  const onAddStartTime = () => {
    setShowStartTimePicker(true);
  };

  const onSaveStartTime = (time) => {
    onChange({
      ...eventParams,
      startTime: time,
    });
    onCloseStartTimePicker();
    onAddEndTime();
  };

  const onCloseStartTimePicker = () => {
    setShowStartTimePicker(false);
  };

  const onAddEndTime = () => {
    setShowEndTimePicker(true);
  };

  const onSaveEndTime = (time) => {
    onChange({
      ...eventParams,
      endTime: time,
    });
    onCloseEndTimePicker();
  };

  const onCloseEndTimePicker = () => {
    setShowEndTimePicker(false);
  };

  const cleanupFiles = (fileToKeep) => {
    if (filesToDelete.length) {
      const files = filesToDelete.filter((file) => file !== fileToKeep);
      deleteFiles(files);
    }
  };

  const formatTime = (time) =>
    time ? dayjs(time).format("MM/DD/YYYY HH:mm") : "";

  const isValid =
    eventParams.title &&
    eventParams.coverPicture &&
    eventParams.location &&
    !invalidFee;

  const onCancel = ({
    forceIgnorePrompt,
    fileToKeep,
    skipFileCleanup,
  } = {}) => {
    const shouldPrompt = isDirty && !forceIgnorePrompt;

    if (shouldPrompt && !promptForConfirmation()) {
      return;
    }

    if (isEditing) {
      fileToKeep = originalCoverPicture;
    }

    if (!skipFileCleanup) {
      cleanupFiles(fileToKeep);
    }

    onClose();
    setIsDirty(false);
  };

  const actionButtons = [
    <Button elongated onClick={onCancel}>
      Cancel
    </Button>,
  ];

  if (isEditing) {
    actionButtons.push(
      <Button
        id="event-dialog-delete"
        loading={isDeleting}
        elongated
        color={"danger"}
        variant={"contained"}
        onClick={() => {
          onCancel({ skipFileCleanup: true });
          onSubmit(true);
        }}
      >
        Delete
      </Button>,
    );
  }

  actionButtons.push(
    <Button
      id={`event-dialog-${type}`}
      loading={isLoading}
      elongated
      color={"primary"}
      disabled={!isValid}
      variant={"contained"}
      onClick={() => {
        onCancel({
          fileToKeep: eventParams.coverPicture,
          forceIgnorePrompt: true,
        });
        onSubmit(false, isEditing ? null : podParams);
      }}
    >
      {typeLabel}
    </Button>,
  );

  const titleElement = useRef(null);

  const onEntered = () => {
    if (titleElement.current) {
      titleElement.current.focus();
    }
  };

  const renderCategories = (categories) =>
    categories.map(({ image, label, checked }, index) => (
      <Grid key={label} item sm={4}>
        <CategoryToggle
          checked={checked}
          label={label}
          src={image}
          onChange={(event) => changeToggle(event, index)}
        />
      </Grid>
    ));

  const renderCategoriesMobile = (categories) =>
    categories.map(({ image, label, checked }, index) => (
      <Grid key={label} item>
        <MobileSliderItem>
          <CategoryToggle
            checked={checked}
            label={label}
            src={image}
            onChange={(event) => changeToggle(event, index)}
          />
        </MobileSliderItem>
      </Grid>
    ));

  if (!eventParams) {
    return null;
  }

  return (
    <PureEventDialog
      open={open}
      className={"PeaEventDialog"}
      title={title}
      content={
        <>
          <Grid item>
            <FormControl margin={"normal"} fullWidth>
              <FormLabel required>Title</FormLabel>
              <TextField
                id="event-form-title"
                required
                fullWidth
                inputRef={titleElement}
                placeholder={"Type the title of your event"}
                InputLabelProps={{
                  shrink: true,
                }}
                value={eventParams.title}
                onChange={(event) => changeText(event, "title")}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl margin={"normal"} fullWidth>
              <FormLabel required>Photo</FormLabel>

              <Box mt={2}>
                <CardMedia
                  id={"event-form-image"}
                  className={"MuiCardMedia-root"}
                  image={eventParams.coverPicture}
                  style={{
                    position: "relative",
                    minHeight: 200,
                  }}
                >
                  <ButtonBase
                    className={"PeaEventForm-photoBtn"}
                    onClick={onChangePhotoClicked}
                  >
                    <Icon inverted icon={"panorama"} shape={"square"} />
                    <Typography inverted>Upload photo</Typography>
                  </ButtonBase>
                </CardMedia>
              </Box>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl fullWidth margin={"normal"}>
              <FormLabel required>Category</FormLabel>
              <Box mt={2}>
                <Hidden smDown>
                  <Grid container>
                    {renderCategories(eventParams.categories)}
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid container>
                    <CategorySlider>
                      {renderCategoriesMobile(eventParams.categories)}
                    </CategorySlider>
                  </Grid>
                </Hidden>
              </Box>
            </FormControl>
          </Grid>

          <Grid item>
            <TextField
              id="event-form-description"
              reevent-form-titlequired
              multiline
              rows={3}
              classes={{
                root: "PeaFormControl-root",
              }}
              InputLabelProps={{
                className: "PeaFormLabel-root",
              }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: "PeaInput-root",
                  input: "PeaInput-input",
                  formControl: "PeaInput-formControl",
                },
              }}
              FormHelperTextProps={{
                className: "PeaFormHelperText-root",
              }}
              fullWidth
              margin={"normal"}
              label={"Description"}
              placeholder={"Type few words about your event"}
              value={eventParams.description}
              onChange={(event) => changeText(event, "description")}
            />
          </Grid>

          <Grid item>
            <FormControl margin={"normal"} fullWidth>
              <LocationInput
                isRequired
                onChange={onLocationChanged}
                value={
                  eventParams.location
                    ? eventParams.location.formattedAddress
                    : ""
                }
              />
            </FormControl>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl margin={"normal"} fullWidth>
                <FormLabel>Start time</FormLabel>
                <DateTimePicker
                  strictCompareDates
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  open={showStartTimePicker}
                  variant="dialog"
                  format="MM/DD/YY HH:mm"
                  id="date-picker-inline"
                  value={eventParams.startTime || minStartDate}
                  onChange={onSaveStartTime}
                  onClose={onCloseStartTimePicker}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  okLabel="Select start time"
                  disablePast
                  TextFieldComponent={() => (
                    <TextField
                      id="event-date-picker"
                      fullWidth
                      value={formatTime(eventParams.startTime)}
                      onClick={onAddStartTime}
                      readOnly
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl margin={"normal"} fullWidth>
                <FormLabel>End time</FormLabel>
                <DateTimePicker
                  strictCompareDates
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                  open={showEndTimePicker}
                  variant="dialog"
                  format="MM/DD/YY HH:mm"
                  id="date-picker-inline"
                  value={eventParams.endTime || minEndDate}
                  onChange={onSaveEndTime}
                  onClose={onCloseEndTimePicker}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  okLabel="Select end time"
                  disablePast
                  TextFieldComponent={() => (
                    <TextField
                      fullWidth
                      value={formatTime(eventParams.endTime)}
                      onClick={onAddEndTime}
                      readOnly
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item>
            <FormControl margin={"normal"} fullWidth>
              <FormLabel>Hashtags</FormLabel>
              <AutocompleteList
                value={keywords}
                canCreate
                fullWidth
                placeholder={"Enter some tags for others to search"}
                onChange={onKeywordsChanged}
                isMulti
                hideSuggestions
                removeSpacing
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl margin={"normal"}>
              <FormLabel>Private Event</FormLabel>
              <Switch
                checked={eventParams.isPrivate}
                onChange={onPrivacyChanged}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl margin={"normal"}>
              <FormLabel>Virtual Event</FormLabel>
              <Switch
                checked={eventParams.isVirtual}
                onChange={onVirtualChanged}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl margin={"normal"} fullWidth>
              <FormLabel>Ticket price</FormLabel>
              <TextField
                id="event-ticket-price"
                fullWidth
                error={invalidFee}
                placeholder={"Type ticket price, $"}
                InputLabelProps={{
                  shrink: true,
                }}
                value={eventParams.fee.price}
                onChange={(event) => changeFee(event)}
              />
            </FormControl>
          </Grid>

          {isEditing && (
            <Grid item>
              <AudienceSelector
                eventId={eventParams.id}
                value={eventParams.audience}
                onChange={onAudienceChanged}
              />
            </Grid>
          )}

          {!isEditing && (
            <>
              <Box m={4} textAlign="center">
                <FormLabel>Pod Options</FormLabel>
              </Box>

              <Grid item>
                <RadioGrid
                  options={statusOptions}
                  title="Status"
                  defaultValue={statusOptions[0].value}
                  value={podParams.state}
                  onChange={onStateChanged}
                />
              </Grid>

              <Grid item>
                <AudienceSelector
                  eventId={eventParams.id}
                  value={eventParams.audience}
                  onChange={onAudienceChanged}
                />
              </Grid>

              <Grid item>
                <RadioGrid
                  options={datingOptions}
                  title="Dating Option"
                  defaultValue={datingOptions[0].value}
                  value={podParams.datingOption}
                  onChange={onDatingChanged}
                />
              </Grid>

              <Grid item>
                <FormControl margin={"normal"} component="fieldset">
                  <FormLabel component="legend">Size Limit</FormLabel>
                  <Counter
                    min={DEFAULT_MIN_POD_SIZE}
                    max={DEFAULT_MAX_POD_SIZE}
                    value={podParams.maxSize || DEFAULT_POD_SIZE}
                    onChange={onMaxSizeChanged}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </>
      }
      actions={actionButtons}
      onClose={onCancel}
      onEntered={onEntered}
      {...props}
    />
  );
};

EventDialog.defaultProps = {
  open: false,
  isLoading: false,
  isDeleting: false,
  eventParams: null,
};

EventDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setShowPhotoUploader: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  eventParams: PropTypes.object,
  isLoading: PropTypes.bool,
  isDeleting: PropTypes.bool,
  open: PropTypes.bool,
};

export default memo(EventDialog);
