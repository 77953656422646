import React, { memo } from "react";
import { Modal } from "pure-components/Layout";
import { Grid, Box, Button } from "mui-treasury/src/brands/peapods";

import { CalendarOptions } from "./styles";

const CalendarModal = ({ children, isOpen, onRequestClose, clearEvent }) => {
  const onClose = (e) => {
    clearEvent();
    onRequestClose(e);
  };

  return (
    <Modal
      open={isOpen}
      title={"Add to calendar"}
      render={(isMobile) => {
        return (
          <Grid
            container
            direction="column"
            style={{
              position: "relative",
            }}
          >
            <Grid item style={{ marginTop: 30 }}>
              <CalendarOptions onClick={onClose}>{children}</CalendarOptions>
            </Grid>

            <Box position={"sticky"} bottom={0} left={0} mt={4}>
              <Grid container alignItems={"center"} justify="center">
                <Grid item>
                  <Button onClick={onClose}>Cancel</Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      }}
    />
  );
};

export default memo(CalendarModal);
