import { useQuery } from "@apollo/client";

import { getUserById as getUserByIdQuery } from "graphql/api";

const useGetUserById = ({ id, username } = {}) => {
  const { data: getUserByIdData, loading: getUserByIdLoading } = useQuery(
    getUserByIdQuery,
    {
      variables: {
        input: {
          id,
          username,
        },
      },
      skip: !id && !username,
    },
  );

  return {
    getUserByIdData,
    getUserByIdLoading,
  };
};

export default useGetUserById;
