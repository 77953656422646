import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import { useCurrentUser } from "graphql/api";

import uploadFileMutation from "./uploadFile.graphql";

const useUploadFile = () => {
  const [input, setInput] = useState();
  const [onCompleted, setOnCompleted] = useState();
  const [onError, setOnError] = useState();
  const currentUser = useCurrentUser();

  const [
    uploadFile,
    {
      loading: uploadFileLoading,
      error: uploadFileError,
      data: uploadFileData,
    },
  ] = useMutation(uploadFileMutation, {
    variables: {
      input: {
        paramsToSign: input,
      },
    },
    onCompleted,
    onError,
    skip: !currentUser,
  });

  const uploadFileWrapper = async (uploadFileInput) => {
    await setInput();

    return new Promise(async (resolve, reject) => {
      await setOnCompleted(
        () =>
          ({ uploadFile }) =>
            resolve(uploadFile),
      );
      await setOnError(() => (error) => reject(error));
      await setInput(uploadFileInput);
    });
  };

  useEffect(() => {
    if (!input) {
      return;
    }
    uploadFile();
  }, [input, uploadFile]);

  return {
    uploadFile: uploadFileWrapper,
    uploadFileLoading,
    uploadFileError,
    uploadFileData,
  };
};

export default useUploadFile;
