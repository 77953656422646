import gql from "graphql-tag";

const queries = {
  IS_LOGGED_IN_QUERY: gql`
    query IsUserLoggedIn {
      isLoggedIn @client
    }
  `,

  GET_SELECTED_USERS_QUERY: gql`
    query GetSelectedUsers {
      selectedUsers @client
    }
  `,

  GET_SELECTED_EVENTS_QUERY: gql`
    query GetSelectedEvents {
      selectedEvents @client
    }
  `,

  GET_SELECTED_PODS_QUERY: gql`
    query GetSelectedPods {
      selectedPods @client
    }
  `,

  GET_SELECTED_THREAD_QUERY: gql`
    query GetSelectedThread {
      selectedThread @client
    }
  `,

  GET_USERS_QUERY: gql`
    query GetUsers {
      users @client
    }
  `,

  GET_EVENTS_QUERY: gql`
    query GetEvents {
      events @client
    }
  `,

  GET_SELECTED_GROUPS_QUERY: gql`
    query getSelectedGroups {
      selectedGroups @client
    }
  `,

  GET_CURRENT_USER_QUERY: gql`
    query GetCurrentUser {
      currentUser @client
    }
  `,

  GET_SHOW_POD_FORM: gql`
    query getShowPodForm {
      showPodForm @client
    }
  `,

  GET_SHOW_POD_TIME_RANGES_FORM: gql`
    query getShowPodTimeRangesForm {
      showPodTimeRangesForm @client
    }
  `,

  GET_SHOW_EVENT_FORM: gql`
    query getShowEventForm {
      showEventForm @client
    }
  `,

  GET_SHOW_GROUP_FORM: gql`
    query getShowGroupForm {
      showGroupForm @client
    }
  `,

  GET_SHOW_EVENT_EDIT_FORM: gql`
    query getShowEventEditForm {
      showEventEditForm @client
    }
  `,

  GET_SHOW_GROUP_EDIT_FORM: gql`
    query getShowGroupEditForm {
      showGroupEditForm @client
    }
  `,

  GET_SHOW_INVITE_FORM: gql`
    query getShowInviteForm {
      showInviteForm @client
    }
  `,

  GET_ADD_TO_CALENDAR_EVENT: gql`
    query getAddToCalendarEvent {
      addToCalendarEvent @client
    }
  `,
};

const mutations = {
  LOGIN: gql`
    mutation login($token: String!) {
      login(token: $token) @client
    }
  `,

  LOGOUT: gql`
    mutation logout {
      logout @client
    }
  `,

  SET_CURRENT_USER: gql`
    mutation setCurrentUser($user: User!) {
      setCurrentUser(user: $user) @client
    }
  `,

  SET_USERS: gql`
    mutation setUsers($users: [User!]!) {
      setUsers(users: $users) @client
    }
  `,

  SET_EVENTS: gql`
    mutation setEvents($events: [Event!]!) {
      setEvents(events: $events) @client
    }
  `,

  SELECT_EVENTS: gql`
    mutation selectEvents($events: [Event!]!) {
      selectEvents(events: $events) @client
    }
  `,

  SELECT_PODS: gql`
    mutation selectPods($pods: [Pod!]!) {
      selectPods(pods: $pods) @client
    }
  `,

  SELECT_USERS: gql`
    mutation selectUsers($users: [User!]!) {
      selectUsers(users: $users) @client
    }
  `,

  SELECT_GROUPS: gql`
    mutation selectGroups($groups: [Group!]!) {
      selectGroups(groups: $groups) @client
    }
  `,

  SELECT_THREAD: gql`
    mutation selectThread($thread: Thread!) {
      selectThread(thread: $thread) @client
    }
  `,

  DESELECT_EVENTS: gql`
    mutation deselectEvents($events: [Event!]!) {
      deselectEvents(events: $events) @client
    }
  `,

  CLEAR_SELECTED_EVENTS: gql`
    mutation clearSelectedEvents {
      clearSelectedEvents @client
    }
  `,

  SET_SHOW_POD_FORM: gql`
    mutation setShowPodForm($showPodForm: Boolean!) {
      setShowPodForm(showPodForm: $showPodForm) @client
    }
  `,

  SET_SHOW_POD_TIME_RANGES_FORM: gql`
    mutation setShowPodTimeRangesForm($showPodTimeRangesForm: Boolean!) {
      setShowPodTimeRangesForm(showPodTimeRangesForm: $showPodTimeRangesForm)
        @client
    }
  `,

  SET_SHOW_EVENT_FORM: gql`
    mutation setShowEventForm($showEventForm: Boolean!) {
      setShowEventForm(showEventForm: $showEventForm) @client
    }
  `,

  SET_SHOW_GROUP_FORM: gql`
    mutation setShowGroupForm($showGroupForm: Boolean!) {
      setShowGroupForm(showGroupForm: $showGroupForm) @client
    }
  `,

  SET_SHOW_EVENT_EDIT_FORM: gql`
    mutation setShowEventEditForm($showEventEditForm: Boolean!) {
      setShowEventEditForm(showEventEditForm: $showEventEditForm) @client
    }
  `,

  SET_SHOW_GROUP_EDIT_FORM: gql`
    mutation setShowGroupEditForm($showGroupEditForm: Boolean!) {
      setShowGroupEditForm(showGroupEditForm: $showGroupEditForm) @client
    }
  `,

  SET_SHOW_INVITE_FORM: gql`
    mutation setShowInviteForm($showInviteForm: Boolean!) {
      setShowInviteForm(showInviteForm: $showInviteForm) @client
    }
  `,

  SET_ADD_TO_CALENDAR_EVENT: gql`
    mutation setAddToCalendarEvent($event: Event) {
      setAddToCalendarEvent(event: $event) @client
    }
  `,
};

const selectedGroupsQuery = queries.GET_SELECTED_GROUPS_QUERY;
const getSelectedPodsQuery = queries.GET_SELECTED_PODS_QUERY;
const getShowGroupFormQuery = queries.GET_SHOW_GROUP_FORM;
const getAddToCalendarEventQuery = queries.GET_ADD_TO_CALENDAR_EVENT;
const getShowPodTimeRangesFormQuery = queries.GET_SHOW_POD_TIME_RANGES_FORM;
const getShowInviteFormQuery = queries.GET_SHOW_INVITE_FORM;

const setShowGroupFormMutation = mutations.SET_SHOW_GROUP_FORM;
const selectGroupsMutation = mutations.SELECT_GROUPS;
const selectPodsMutation = mutations.SELECT_PODS;
const setAddToCalendarEventMutation = mutations.SET_ADD_TO_CALENDAR_EVENT;
const setShowPodTimeRangesFormMutation =
  mutations.SET_SHOW_POD_TIME_RANGES_FORM;
const setShowInviteFormMutation = mutations.SET_SHOW_INVITE_FORM;
// TODO: export all queries and mutations as named
export {
  queries,
  mutations,
  // queries
  getShowGroupFormQuery,
  selectedGroupsQuery,
  getAddToCalendarEventQuery,
  getShowPodTimeRangesFormQuery,
  getSelectedPodsQuery,
  getShowInviteFormQuery,
  // mutations
  setAddToCalendarEventMutation,
  setShowPodTimeRangesFormMutation,
  setShowGroupFormMutation,
  selectGroupsMutation,
  selectPodsMutation,
  setShowInviteFormMutation,
};
