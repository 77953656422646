import { useState, useEffect } from "react";
import { useQuery, useApolloClient } from "@apollo/client";

import { queries } from "graphql/clientApi";

import getCurrentUserQuery from "./getCurrentUser.graphql";

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const client = useApolloClient();
  const { data } = useQuery(queries.IS_LOGGED_IN_QUERY);
  const isLoggedIn = data && data.isLoggedIn;

  const { data: getCurrentUserData } = useQuery(getCurrentUserQuery, {
    skip: !isLoggedIn,
  });

  useEffect(() => {
    if (getCurrentUserData) {
      const { user } = getCurrentUserData.getCurrentUser;
      setCurrentUser(user);
      client.cache.retain(`User:${user.id}`);
    }
  }, [getCurrentUserData, client.cache]);

  return currentUser;
};

export default useCurrentUser;
