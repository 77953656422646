import { useMutation } from "@apollo/client";

import { followUser as followUserMutation } from "graphql/api";

const useFollowUser = () => {
  const [followUser, { loading: followUserLoading }] = useMutation(
    followUserMutation,
    {
      refetchQueries: ["getCurrentUser", "getUserById"],
    },
  );

  return {
    followUser,
    followUserLoading,
  };
};

export default useFollowUser;
