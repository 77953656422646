import { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { some } from "lodash";

import usePreventLeave from "utils/preventLeave";

// TODO: export raw named queries
import {
  queries,
  mutations,
  getShowPodTimeRangesFormQuery,
  setShowPodTimeRangesFormMutation,
  getSelectedPodsQuery,
} from "graphql/clientApi";

import {
  getCurrentUser as getCurrentUserQuery,
  createPod as createPodMutation,
  updatePod as updatePodMutation,
  deletePod as deletePodMutation,
  joinPodMutation,
  searchEventsQuery,
  getPodsByEventId,
  getPodsByUserId,
  getEventById,
  useCurrentUser,
  getUserById,
} from "graphql/api";

// TODO: pull from profile
const DEFAULT_POD_SIZE = 3;

const defaultPodParams = {
  state: undefined,
  datingOption: undefined,
  maxSize: DEFAULT_POD_SIZE,
  audience: undefined,
  startTime: undefined,
  endTime: undefined,
  timeRangeOptions: undefined,
  timeOption: "USE_EVENT_TIME",
};

function hasSelectedTimeRangeOptions({ timeRangeOptions, userId }) {
  return some(timeRangeOptions, (option) => option.userId === userId);
}

const usePodGraphql = () => {
  const { promptForConfirmation } = usePreventLeave(true);

  const [podParams, setPodParams] = useState(defaultPodParams);
  const [myPod, setMyPod] = useState();
  const [event, setEvent] = useState();
  const [refetchQueries, setRefetchQueries] = useState([]);
  const [selectedPods, setSelectedPods] = useState();

  const currentUser = useCurrentUser();

  const { data: getSelectedPodsData } = useQuery(getSelectedPodsQuery);

  const { data: getShowPodFormData } = useQuery(queries.GET_SHOW_POD_FORM);
  const { showPodForm: modalOpen } = getShowPodFormData || {};

  const { data: getShowPodTimeRangesFormData } = useQuery(
    getShowPodTimeRangesFormQuery,
  );
  const { showPodTimeRangesForm: timeRangesModalOpen } =
    getShowPodTimeRangesFormData || {};

  const [selectPods] = useMutation(mutations.SELECT_PODS);

  const [hidePodForm] = useMutation(mutations.SET_SHOW_POD_FORM, {
    variables: {
      showPodForm: false,
    },
  });

  const [showTimeRangeOptionsForm] = useMutation(
    setShowPodTimeRangesFormMutation,
    {
      variables: {
        showPodTimeRangesForm: true,
      },
    },
  );

  const [hideTimeRangeOptionsForm] = useMutation(
    setShowPodTimeRangesFormMutation,
    {
      variables: {
        showPodTimeRangesForm: false,
      },
    },
  );

  const { loading: getEventByIdLoading, data: getEventByIdData } = useQuery(
    getEventById,
    {
      variables: {
        input: {
          id: event?.id,
        },
      },
      skip: !event?.id,
    },
  );

  const resetPodParams = () => setPodParams(defaultPodParams);

  const onClose = useCallback(() => {
    hidePodForm();
    resetPodParams();
  }, [hidePodForm]);

  const onTimeRangesModalClose = useCallback(() => {
    hideTimeRangeOptionsForm();
    resetPodParams();
  }, [hideTimeRangeOptionsForm]);

  const getTimeRangeOptions = useCallback(
    (timeRanges) => {
      if (!timeRanges?.length) {
        return;
      }

      const temp = timeRanges
        .filter((o) => o.userId === currentUser?.id)
        .map(({ state, start, end, timeRange }) => ({
          state,
          start: start || timeRange?.start,
          end: end || timeRange?.end,
        }));
      return temp;
    },
    [currentUser],
  );

  // TODO: don't allow empty timeOptions on update

  const formatInput = useCallback(
    (podParams) => {
      const { audience, startTime, endTime, timeRangeOptions, timeOption } =
        podParams;

      return {
        ...podParams,
        startTime,
        endTime,
        timeRangeOptions:
          timeOption === "ENABLE_PLANNING"
            ? getTimeRangeOptions(timeRangeOptions)
            : undefined,
        audience:
          audience && Object.values(audience).every((el) => el === undefined)
            ? { reset: true }
            : audience,
      };
    },
    [getTimeRangeOptions],
  );

  useEffect(() => {
    if (!modalOpen) {
      // TODO: show loading if myPod on event
      setPodParams({ ...defaultPodParams });
    } else if (myPod) {
      const newParams = {
        state: myPod.state,
        datingOption: myPod.datingOption,
        audience: myPod.audience,
        maxSize: myPod.maxSize,
        startTime: myPod.startTime,
        endTime: myPod.endTime,
        timeRangeOptions: myPod.timeRangeOptions,
        timeOption: myPod.timeOption,
      };
      setPodParams(newParams);
    }
  }, [modalOpen, myPod]);

  const { data } = useQuery(queries.GET_SELECTED_EVENTS_QUERY);
  const { selectedEvents } = data || {};

  useEffect(() => {
    const newEvent = selectedEvents && selectedEvents[0];
    setEvent(newEvent);
  }, [selectedEvents]);

  useEffect(() => {
    if (!getSelectedPodsData) {
      return;
    }
    setSelectedPods(getSelectedPodsData.selectedPods);
  }, [getSelectedPodsData, setSelectedPods]);

  useEffect(() => {
    if (!getEventByIdData) {
      return;
    }

    const fullEvent = {
      ...getEventByIdData?.getEventById?.event,
      myPod: getEventByIdData?.getEventById?.myPod,
    };

    setEvent((e) => fullEvent);
  }, [getEventByIdData]);

  useEffect(() => {
    const pod = event && event.myPod;

    setMyPod(pod);

    let newParams = defaultPodParams;
    if (pod) {
      newParams = {
        ...pod,
      };
      selectPods({
        variables: {
          pods: [pod],
        },
      });
    }

    setPodParams(newParams);
  }, [event, setPodParams, selectPods]);

  const skip = !event;

  const [createPod, { data: createPodData, loading: createPodLoading }] =
    useMutation(createPodMutation);

  const [updatePod, { data: updatePodData, loading: updatePodLoading }] =
    useMutation(updatePodMutation, {
      refetchQueries,
      skip,
    });

  const [joinPod, { data: joinPodData, loading: joinPodLoading }] =
    useMutation(joinPodMutation);

  const [deletePod, { data: deletePodData, loading: deletePodLoading }] =
    useMutation(deletePodMutation);

  const createPodWrapper = useCallback(
    (params = {}) => {
      const options = {
        variables: {
          input: {
            ...formatInput(params),
            eventId: params.eventId || event?.id,
          },
        },
        skip,
        refetchQueries,
      };

      return createPod(options);
    },
    [event, createPod, formatInput, refetchQueries, skip],
  );

  const joinPodWrapper = useCallback(
    async (pod = {}) => {
      const { id, timeOption, timeRangeOptions } = pod;

      const input = formatInput(pod);

      const options = {
        variables: {
          input: {
            id,
            timeRangeOptions: input.timeRangeOptions,
          },
        },
        refetchQueries,
      };

      if (
        timeOption === "ENABLE_PLANNING" &&
        !hasSelectedTimeRangeOptions({
          timeRangeOptions,
          userId: currentUser.id,
        })
      ) {
        showTimeRangeOptionsForm();
      } else {
        await joinPod(options);
        hideTimeRangeOptionsForm();
        // TODO: show confirmation toast
      }
    },
    [
      currentUser,
      joinPod,
      refetchQueries,
      formatInput,
      showTimeRangeOptionsForm,
      hideTimeRangeOptionsForm,
    ],
  );

  const updatePodWrapper = useCallback(
    async (params = {}) => {
      const options = {
        variables: {
          input: {
            id: myPod?.id,
            ...formatInput(params),
          },
        },
        skip: !myPod,
        refetchQueries,
      };

      updatePod(options);
    },
    [updatePod, formatInput, myPod, refetchQueries],
  );

  const deletePodWrapper = useCallback(() => {
    const id = myPod?.id;

    const options = {
      variables: {
        input: {
          id,
        },
      },
      refetchQueries,
      // race condition with deleteEvent mutation
      awaitRefetchQueries: true,
    };

    if (!promptForConfirmation("Are you sure you want to leave the pod?")) {
      return;
    }

    deletePod(options);

    onClose();
  }, [deletePod, refetchQueries, promptForConfirmation, onClose, myPod]);

  const updateRefetchQueries = () => {
    // TODO: don't use refetchQueries, find a better solution
    // update: (cache, { data: {createPod: { pod } } }) => {}
    // Note: order matters
    const queries = [
      getUserById.definitions[0].name.value,
      getCurrentUserQuery.definitions[0].name.value,
      searchEventsQuery.definitions[0].name.value,
      getPodsByUserId.definitions[0].name.value,
    ];

    if (event?.id) {
      queries.push({
        query: getPodsByEventId,
        variables: {
          input: {
            id: event?.id,
          },
        },
      });
      queries.push({
        query: getEventById,
        variables: {
          input: {
            id: event?.id,
          },
        },
      });
    }

    setRefetchQueries(queries);
  };

  useEffect(() => {
    if (createPodData || updatePodData || deletePodData) {
      onClose();
    }
  }, [onClose, createPodData, updatePodData, deletePodData]);

  useEffect(updateRefetchQueries, [event]);

  return {
    selectedPods,
    podParams,
    setPodParams,
    event,
    myPod,
    modalOpen,
    timeRangesModalOpen,
    onClose,
    onTimeRangesModalClose,
    podGraphQl: {
      createPod: createPodWrapper,
      createPodData,
      createPodLoading,
      updatePod: updatePodWrapper,
      updatePodData,
      updatePodLoading,
      joinPod: joinPodWrapper,
      joinPodData,
      joinPodLoading,
      deletePod: deletePodWrapper,
      deletePodData,
      deletePodLoading,
      getEventByIdLoading,
    },
  };
};

export default usePodGraphql;
