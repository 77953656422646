import React from "react";
import { FacebookIcon, TwitterIcon } from "react-share";

import { Container, SocialLinks } from "./footer/styles";

const Footer = (props) => (
  <Container>
    <SocialLinks>
      <a
        href="https://www.facebook.com/PeapodsHQ"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon size={45} round={true} />
      </a>
      <a
        href="https://twitter.com/PeapodsHQ"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterIcon size={45} round={true} />
      </a>
    </SocialLinks>
  </Container>
);

export default Footer;
