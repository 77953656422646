import styled from "styled-components";

import { colors } from "pages/Landing/landing/SharedStyles";

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 90px;
  background: ${colors.mainBlue};
`;

const SocialLinks = styled.div`
  display: flex;

  > a {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export { Container, SocialLinks };
