import { useState, useEffect, useCallback } from "react";
import { useQuery, useApolloClient } from "@apollo/client";

import { getCurrentUser as getCurrentUserQuery } from "graphql/api";
import { queries } from "graphql/clientApi";

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const client = useApolloClient();
  const { data } = useQuery(queries.IS_LOGGED_IN_QUERY);
  const isLoggedIn = data && data.isLoggedIn;

  const { data: getCurrentUserData, loading: isLoading } = useQuery(
    getCurrentUserQuery,
    {
      skip: !isLoggedIn,
    },
  );

  const updateCache = useCallback(
    (user) => {
      setCurrentUser(user);
      client.cache.retain(`User:${user.id}`);
      client.cache.writeQuery({
        query: queries.GET_CURRENT_USER_QUERY,
        data: { currentUser: user },
      });
    },
    [client.cache],
  );

  const refetchCurrentUser = useCallback(async () => {
    const { data } = await client.query({
      query: getCurrentUserQuery,
      fetchPolicy: "network-only",
    });
    const user = data?.getCurrentUser?.user;

    if (user) {
      updateCache(user);
    }
  }, [client, updateCache]);

  useEffect(() => {
    if (getCurrentUserData) {
      const { user } = getCurrentUserData.getCurrentUser;
      updateCache(user);
    }
  }, [getCurrentUserData, updateCache]);

  return {
    refetchCurrentUser,
    currentUser,
    isLoading,
  };
};

export default useCurrentUser;
