import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Box,
  SnackbarContent,
  theme,
} from "mui-treasury/src/brands/peapods";
import { makeStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: theme.spacing(1),
  },
}));

const LocationSnackbar = forwardRef(
  ({ key, message, url, closeSnackbar }, ref) => {
    const [copied, setCopied] = useState(false);
    const classes = useStyles();

    const onCopied = () => {
      setCopied(true);
    };

    const onCloseSnackbar = () => {
      closeSnackbar(key);
    };

    return (
      <SnackbarContent
        ref={ref}
        id={key}
        style={{
          backgroundColor: theme.palette.secondary.main,
        }}
        message={
          <>
            <Box mr={4} display="inline">
              {message}
            </Box>

            {url && (
              <CopyToClipboard text={url} onCopy={onCopied}>
                <Button
                  variant={"contained"}
                  color="primary"
                  className={classes.margin}
                >
                  {copied ? "copied!" : "copy link"}
                </Button>
              </CopyToClipboard>
            )}

            <Button onClick={onCloseSnackbar}>dismiss</Button>
          </>
        }
      />
    );
  },
);

LocationSnackbar.propTypes = {
  key: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

export default LocationSnackbar;
