import React from "react";
import {
  makeStyles,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  CalendarIcon,
  GroupIcon,
  MessageIcon,
  CloseIcon,
  Backdrop,
} from "mui-treasury/src/brands/peapods";

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: "absolute",
    top: 0,
    bottom: -239,
    zIndex: 0,
  },
  toolTip: {
    width: 130,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  backdrop: {
    zIndex: 1,
  },
}));

const actions = [
  { id: "create-event-action", icon: <CalendarIcon />, name: "Create Event" },
  { id: "create-group-action", icon: <GroupIcon />, name: "Create Group" },
  {
    id: "create-message-action",
    icon: <MessageIcon />,
    name: "Create Message",
  },
];

const GlobalSpeedDial = ({
  onOpen,
  onClose,
  onAction,
  isOpen,
  isHidden,
  isMobile,
}) => {
  const classes = useStyles();

  return (
    <>
      <Backdrop open={isOpen} className={classes.backdrop} />

      <SpeedDial
        direction={isMobile ? "up" : "down"}
        ariaLabel="peapods-create-menu"
        className={classes.speedDial}
        style={{
          left: isMobile ? 0 : "auto",
          right: isMobile ? "auto" : 0,
          zIndex: isOpen ? 1 : 0,
        }}
        icon={<SpeedDialIcon openIcon={<CloseIcon />} />}
        onClose={onClose}
        color="primary"
        onOpen={onOpen}
        open={isOpen}
        FabProps={{
          id: "peapods-create-menu",
          size: isMobile ? "large" : "medium",
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            classes={{
              staticTooltipLabel: classes.toolTip,
            }}
            tooltipOpen
            tooltipPlacement={"left"}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            FabProps={{
              id: action.id,
            }}
            onClick={() => onAction(action.name)}
          />
        ))}
      </SpeedDial>
    </>
  );
};

export default GlobalSpeedDial;
