import { useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { orderBy } from "lodash";

import { getMessagesByParent as getMessagesByParentQuery } from "graphql/api";

const useGetMessagesByParent = (threadIdProp) => {
  const [threadId, setThreadId] = useState();
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(false);

  const { data, loading, fetchMore } = useQuery(getMessagesByParentQuery, {
    variables: {
      input: {
        threadId,
      },
    },
    skip: !threadId,
  });

  const loadNextPage = useCallback(async () => {
    if (!data) {
      return;
    }

    const { pageInfo } = data.getMessagesByParent;

    setMessagesLoading(true);

    await fetchMore({
      variables: {
        input: {
          threadId,
          pagination: {
            after: pageInfo.endCursor,
          },
        },
      },
      skip: !data,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges, pageInfo, totalCount } =
          fetchMoreResult.getMessagesByParent;

        setMessagesLoading(false);

        return edges.length
          ? {
              getMessagesByParent: {
                __typename: previousResult.getMessagesByParent.__typename,
                edges: [...previousResult.getMessagesByParent.edges, ...edges],
                pageInfo,
                totalCount,
              },
            }
          : previousResult;
      },
    });
  }, [data, threadId, fetchMore]);

  const onMessagesLoaded = () => {
    if (!data) {
      return;
    }

    setMessages(
      orderBy(
        data.getMessagesByParent.edges.map((e) => e.node),
        "created",
      ),
    );
  };

  const onThreadIdChanged = () => {
    if (threadId === threadIdProp) {
      return;
    }
    if (!threadIdProp) {
      setMessages([]);
    }
    setThreadId(threadIdProp);
  };

  useEffect(onMessagesLoaded, [data]);
  useEffect(onThreadIdChanged, [threadIdProp, threadId]);

  return {
    messages,
    messagesLoading: loading || messagesLoading,
    pageInfo: data && data.getMessagesByParent.pageInfo,
    loadNextPage,
    fetchMore,
  };
};

export default useGetMessagesByParent;
