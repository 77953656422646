import React, { memo } from "react";
import PropTypes from "prop-types";
import { AutocompleteList } from "mui-treasury/src/brands/peapods";

import { useSearchAll } from "graphql/api/index";

import SearchInputOption from "./SearchInputOption";

const SearchInput = ({
  value,
  label,
  canCreate,
  isMulti,
  clearInputAfterEnter,
  showTypeLabels,
  includeGroups,
  includeUsers,
  includeEvents,
  includeHashtags,
  userFilters,
  eventFilters,
  groupFilters,
  onChange,
  onBlur,
  InputControlComponent,
  menuPlacement,
  isRouting,
  autoFocus,
}) => {
  const { searchAll } = useSearchAll();

  const formatResponse = (response) => {
    const { users, groups, events, hashtags } = response;
    const selectedIds = [...value.map((item) => item.value)];

    const createOptions = (page) => {
      return page.edges
        .map(({ node }) => ({
          label: node.title || node.name,
          value: node.id,
          node,
          isRouting,
        }))
        .filter((item) => !selectedIds.includes(item.node.id));
    };

    let options = [];

    const addToOptions = (page, label) => {
      if (!page) {
        return;
      }

      const newOptions = createOptions(page);

      if (showTypeLabels) {
        options.push({
          label,
          options: newOptions,
        });
      } else {
        options = [...options, ...newOptions];
      }
    };

    addToOptions(users, "users");
    addToOptions(groups, "groups");
    addToOptions(events, "events");
    addToOptions(hashtags, "hashtags");

    return options;
  };

  const getSuggestions = async (searchString) => {
    const input = {
      searchString,
      includeUsers,
      includeGroups,
      includeEvents,
      includeHashtags,
      userFilters,
      eventFilters,
      groupFilters,
    };
    // TODO: handle pagination
    const res = await searchAll(input);
    return formatResponse(res);
  };

  return (
    <AutocompleteList
      label={label}
      value={value}
      isMulti={isMulti}
      canCreate={canCreate}
      clearAfterEnter={clearInputAfterEnter}
      getSuggestions={getSuggestions}
      onChange={onChange}
      onBlur={onBlur}
      OptionComponent={SearchInputOption}
      InputControl={InputControlComponent}
      menuPlacement={menuPlacement}
      autoFocus={autoFocus}
    />
  );
};

SearchInput.propTypes = {
  includeGroups: PropTypes.bool,
  includeUsers: PropTypes.bool,
  includeEvents: PropTypes.bool,
  includeHashtags: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  ),
  isMulti: PropTypes.bool,
  canCreate: PropTypes.bool,
  clearInputAfterEnter: PropTypes.bool,
  showTypeLabels: PropTypes.bool,
  InputControlComponent: PropTypes.func,
  menuPlacement: PropTypes.string,
  isRouting: PropTypes.bool,
  userFilters: PropTypes.object,
  eventFilters: PropTypes.object,
  groupFilters: PropTypes.object,
  autoFocus: PropTypes.bool,
};

SearchInput.defaultProps = {
  includeGroups: true,
  includeUsers: true,
  includeEvents: false,
  includeHashtags: false,
  userFilters: undefined,
  eventFilters: undefined,
  groupFilters: undefined,
  canCreate: false,
  label: "",
  value: [],
  isMulti: true,
  clearInputAfterEnter: false,
  showTypeLabels: true,
  InputControlComponent: undefined,
  menuPlacement: "auto",
  isRouting: false,
  autoFocus: false,
  onChange: () => {},
};

export default memo(SearchInput);
