import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Typography, Button, Box } from "mui-treasury/src/brands/peapods";
import useLocalStorage from "react-use-localstorage-hook";
import { difference } from "lodash";

import { useUserHooks, getThreadsByParentQuery } from "graphql/api";
import { mutations } from "graphql/clientApi";

import LocationInput from "smart-components/LocationInput";

const useSteps = (closeStepper) => {
  const history = useHistory();

  const [sharedEvent] = useLocalStorage("sharedEvent");

  const [openEventForm] = useMutation(mutations.SET_SHOW_EVENT_FORM, {
    variables: { showEventForm: true },
  });

  const [openGroupForm] = useMutation(mutations.SET_SHOW_GROUP_FORM, {
    variables: { showGroupForm: true },
  });

  const [location, setLocation] = useState();
  const [userId, setUserId] = useState();
  const [nonCreatedPods, setNonCreatedPods] = useState([]);
  const [createdThreads, setCreatedThreads] = useState([]);

  const {
    currentUser,
    getUserByIdData,
    getUserByIdLoading,
    updateUser,
    updateUserLoading,
    getCurrentUserLoading,
  } = useUserHooks({ userId });

  const { data } = useQuery(getThreadsByParentQuery, {
    variables: {
      input: {
        parentType: "USER",
        parentId: currentUser && currentUser.id,
      },
    },
    skip: !currentUser,
  });

  const isLoading = getUserByIdLoading || getCurrentUserLoading;

  useEffect(() => {
    if (currentUser && !userId) {
      setUserId(currentUser.id);
    }
  }, [currentUser, userId]);

  const onLocationChanged = useCallback(
    (loc) => {
      if (!loc || !currentUser) {
        return;
      }

      const location = {
        lat: loc.geometry.location.lat(),
        lng: loc.geometry.location.lng(),
        formattedAddress: loc.formatted_address,
      };

      setLocation(location);

      updateUser({
        id: currentUser.id,
        location,
      });
    },
    [updateUser, currentUser],
  );

  const onSearchEvents = useCallback(() => {
    history.push("/discover/pods");
    closeStepper();
  }, [history, closeStepper]);

  const onSearchUsers = useCallback(() => {
    history.push("/discover/peas");
    closeStepper();
  }, [history, closeStepper]);

  const onSendMessage = useCallback(() => {
    history.push("/messages");
    closeStepper();
  }, [history, closeStepper]);

  const onUpdateProfile = useCallback(() => {
    if (!currentUser) {
      return;
    }
    history.push(`/@${currentUser.username}`);
    closeStepper();
  }, [currentUser, history, closeStepper]);

  const onCreateEvent = useCallback(() => {
    openEventForm();
    closeStepper();
  }, [openEventForm, closeStepper]);

  const onCreateGroup = useCallback(() => {
    openGroupForm();
    closeStepper();
  }, [openGroupForm, closeStepper]);

  const updateNonCreatedPods = () => {
    if (!currentUser) {
      return;
    }

    const createdEventIds = currentUser.createdEventsConnection.edges.map(
      (e) => e.node.id,
    );

    const podMemberEventIds =
      currentUser.podsConnection?.edges?.map((e) => e.node.event?.id) || [];

    setNonCreatedPods(difference(podMemberEventIds, createdEventIds));
  };

  useEffect(updateNonCreatedPods, [currentUser]);

  const stepOne = useMemo(
    () => ({
      label: "Enter your location",
      isComplete: currentUser && !!currentUser.location,
      content: () => {
        const text = `
        This will be public information, be careful not to be too specific.
        Your recommendations will be based on this.
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <LocationInput
              value={location ? location.formattedAddress : undefined}
              onChange={onLocationChanged}
              buttonText={location && "Save"}
              isLoading={updateUserLoading}
              showLabel={false}
            />
          </div>
        );
      },
    }),
    [currentUser, location, onLocationChanged, updateUserLoading],
  );

  const stepTwo = useMemo(
    () => ({
      label: "Create a pod",
      content: () => {
        const text = `
        Search for an event you are interested in using the powerful filters.
        A 'pod' is a small group of users attending an event together.
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <Box m={1} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onSearchEvents}
              >
                search events
              </Button>
            </Box>
          </div>
        );
      },
      isComplete: currentUser?.podsConnection?.totalCount > 0,
    }),
    [currentUser?.podsConnection?.totalCount, onSearchEvents],
  );

  const stepThree = useMemo(
    () => ({
      label: "Share event with a friend",
      content: () => {
        const text = `
        Peapods is more fun with friends! Use the 'share' button to invite
        someone who would also be interested in going.
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <Box m={1} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onSearchEvents}
              >
                search events
              </Button>
            </Box>
          </div>
        );
      },
      isComplete: sharedEvent,
    }),
    [onSearchEvents, sharedEvent],
  );

  const stepFour = useMemo(
    () => ({
      label: "Update your profile",
      content: () => {
        const text = `
        It's time to introduce yourself to the community.
        Add a profile photo, cover image, bio, and some tags so others can find you!
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <Box m={1} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onUpdateProfile}
              >
                go to profile
              </Button>
            </Box>
          </div>
        );
      },
      isComplete:
        getUserByIdData &&
        getUserByIdData.getUserById &&
        getUserByIdData.getUserById.user &&
        getUserByIdData.getUserById.user.profilePhotos &&
        getUserByIdData.getUserById.user.profilePhotos[0] &&
        getUserByIdData.getUserById.user.headerPhoto &&
        getUserByIdData.getUserById.user.bio &&
        getUserByIdData.getUserById.user.taggedConnection.totalCount,
    }),
    [getUserByIdData, onUpdateProfile],
  );

  const stepFive = useMemo(
    () => ({
      label: "Create an event",
      content: () => {
        const text = `
        Have you ever wanted to do something but just not alone?
        Now is your chance to host your own activity for others to enjoy!
        Make sure you set a good description and image, and share it just the
        peas you want to.
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <Box m={1} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onCreateEvent}
              >
                create event
              </Button>
            </Box>
          </div>
        );
      },
      isComplete:
        currentUser && currentUser.createdEventsConnection.totalCount > 0,
    }),
    [onCreateEvent, currentUser],
  );

  const stepSix = useMemo(
    () => ({
      label: "Create a group",
      content: () => {
        const text = `
        Groups are a very important feature of the app.
        You can create 'Personal' groups, which only you can ever see, as a 
        way of controlling who sees your activities. You can also create 'public'
        or 'exclusive' groups and invite users to join them.
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <Box m={1} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onCreateGroup}
              >
                create group
              </Button>
            </Box>
          </div>
        );
      },
      isComplete:
        currentUser && currentUser.createdGroupsConnection.totalCount > 3,
    }),
    [onCreateGroup, currentUser],
  );

  const stepSeven = useMemo(
    () => ({
      label: "Join another pod",
      content: () => {
        const text = `
        While it's great when other peas join your pods, it's also important to 
        join theirs as well. Try joining a pod for an event that you did not create.
        But remember, if you join and then flake you will lose reputation!
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <Box m={1} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onSearchEvents}
              >
                find pods
              </Button>
            </Box>
          </div>
        );
      },
      isComplete: nonCreatedPods.length > 0,
    }),
    [onSearchEvents, nonCreatedPods],
  );

  const stepEight = useMemo(
    () => ({
      label: "Follow 5 peas",
      content: () => {
        const text = `
        Do you want to see all the events that your friends want to attend with you?
        Remember, when peas create pods they choose who can see the pod, so all
        the pods you see in your home feed are ready for you to join! To populate
        your home feed, you must follow some peas, let's start with 5.
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <Box m={1} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onSearchUsers}
              >
                find peas
              </Button>
            </Box>
          </div>
        );
      },
      isComplete:
        currentUser && currentUser.followingConnection.totalCount >= 5,
    }),
    [onSearchUsers, currentUser],
  );

  const stepNine = useMemo(
    () => ({
      label: "Gain 5 followers",
      content: () => {
        const text = `
        When you create your events and pods, who will see them?
        That is for you to decide. Be sure to make good use of your 'personal' groups
        to organize your followers, every follower must belong to a personal group.
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <Box m={1} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onSearchUsers}
              >
                find followers
              </Button>
            </Box>
          </div>
        );
      },
      isComplete:
        currentUser && currentUser.followersConnection.totalCount >= 5,
    }),
    [onSearchUsers, currentUser],
  );

  const stepTen = useMemo(
    () => ({
      label: "Create a thread",
      content: () => {
        const text = `
        Messaging is of course an important feature of any social application.
        You can message peas directly, your group threads, or your pods.
        Remember to be nice and don't be afraid to be the first to reach out!
      `;
        return (
          <div>
            <Typography>{text}</Typography>
            <Box m={1} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onSendMessage}
              >
                create thread
              </Button>
            </Box>
          </div>
        );
      },
      isComplete: createdThreads.length > 0,
    }),
    [onSendMessage, createdThreads],
  );

  const onData = () => {
    setCreatedThreads(
      data?.getThreadsByParent?.edges?.map((e) => e.node) || [],
    );
  };

  useEffect(onData, [data]);

  const steps = useMemo(
    () => [
      stepOne,
      stepTwo,
      stepThree,
      stepFour,
      stepFive,
      stepSix,
      stepSeven,
      stepEight,
      stepNine,
      stepTen,
    ],
    [
      stepOne,
      stepTwo,
      stepThree,
      stepFour,
      stepFive,
      stepSix,
      stepSeven,
      stepEight,
      stepNine,
      stepTen,
    ],
  );

  return {
    steps,
    isLoading,
  };
};

export default useSteps;
