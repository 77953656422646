import React, { Component } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useQuery } from "@apollo/client";
import { LastLocationProvider } from "react-router-last-location";
import { useTheme } from "mui-treasury/src/brands/peapods";
import styled from "styled-components";

import {
  Landing,
  AsyncDiscover,
  AsyncNotification,
  AsyncHome,
  AsyncLogin,
  AsyncNotFound,
  AsyncEmailConfirmation,
  AsyncProfile,
  AsyncMessages,
  AsyncEvent,
  AsyncGroup,
  AsyncSettings,
  AsyncFeedback,
} from "pages";

import Navbar from "smart-components/Navbar";

import { queries } from "graphql/clientApi";

const history = createBrowserHistory();

const AppWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: ${({ isMobile }) => (isMobile ? "column-reverse" : "column")};
`;

const Routes = ({ children }) => {
  const theme = useTheme();
  const { isMobile } = theme;

  const { data } = useQuery(queries.IS_LOGGED_IN_QUERY);
  const isLoggedIn = data && data.isLoggedIn;

  return (
    <Router history={history}>
      <LastLocationProvider>
        <AppWrapper isMobile={isMobile}>
          {isLoggedIn && <Navbar isLoggedIn={isLoggedIn} />}

          <Route
            render={(props) => (
              <ModalSwitch {...props} isLoggedIn={isLoggedIn} />
            )}
          />
          {children}
        </AppWrapper>
      </LastLocationProvider>
    </Router>
  );
};

// taken from official example:
// https://reacttraining.com/react-router/web/example/modal-gallery
class ModalSwitch extends Component {
  previousLocation = this.props.location;

  // TODO: refactor this class with hooks
  UNSAFE_componentWillUpdate(nextProps) {
    const { location } = this.props;
    if (
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = location;
    }
  }

  render = () => {
    const { location, isLoggedIn } = this.props;
    const { redirectAfterLogin } = window.localStorage;

    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    );

    if (redirectAfterLogin && isLoggedIn) {
      delete window.localStorage.redirectAfterLogin;
      return <Redirect to={redirectAfterLogin} />;
    }

    if (
      location.search?.includes("?vip=peapodsvip") ||
      location.search?.includes("?vip=bugcrowd")
    ) {
      window.localStorage.setItem("peapodsvip", true);
    }

    const isBetaUser = !!window.localStorage.peapodsvip;

    // TODO: after logging out then logging back in, we landing on
    // this.previousLocation - this is a bug

    return (
      <Switch location={isModal ? this.previousLocation : location}>
        <Route path="/index.html" render={() => <Redirect to="/" />} />
        <Route
          exact
          path="/"
          render={() => (isLoggedIn ? <AsyncHome /> : <Landing />)}
        />

        <Redirect exact from="/settings" to="/settings/about" />
        <Route path="/settings" component={AsyncSettings} />
        <Redirect from="/privacy" to="/settings/privacy" />
        <Redirect from="/terms" to="/settings/terms" />

        {/* remove when public beta is live */}
        {!isBetaUser && <Redirect to={"/"} />}

        <Route
          path="/login/email-confirmation"
          component={AsyncEmailConfirmation}
        />

        <Route
          path={["/login", "/signup"]}
          render={() => (isLoggedIn ? <Redirect to="/" /> : <AsyncLogin />)}
        />

        {!isLoggedIn && (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )}

        <Redirect exact from="/feedback" to="/feedback/comments" />
        <Route path="/feedback/:component" component={AsyncFeedback} />

        <Redirect exact from="/discover" to="/discover/pods" />
        <Route exact path="/discover/:type" component={AsyncDiscover} />

        <Route exact path="/notifications" component={AsyncNotification} />

        <Redirect exact from="/messages" to="/messages/direct" />
        <Route path="/messages/:type/:threadId" component={AsyncMessages} />
        <Route exact path="/messages/:type" component={AsyncMessages} />

        <Route exact path="/group/:groupId" component={AsyncGroup} />
        <Route exact path="/event/:id" component={AsyncEvent} />

        <Route exact path="/:userName" component={AsyncProfile} />
        <Route component={AsyncNotFound} />
      </Switch>
    );
  };
}

export { history };
export default Routes;
