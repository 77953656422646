import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Popover,
  Backdrop,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  makeStyles,
  LoadingSpinner,
  theme,
} from "mui-treasury/src/brands/peapods";
import cx from "clsx";
import { min } from "lodash";

import { useUserHooks } from "graphql/api";

import useSteps from "./steps";

const MIN_STEP_WIDTH = 250;

const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
  },
  step: {
    width: "100%",
    minWidth: MIN_STEP_WIDTH,
  },
  stepLabel: {
    "&:hover": {
      "& .MuiStepLabel-iconContainer": {
        "& svg": {
          color: theme.palette.primary.main,
        },
      },
      "& .MuiStepLabel-label": {
        color: theme.palette.common.black,
      },
    },
  },
}));

function OnboardingGuide() {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [percentComplete, setPercentComplete] = useState(0);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const { steps, isLoading } = useSteps(handleClose);

  const scrollerRef = useRef();

  const { currentUser, updateUser } = useUserHooks();

  const open = Boolean(anchorEl);
  const id = open ? "onboarding-popover" : undefined;

  const onOnboardingComplete = useCallback(async () => {
    if (!currentUser) {
      return;
    }

    const { settings } = currentUser;

    if (!settings || !settings.isOnboarded) {
      await updateUser({
        id: currentUser.id,
        settings: {
          isOnboarded: true,
        },
      });

      // TODO: show confetti modal
    }
  }, [currentUser, updateUser]);

  const updateStepper = () => {
    if (!scrollerRef.current) {
      return;
    }
    const { scrollLeft, clientWidth, scrollWidth } = scrollerRef.current;
    const left = MIN_STEP_WIDTH / 1.3;

    if (scrollLeft + clientWidth < scrollWidth) {
      scrollerRef.current.scrollBy({ left, behavior: "smooth" });
    }
  };

  const onStepsUpdated = () => {
    const index = min(
      steps.reduce((prev, next, index) => {
        if (!next.isComplete) {
          prev.push(index);
        }
        return prev;
      }, []),
    );

    const completedSteps = steps.filter((step) => step.isComplete);
    const percent = parseInt((completedSteps.length / steps.length) * 100);
    const allComplete = completedSteps.length === steps.length;

    setActiveStep(activeStep || index);
    setPercentComplete(percent);

    if (allComplete) {
      onOnboardingComplete();
    }
  };

  const handleStepClick = (step, index) => () => {
    setActiveStep(index);
  };

  useEffect(updateStepper, [activeStep]);
  useEffect(onStepsUpdated, [steps, onOnboardingComplete, activeStep]);

  return activeStep === undefined || isLoading ? null : (
    <>
      <Backdrop open={open} />

      <Button
        className={cx(classes.button, "MuiFab-root")}
        loading={isLoading}
        aria-describedby={id}
        shadowless={false}
        variant="contained"
        color="danger"
        onClick={handleClick}
        shape={"circular"}
        size={theme.isMobile ? "large" : "big"}
        style={{
          position: "absolute",
          right: theme.isMobile ? 0 : 84,
          left: theme.isMobile ? 0 : "inherit",
          bottom: theme.isMobile ? "104px" : "inherit",
          margin: "auto",
        }}
      >
        {percentComplete}%
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: theme.isMobile ? "top" : "bottom",
          horizontal: theme.isMobile ? "left" : "center",
        }}
        transformOrigin={{
          vertical: theme.isMobile ? "bottom" : "top",
          horizontal: theme.isMobile ? "left" : "center",
        }}
      >
        <div
          style={{
            maxWidth: theme.isMobile ? "100%" : 500,
            maxHeight: theme.isMobile ? 500 : "100%",
            overflowX: "auto",
          }}
          ref={scrollerRef}
        >
          <Stepper
            alternativeLabel={theme.isMobile}
            activeStep={activeStep}
            orientation={"vertical"}
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              steps.map((step, index) => (
                <Step
                  key={step.label}
                  className={classes.step}
                  completed={step.isComplete}
                  style={{
                    minHeight: theme.isMobile ? 275 : "auto",
                  }}
                >
                  <StepLabel
                    classes={{ root: classes.stepLabel }}
                    style={{ cursor: step.isComplete ? "text" : "pointer" }}
                    onClick={handleStepClick(step, index)}
                  >
                    {step.label}
                  </StepLabel>

                  {theme.isMobile && index === activeStep && step.content()}

                  {!theme.isMobile && index === activeStep && (
                    <StepContent>{step.content()}</StepContent>
                  )}
                </Step>
              ))
            )}
          </Stepper>
        </div>
      </Popover>
    </>
  );
}

export default OnboardingGuide;
