import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid, Button } from "mui-treasury/src/brands/peapods";
import { useQuery } from "@apollo/client";

import { Modal } from "pure-components/Layout";
import TimeRangeSelector from "pure-components/TimeRangeSelector";

import { useUserHooks } from "graphql/api";

import { getSelectedPodsQuery } from "graphql/clientApi";

import usePreventLeave from "utils/preventLeave";

import usePodFormHooks from "../PodForm/PodFormHooks";

const PodTimeRangeForm = () => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const { currentUser } = useUserHooks();

  const { data } = useQuery(getSelectedPodsQuery);

  const {
    selectedPods,
    podParams,
    setPodParams,
    podGraphQl,
    timeRangesModalOpen,
    onTimeRangesModalClose,
  } = usePodFormHooks();

  const { joinPod, joinPodLoading } = podGraphQl;

  const { timeRangeOptions } = podParams || {};

  const { isDirty, setIsDirty, promptForConfirmation } = usePreventLeave();

  const onFormClosed = (...args) => {
    if (isDirty && !promptForConfirmation()) {
      return;
    }
    setIsDirty(false);

    onTimeRangesModalClose(...args);
  };

  const onPodParamsChanged = (...args) => {
    setIsDirty(true);
    setPodParams(...args);
  };

  const onTimeOptionChanged = (timeRangeOptions) => {
    onPodParamsChanged({
      ...podParams,
      timeRangeOptions,
    });
  };

  const title = "Time Options";

  const onSave = useCallback(async () => {
    await joinPod(podParams);
    setIsDirty(false);
  }, [joinPod, setIsDirty, podParams]);

  const onEditModeChange = (isEditMode) => {
    setIsSaveDisabled(isEditMode);
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setPodParams(data.selectedPods[0]);
  }, [data, setPodParams]);

  const podMembers = selectedPods?.length ? selectedPods[0].members : [];
  const buttonText = podMembers?.map((u) => u.id).includes(currentUser?.id)
    ? "Save"
    : "Join Pod";

  const users = [...(podMembers || []), currentUser];

  return (
    <Modal
      open={timeRangesModalOpen}
      title={title}
      render={(isMobile) => {
        return (
          <Grid
            container
            direction="column"
            style={{
              position: "relative",
            }}
          >
            <Grid item>
              <TimeRangeSelector
                timeRangeOptions={timeRangeOptions}
                onChange={onTimeOptionChanged}
                onEditModeChange={onEditModeChange}
                userId={currentUser?.id}
                users={users}
              />
            </Grid>

            <Box position={"sticky"} bottom={0} left={0} mt={4}>
              <Grid
                container
                alignItems={"center"}
                justify="center"
                spacing={4}
              >
                <Grid item>
                  <Button onClick={onFormClosed}>Cancel</Button>
                </Grid>

                <Grid item>
                  <Button
                    disabled={isSaveDisabled}
                    loading={joinPodLoading}
                    color={"primary"}
                    variant={"contained"}
                    onClick={onSave}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      }}
    />
  );
};

export default PodTimeRangeForm;
