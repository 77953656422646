import { useMutation } from "@apollo/client";

import { createGroup as createGroupMutation } from "graphql/api";

const useCreateGroup = () => {
  const [createGroup, { loading }] = useMutation(createGroupMutation, {
    refetchQueries: ["getCurrentUser", "getUserById"],
  });

  return {
    createGroup,
    createGroupLoading: loading,
  };
};

export default useCreateGroup;
